a,
a:hover,
a:visited,
a:focus {
  text-decoration: none;
  outline: none;
}
.error {
  color: #ff580c;
  background-color: #ffd9cc;
  border: 1px solid #ff580c;
}
.btn.disabled {
  opacity: 0.45;
}
.valid {
  color: #ff580c;
  background: #ff580c;
  border: 1px solid #ff580c;
}
.error-msg {
  position: relative;
  left: 380px;
  font-size: 1.2rem;
  width: 225px;
  border-radius: 4px;
  padding: 5px 7px;
  background-color: #fed3d5;
  color: #c03634;
  display: none;
  top: -44px;
}
@media screen and (min-width:992px) and (max-width:1199px) {
  .error-msg {
    left: 325px;
  }
}
.error-msg.fixed {
  position: inherit;
  width: auto;
  margin-top: 10px;
}
.error-msg p {
  font-size: 1.2rem!important;
  line-height: 1.8rem!important;
  margin-bottom: 0!important;
  color: #c03634!important;
}
@media screen and (max-width:991px) {
  .error-msg {
    position: initial;
    width: auto;
    margin-top: 10px;
  }
}
.error-msg .arrow-left {
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  position: absolute;
  top: 16px;
  left: -6px;
  border-right: 7px solid #fed3d5;
}
@media screen and (max-width:991px) {
  .error-msg .arrow-left {
    display: none;
  }
}
.ring-loader {
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-indent: -1000px;
  color: transparent !important;
  text-align: left !important;
  overflow: hidden !important;
}
.ring-loader:after {
  content: " ";
  position: absolute;
  display: block;
  width: 15px;
  height: 15px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ff804b;
  border-color: #ff804b transparent #ff804b transparent;
  animation: ring-loader 1.2s linear infinite;
}
@keyframes ring-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.form-control.form-error.has-error {
  border: 1px solid #c03634 !important;
}
.alert-danger {
  color: #2d3436;
  background-color: #fff0f0;
  border: none;
  border-left: 5px solid #e84848;
  border-radius: 0.4rem;
  line-height: 2rem;
}
.alert-warning {
  color: #2d3436;
  background-color: #fff7dd;
  border: none;
  border-left: 5px solid #f0ce64;
  border-radius: 0.4rem;
  line-height: 2rem;
}
.alert-info {
  color: #2d3436;
  background-color: #e7f7ff;
  border: none;
  border-left: 5px solid #31708f;
  border-radius: 0.4rem;
  line-height: 2rem;
}
.has-error .form-control-feedback {
  color: #c03634;
  top: 10px;
}
.has-success .form-control-feedback {
  color: #3c763d;
}
.float-container {
  float: left;
  width: 100%;
}
strong {
  font-weight: bold;
}
.d-none {
  display: none;
}
.centered-div {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.fit-content {
  width: fit-content;
}
/**
  BOOTSTRAP-EXTENSION GRID 5 COLUMNS
*/
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding: 0 10px;
}
.col-xs-5ths {
  width: 20%;
  float: left;
}
@media (min-width: 768px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20px;
    float: left;
  }
}
/**
  SPRITE
*/
/**
LOGO PRESSE
*/
/*
GENERAL
*/
/*
NAVIGATION
*/
/*
PROFIL ICONS
*/
/*sprite categorie univers thin*/
.fleche-grise {
  background-position: -50px -29px;
  width: 5px;
  height: 10px;
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 10px;
}
/** Sprite consumer theme lisboa **/
.consumer-circle-blue {
  background-position: 0 0;
  width: 40px;
  height: 40px;
}
.consumer-check-white {
  background-position: -45px 0;
  width: 24px;
  height: 18px;
}
.consumer-arrow-blue {
  background-position: -74px 0;
  width: 6px;
  height: 10px;
}
.consumer-arrow-orange {
  background-position: -85px 0;
  width: 6px;
  height: 9px;
}
.consumer-calendar-mini {
  background-position: -96px 0;
  width: 23px;
  height: 23px;
}
.consumer-pin-mini {
  background-position: -124px 0;
  width: 19px;
  height: 19px;
}
.consumer-calendar {
  background-position: -148px 0;
  width: 29px;
  height: 29px;
}
.consumer-helmet {
  background-position: -181px 0;
  width: 33px;
  height: 33px;
}
.consumer-hammer {
  background-position: -219px 0;
  width: 34px;
  height: 34px;
}
.consumer-pin {
  background-position: -258px 0;
  width: 21px;
  height: 29px;
}
.consumer-beds {
  background-position: -284px 0;
  width: 29px;
  height: 29px;
}
.consumer-head {
  background-position: -318px 0;
  width: 32px;
  height: 32px;
}
.consumer-clock {
  background-position: -355px 0;
  width: 29px;
  height: 29px;
}
.consumer-phone {
  background-position: -389px 0;
  width: 29px;
  height: 29px;
}
.consumer-mail {
  background-position: -422px 0;
  width: 28px;
  height: 40px;
}
.consumer-lock {
  background-position: -455px 0;
  width: 19px;
  height: 26px;
}
.consumer-arrow-white {
  background-position: -479px 0;
  width: 6px;
  height: 10px;
}
.consumer-user-icon {
  width: 17px;
  height: 22px;
  background-position: 0 0;
}
.consumer-logout {
  width: 21px;
  height: 22px;
  background-position: -22px 0;
}
html {
  font-size: 9px;
}
@media (min-width:768px) {
  html {
    font-size: 10px;
  }
}
hr {
  border-top: 0;
  border-bottom: 1px solid #cecece;
  width: 100%;
  height: 0;
}
.uppercase {
  text-transform: uppercase;
}
.bold {
  font-weight: bold;
}
.centered-block {
  margin: 0 auto;
}
.wrapper-grey {
  background-color: #f0f0f0;
}
.wrapper-grey-2 {
  background-color: #f0f0f0;
  border: 1px solid #e8e5e5;
}
#page-content {
  width: 100%;
  transition: 0.2s;
}
#page-content.dropdown-open {
  opacity: 20%;
  cursor: pointer;
}
.sprite {
  background-image: url('/images/habitatpresto/sprites.png');
  background-repeat: no-repeat;
}
.sprite-general {
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
}
.sprite-nav {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
}
.sprite-profile {
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
}
.sprite-univers-thin {
  background-image: url('/images/habitatpresto/sprite-univers-thin.png');
  background-repeat: no-repeat;
}
.sprite-consumer {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
}
.sprite-consumer-header {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer-header.png');
  background-repeat: no-repeat;
}
.sprite-nav-progress {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-nav-progress.png');
  background-repeat: no-repeat;
}
.bt-orange {
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
  font-size: 18px;
}
.bt-orange:hover {
  background-color: #f64e01;
}
.bt-border-orange {
  background-color: #FFF;
  color: #ff580c;
  border: 1px solid #ff580c;
  border-radius: 3px;
  padding: 10px 20px;
  text-align: center;
}
a.bt-border-orange,
a.bt-border-orange:active,
a.bt-border-orange:visited {
  background-color: #FFF;
  color: #ff580c;
  border: 1px solid #ff580c;
  border-radius: 3px;
  padding: 10px 20px;
}
a.bt-border-orange:hover {
  background-color: #ff580c;
  color: #FFF;
  border: 1px solid #ff580c;
  border-radius: 3px;
  padding: 10px 20px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.no-padding {
  padding: 0;
}
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-60 {
  padding-top: 60px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-60 {
  padding-bottom: 60px;
}
.plr-60 {
  padding-left: 60px;
  padding-right: 60px;
}
.pl-0 {
  padding-left: 0;
}
.pr-0 {
  padding-right: 0;
}
.pl-30 {
  padding-left: 30px;
}
.pr-30 {
  padding-right: 30px;
}
.lh-10 {
  line-height: 10px;
}
.lh-15 {
  line-height: 15px;
}
.lh-20 {
  line-height: 20px;
}
.lh-25 {
  line-height: 25px;
}
.lh-30 {
  line-height: 30px;
}
.legal-notice {
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #757575;
  margin-top: 15px;
  font-family: Arial;
  text-align: justify;
}
.legal-notice a {
  text-decoration: underline;
  color: #474747;
}
.btn-secondary-light {
  color: #fff;
  background-color: #b2bdc8;
  border-color: #b2bdc8;
}
.btn-secondary-light:hover {
  color: #fff;
  background-color: #86929b;
  border-color: #86929b;
}
.btn-secondary-light:focus,
.btn-secondary-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(146, 146, 146, 0.5);
}
.orange {
  color: #ff580c;
}
@font-face {
  font-family: "Open Sans Condensed";
  src: url('/build/fonts/opensans-condlight-webfont.eot');
  src: url('/build/fonts/opensans-condlight-webfont.eot?#iefix') format('embedded-opentype'), url('/build/fonts/opensans-condlight-webfont.woff2') format('woff2'), url('/build/fonts/opensans-condlight-webfont.woff') format('woff'), url('/build/fonts/opensans-condlight-webfont.ttf') format('truetype'), url('/build/fonts/opensans-condlight-webfont.svg#opensans-condlight-webfont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans Condensed";
  src: url('/build/fonts/opensans-condbold-webfont.eot');
  src: url('/build/fonts/opensans-condbold-webfont.eot?#iefix') format('embedded-opentype'), url('/build/fonts/opensans-condbold-webfont.woff2') format('woff2'), url('/build/fonts/opensans-condbold-webfont.woff') format('woff'), url('/build/fonts/opensans-condbold-webfont.ttf') format('truetype'), url('/build/fonts/opensans-condbold-webfont.svg#opensans-condbold-webfont') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url('/build/fonts/opensans-regular-webfont.eot');
  src: url('/build/fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'), url('/build/fonts/opensans-regular-webfont.woff2') format('woff2'), url('/build/fonts/opensans-regular-webfont.woff') format('woff'), url('/build/fonts/opensans-regular-webfont.ttf') format('truetype'), url('/build/fonts/opensans-regular-webfont.svg#opensans-regular-webfont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url('/build/fonts/opensans-bold-webfont.eot');
  src: url('/build/fonts/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'), url('/build/fonts/opensans-bold-webfont.woff2') format('woff2'), url('/build/fonts/opensans-bold-webfont.woff') format('woff'), url('/build/fonts/opensans-bold-webfont.ttf') format('truetype'), url('/build/fonts/opensans-bold-webfont.svg#opensans-bold-webfont') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/**
  TAILLES
*/
#sizes .list .base {
  font-size: 1.4rem;
  line-height: 1.8rem;
}
#sizes .list .xxl {
  font-size: 4.8rem;
  line-height: 4.8rem;
}
#sizes .list .xl_l {
  font-size: 3.6rem;
  line-height: 3.6rem;
}
#sizes .list .l {
  font-size: 3.3rem;
  line-height: 3.6rem;
}
#sizes .list .m {
  font-size: 2.4rem;
  line-height: 3rem;
}
#sizes .list .s {
  font-size: 1.8rem;
  line-height: 2.2rem;
}
#sizes .list .xs {
  font-size: 1.2rem;
  line-height: 1.5rem;
}
#sizes .list .xxs {
  font-size: 1rem;
  line-height: 1.4rem;
}
#sizes .list .quotation {
  font-size: 1.4rem;
  line-height: 2.4rem;
}
/**
  FONT COMPLETES
*/
#completes .list .base {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
}
#completes .list .xxl_title_uppercase {
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: white;
}
#completes .list .s_subtitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: white;
}
#completes .list .s_form_submit {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
}
#completes .list .xl_l_key_number {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
}
#completes .list .l {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
#completes .list .quotation {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #474747;
  font-style: italic;
}
#completes .list .m {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
}
#completes .list .m_cond {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
}
#completes .list .xxs {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.4rem;
  color: #474747;
}
body,
.txt-base,
.txt-base:hover,
.txt-base:focus,
.txt-base:visited {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
}
.privacy-center-button {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 5px 15px;
  color: #fff;
  display: flex;
  align-items: center;
  background-color: #bbbbbb;
  border-top-left-radius: 4px;
  z-index: 3000;
  font-family: 'Open Sans', sans-serif;
}
.privacy-center-button:hover {
  color: #fff;
  background-color: #27a3b2;
}
.google-button {
  all: unset;
  margin: auto;
  width: fit-content;
  height: 52px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: all 300ms ease-in-out;
  display: flex;
  justify-content: space-between;
}
.google-button .google-icon-wrapper {
  margin: 1px;
  width: 50px;
  height: 50px;
  border-radius: 1px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.google-button .google-button-text {
  display: flex;
  color: #ffffff!important;
  font-size: 16px;
  letter-spacing: 0.2px;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 0!important;
  margin-bottom: 0!important;
  align-items: center;
}
.google-button:hover {
  box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
  cursor: pointer;
}
.google-button:active {
  background: #3367d6;
}
/**
  PADDING DES BOUTONS
*/
/**
  STYLE DES BOUTONS
*/
.button.white1 {
  color: #05d8c6;
  border: 1px solid #05d8c6;
  background-color: transparent;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
}
.button.white1:hover {
  color: white;
  border: 1px solid #05d8c6;
  background-color: #05d8c6;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
.button.white2 {
  color: #ff580c;
  border: 1px solid #ff580c;
  background-color: transparent;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
}
.button.white2:hover {
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
.button.green1 {
  color: white;
  border: 1px solid #05d8c6;
  background-color: #05d8c6;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
}
.button.green1:hover {
  color: white;
  border: 1px solid #27a3b2;
  background-color: #27a3b2;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
.button.orange1 {
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
}
.button.orange1:hover {
  color: white;
  border: 1px solid #f64e01;
  background-color: #f64e01;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
#paddings .list .button.padding-1 {
  padding: 10px 20px;
}
/**
  BUTTON STYLES
*/
#styles .list .button.style-white1 {
  color: #05d8c6;
  border: 1px solid #05d8c6;
  background-color: transparent;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
#styles .list .button.style-white2 {
  color: #ff580c;
  border: 1px solid #ff580c;
  background-color: transparent;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
#styles .list .button.style-green1 {
  color: white;
  border: 1px solid #05d8c6;
  background-color: #05d8c6;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
#styles .list .button.style-green2 {
  color: white;
  border: 1px solid #27a3b2;
  background-color: #27a3b2;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
#styles .list .button.style-orange1 {
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
#styles .list .button.style-orange2 {
  color: white;
  border: 1px solid #f64e01;
  background-color: #f64e01;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
.iti__flag {
  background-image: url("../img/flags.png");
}
@media (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../img/flags@2x.png");
  }
}
.d-flex-align-center {
  display: flex;
  align-items: center;
}
.d-flex-align-center label {
  font-weight: normal;
}
.d-flex-justify-center {
  display: flex;
  justify-content: center;
}
.absolute-icon-parent {
  position: relative;
}
.absolute-icon-parent .see-password-icon {
  position: absolute;
  right: 1.5rem;
  top: .8rem;
  cursor: pointer;
  color: lightgrey;
}
.input_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  height: 40px;
  position: relative;
  cursor: pointer;
  margin: 15px 0;
}
.input_wrapper input[type="checkbox"] {
  width: 80px;
  height: 40px;
  cursor: pointer;
  appearance: none;
  background: #5c6369;
  border-radius: 3rem;
  position: relative;
  outline: 0;
  transition: all .2s;
}
.input_wrapper input[type="checkbox"]::after {
  position: absolute;
  content: "";
  top: 3px;
  left: 3px;
  width: 34px;
  height: 34px;
  background: #ffffff;
  z-index: 2;
  border-radius: 6rem;
  transition: all .35s;
}
.input_wrapper svg {
  position: absolute;
  top: 50%;
  transform-origin: 50% 50%;
  fill: #fff;
  transition: all .35s;
  z-index: 1;
}
.input_wrapper .is_checked {
  width: 18px;
  left: 2%;
  top: 38%;
  transform: translateX(0px) translateY(-40%) scale(1);
}
.input_wrapper .is_unchecked {
  width: 15px;
  left: 6%;
  top: 40%;
  transform: translateX(10px) translateY(-40%) scale(1);
}
/* Checked State */
.input_wrapper input[type="checkbox"]:checked {
  background: #ff804b;
}
.input_wrapper input[type="checkbox"]:checked:after {
  left: calc(55%);
}
.input_wrapper input[type="checkbox"]:checked + .is_checked {
  transform: translateX(0) translateY(-30%) scale(1);
}
.input_wrapper input[type="checkbox"]:checked ~ .is_unchecked {
  transform: translateX(-190%) translateY(-30%) scale(0);
}
/****** LAYOUTS ******/
/** Icons **/
.size-14px {
  width: 14px;
  height: 14px;
}
.my-account-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-image: url('/build/images/theme/lisboa/consumer/user-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.im-pro-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-image: url('/build/images/theme/lisboa/consumer/pro-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.logout-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-image: url('/build/images/theme/lisboa/consumer/logout-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.podcast-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-image: url('/build/images/theme/lisboa/consumer/podcast-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
}
.at-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-image: url('/build/images/theme/lisboa/consumer/at-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
}
.search-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-image: url('/build/images/theme/lisboa/consumer/search-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  position: relative;
  top: 4px;
  left: 7px;
  cursor: pointer;
}
.right-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-image: url('/build/images/theme/lisboa/consumer/right-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  margin-right: 0;
}
.consumer-logout {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
}
.free-estimate-btn:focus {
  color: #fff;
}
.gray-line {
  border-bottom: 1px solid rgba(217, 217, 217, 0.37);
}
/* ==========================================================================
   HEADER
   ========================================================================== */
header .headerbar a,
nav .menubar a {
  color: black;
}
i {
  font-family: "FontAwesome";
  margin: 0 0.4rem;
  font-style: normal;
}
.separator-horizontaly {
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
}
.separator-verticaly {
  height: 2rem;
  width: 1px;
  margin: 0.6rem 0.8rem;
  background: #e3e1e1;
}
.hamburger {
  display: none;
}
.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  background-color: #747474;
}
header {
  z-index: 999;
  padding: 2rem 0;
  font-size: 14px;
  border-bottom: 0.1rem solid rgba(217, 217, 217, 0.37);
  background-color: white;
  transition: 1s;
}
header .headerbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
}
header .headerbar .headerbar-left a {
  position: relative;
  display: block;
  width: 250px;
}
header .headerbar .headerbar-left a img {
  width: 100%;
  height: auto;
}
header .headerbar .headerbar-right {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
header .headerbar .headerbar-right a {
  margin-left: 1.5rem;
}
header .headerbar .headerbar-right .free-estimate {
  position: relative;
  padding: 1.8rem 2rem;
  z-index: 99;
  border: 0.1rem solid #ff580c;
  white-space: normal;
  background-color: #ff580c;
  color: white;
  transform: skew(-20deg);
  transition: 0.3s;
}
header .headerbar .headerbar-right .free-estimate::before {
  content: '';
  position: absolute;
  left: -2rem;
  top: -0.1rem;
  width: 1rem;
  height: 104%;
  background-color: #ff580c;
}
header .headerbar .headerbar-right .free-estimate:hover,
header .headerbar .headerbar-right .free-estimate::before:hover {
  border: 0.1rem solid #e9500a;
  background-color: #e9500a;
}
header .headerbar .headerbar-right .free-estimate div {
  display: block;
  white-space: normal;
  font-weight: bold;
  transform: skew(20deg, 0deg);
}
header .headerbar .headerbar-right .log-block {
  display: flex;
}
header .headerbar .headerbar-right .log-block .headerbar-item {
  position: relative;
  white-space: nowrap;
  margin-left: 2rem;
  display: flex;
  align-items: center;
}
header .headerbar .headerbar-right .log-block .headerbar-item::before {
  content: '';
  display: inline-block;
  position: absolute;
  bottom: -0.8rem;
  left: -2px;
  right: 0;
  width: 0%;
  border-bottom: 0.2rem solid black;
  transition: 0.3s;
}
header .headerbar .headerbar-right .log-block .headerbar-item > i {
  display: block;
}
header .headerbar .headerbar-right .log-block .headerbar-item:hover::before,
header .headerbar .headerbar-right .log-block .im-pro-custom:hover::before {
  width: 104%;
}
header .headerbar .headerbar-right .log-block .im-pro-custom {
  color: #ff580c;
}
header .headerbar .headerbar-right .log-block .im-pro-custom::before {
  content: '';
  display: inline-block;
  position: absolute;
  bottom: -0.8rem;
  left: -2px;
  right: 0;
  width: 0%;
  border-bottom: 0.2rem solid #ff580c;
  transition: 0.3s;
}
/* ==========================================================================
   MENU
   ========================================================================== */
nav.container {
  position: relative;
  padding: 2rem 0 !important;
  background-color: white;
  font-size: 14px;
}
nav.container .menubar {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  z-index: 9999;
}
nav.container .menubar .current-category-list {
  position: relative;
}
nav.container .menubar .current-category-list .current-category-title {
  position: absolute;
  top: -1.6rem;
  left: 1.6rem;
  font-size: 1rem;
  color: #474747;
}
nav.container .menubar .current-category-list ul {
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  padding: 0.78rem 0;
  list-style: none;
  background-color: #f2f2f2;
  border-radius: 999rem;
}
nav.container .menubar .current-category-list ul li {
  margin-left: 0.2rem;
}
nav.container .menubar .current-category-list ul li a {
  padding: 0.7rem 1.4rem;
  white-space: nowrap;
  border-radius: 2rem;
  transition: background-color 0.3s;
  will-change: background-color;
}
nav.container .menubar .current-category-list ul li a:hover {
  background-color: #e8e8e8;
}
nav.container .menubar .all-advice-block {
  margin: 0 1.2rem 0 0;
}
nav.container .menubar .all-advice-block .all-advice-btn {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.7rem 1.4rem;
  border-radius: 2rem;
  background-color: #01ccbb;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  font-weight: bold;
  color: white;
  transition: 0.3s;
}
nav.container .menubar .all-advice-block .all-advice-btn i {
  margin: 0 0.4rem !important;
}
nav.container .menubar .all-advice-block .all-advice-expand {
  position: absolute;
  display: none;
  z-index: 99999;
}
nav.container .menubar .all-advice-block .all-advice-expand .all-category-list {
  width: 65%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem;
}
nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .title {
  position: absolute;
  top: 1.2rem;
  left: 2rem;
  font-size: 1.6rem;
  font-weight: bold;
}
nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .see-more {
  position: absolute;
  top: 1.2rem;
  right: 2rem;
  font-size: 1.6rem;
  font-weight: bold;
  color: #e9500a;
}
nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .list-category {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-left: 1rem;
  margin: 2rem 0 0 0;
  list-style: none;
}
@media only screen and (max-width: 1171px) {
  nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .list-category {
    grid-template-columns: repeat(3, 1fr);
  }
}
nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .list-category .category-item {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 1rem 0;
}
nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .list-category .category-item::before {
  content: "\2022";
  color: #ff580c;
  font-weight: bold;
  display: inline-block;
  margin-left: -1em;
}
nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .list-category .category-item a {
  padding: 0.7rem 1.4rem;
  border-radius: 999rem;
  text-decoration: none;
  background-color: transparent;
  white-space: nowrap;
  transition: background-color 0.3s;
  will-change: background-color;
}
nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .list-category .category-item a:hover {
  background-color: #e9e9e9;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 0 1rem 1rem 0;
  padding: 3rem;
  width: 35%;
  background-color: #fbfbfb;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .title {
  position: absolute;
  top: 1.2rem;
  left: 2rem;
  font-size: 1.6rem;
  font-weight: bold;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 1rem;
  color: black;
  text-decoration: none;
  cursor: pointer;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item:first-of-type {
  margin-top: 2rem;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item:hover {
  color: black;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item:hover .latest-article-content {
  background-color: #e9e9e9;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item .latest-article-img {
  z-index: 2;
  width: 10rem;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item .latest-article-img img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item .latest-article-content {
  display: flex;
  width: 100%;
  flex-flow: column;
  padding: .6rem 0.1rem .6rem 1.8rem;
  margin-left: -0.8rem;
  transition: 0.3s;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item .latest-article-content div span {
  border-bottom: 0.2rem solid #d6d6d669;
  padding-bottom: 0.1rem;
  color: #ff580c;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item .latest-article-content span {
  margin-top: 1.4rem;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item .latest-article-go {
  display: flex;
  align-items: center;
  padding: 0.4rem 0.6rem;
  border-radius: 0 1rem 1rem 0;
  background-color: #ff580c;
  opacity: 0;
  transition: 0.3s;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item .latest-article-go > i {
  color: #f9f8f6;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item:hover .latest-article-go {
  opacity: 1;
}
nav.container .menubar .all-advice-block.dropDownOpen .all-advice-expand {
  left: 0%;
  right: 0%;
  top: 6rem;
  display: flex;
  background: white;
  border: 0.1rem solid #f5f5f5;
  border-radius: 1rem;
  padding: 0;
}
nav.container .menubar .podcast-newsletter-list {
  display: flex;
}
nav.container .menubar .podcast-newsletter-list .button-item a {
  display: flex;
  align-items: center;
  padding: 0.7rem 1.4rem;
  white-space: nowrap;
  border-radius: 2rem;
  transition: background-color 0.3s;
  will-change: background-color;
}
nav.container .menubar .podcast-newsletter-list .button-item a:hover {
  background-color: #e8e8e8;
}
@media only screen and (max-width: 1200px) {
  nav .menubar .podcast-newsletter-list {
    margin-top: 1rem;
  }
}
.text-blue {
  color: #01ccbb;
}
/* ==========================================================================
      SUB MENU
  ========================================================================== */
.sub-menu {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 1rem 0;
}
@media only screen and (max-width: 768px) {
  .sub-menu {
    flex-flow: column nowrap;
  }
}
.sub-menu li.sub-menu-item.dropdown {
  display: block;
}
.sub-menu li.sub-menu-item.dropdown .dropdown-toggle {
  color: #474747;
  line-height: 1.8rem;
}
.sub-menu li.sub-menu-item.dropdown .dropdown-toggle img {
  margin-left: 5px;
}
.sub-menu li.sub-menu-item.dropdown .dropdown-toggle:after {
  display: none;
}
.sub-menu li.sub-menu-item.dropdown .dropdown-menu {
  box-shadow: none;
  width: 120%;
  padding: 0;
  border-radius: 0.6rem;
}
.sub-menu li.sub-menu-item.dropdown .dropdown-menu .dropdown-item {
  padding: 2rem;
  font-size: 1.3rem;
  border-radius: 0.6rem;
  transition: 0.3s;
}
.sub-menu li.sub-menu-item.dropdown .dropdown-menu .dropdown-item:hover {
  color: #ff580c;
  background-color: #f0f0f0;
}
.sub-menu li.sub-menu-item.dropdown > a > span {
  display: inline-block;
  color: black;
}
.sub-menu li .menu-button,
.sub-menu li.sub-menu-item.dropdown > a > span {
  padding: 2rem;
}
@media only screen and (max-width: 768px) {
  .sub-menu li .menu-button,
  .sub-menu li.sub-menu-item.dropdown > a > span {
    padding: 2rem 0;
  }
}
.sub-menu li .menu-button a {
  position: relative;
  padding: 15px 0;
  color: black;
}
.sub-menu li .menu-button a::before {
  content: '';
  display: inline-block;
  position: absolute;
  bottom: 1rem;
  left: 0px;
  right: 0;
  width: 0%;
  border-bottom: 0.2rem solid #ff580c;
  transition: 0.3s;
}
.sub-menu li .menu-button a:hover::before {
  content: '';
  display: inline-block;
  position: absolute;
  bottom: 1rem;
  left: 0px;
  right: 0;
  width: 100%;
  border-bottom: 0.2rem solid #ff580c;
  transition: 0.3s;
}
/* ================== FORMAT MOBILE ================== */
@media only screen and (max-width: 1075px) {
  .hamburger {
    display: block;
    position: absolute;
    left: 1rem;
    top: 1.6rem;
    cursor: pointer;
  }
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  header {
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    padding: 0;
    margin: 0;
    font-size: 16px;
    z-index: 99999;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    border-bottom: none;
  }
  header .container {
    width: 100% !important;
    margin: 0;
    padding: 0;
  }
  header .headerbar {
    height: auto;
    padding: 0;
  }
  header .headerbar .headerbar-left a {
    margin: 0 1rem 0 5rem;
    min-width: 135px;
    max-width: 135px;
  }
  header .headerbar .headerbar-left a img {
    width: 100%;
  }
  header .headerbar .headerbar-right .free-estimate {
    padding: 15px 10px;
    height: 100%;
    width: 90px;
    text-align: center;
    font-weight: 300;
    position: relative;
    left: -2rem;
    margin-left: 0;
    z-index: 99;
    border: 0.1rem solid #ff580c;
    white-space: normal;
    background-color: #ff580c;
    color: white;
    transition: 0.3s;
  }
  header .headerbar .headerbar-right .free-estimate::before {
    left: -1rem;
    width: 5px;
  }
  header .headerbar .headerbar-right .free-estimate div {
    position: relative;
    display: block;
    white-space: normal;
    font-weight: bold;
    transform: skew(20deg, 0deg);
  }
  header .headerbar .headerbar-right .log-block {
    display: none;
  }
  header .headerbar .headerbar-right .log-block.active {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    background-color: #fff;
    border-top: 0.1rem solid #f1f1f1;
  }
  header .headerbar .headerbar-right .log-block .headerbar-item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 2rem;
  }
  header .headerbar .headerbar-right .log-block .headerbar-item::before {
    display: none;
  }
  header .headerbar .headerbar-right .log-block .headerbar-item i {
    display: block;
  }
  header .headerbar .headerbar-right .log-block .im-pro-custom {
    border-left: 0.1rem solid #f1f1f1;
  }
  header .headerbar .headerbar-right .log-block .im-pro-custom::before {
    display: none;
  }
  .menubar .separator-verticaly.mobile,
  .podcast-newsletter-list .separator-verticaly.mobile {
    display: none;
  }
  nav.container {
    box-shadow: none;
    padding: 0 !important;
    margin: 0;
  }
  nav.container .menubar {
    position: fixed;
    z-index: 9999;
    left: -100%;
    top: 6.4rem;
    flex-flow: column nowrap;
    justify-content: normal;
    width: 100%;
    height: 100%;
    padding: 3.2rem 0 10rem 0;
    margin-top: 0;
    box-shadow: none;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 14px;
    background-color: #fff;
    text-align: center;
    transition: 0.3s;
  }
  nav.container .menubar.active {
    left: 0;
  }
  nav.container .menubar .current-category-list {
    width: 100%;
  }
  nav.container .menubar .current-category-list .current-category-title {
    top: -1.4rem;
    left: 1.6rem;
    font-size: 1.2rem;
  }
  nav.container .menubar .current-category-list ul {
    flex-direction: column;
    margin: 1rem 0;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }
  nav.container .menubar .current-category-list ul li {
    margin: 0.2rem 0;
    text-align: left;
  }
  nav.container .menubar .current-category-list ul li a {
    display: block;
    padding: 1.6rem 4rem;
    width: 100%;
    border-radius: 0;
    background-color: #f8f8f8;
  }
  nav.container .menubar .all-advice-block {
    width: 100%;
    margin: 0.4rem 0;
    padding: 0 2rem;
  }
  nav.container .menubar .all-advice-block .all-advice-btn {
    justify-content: start;
    padding: 1.4rem 1.8rem;
    text-align: left;
  }
  nav.container .menubar .all-advice-block.dropDownOpen .all-advice-expand {
    position: initial;
    width: 100%;
    flex-direction: column-reverse;
    padding: 0;
    border-radius: 0;
    border: none;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .all-category-list {
    width: 100%;
    padding: 0;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .title {
    position: initial;
    top: 0;
    left: 0;
    margin: 2rem 0;
    font-size: 1.8rem;
    text-align: left;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .see-more {
    top: 0;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .list-category {
    padding: 0;
    margin-top: 0;
    grid-template-columns: repeat(1, 1fr);
    text-align: left;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .list-category .category-item {
    padding: 0;
    margin: 1.2rem 0;
    text-align: left;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .list-category .category-item::before {
    display: none;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .list-category .category-item a {
    width: 100%;
    display: inline-block;
    padding: 1rem;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles {
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .title {
    position: initial;
    top: 0;
    left: 0;
    margin: 2rem 0;
    padding: 0;
    font-size: 1.8rem;
    text-align: left;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item {
    margin-top: 0;
    margin-bottom: 3rem;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item .latest-article-img {
    display: none;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item .latest-article-content {
    padding: 1.2rem;
    text-align: left;
  }
  nav.container .menubar .podcast-newsletter-list {
    flex-direction: column;
    margin-top: 0;
    padding: 0 2rem;
    padding-bottom: 12rem;
    width: 100%;
    text-align: left;
  }
  nav.container .menubar .podcast-newsletter-list .button-item {
    margin: 0.4rem 0;
    width: 100%;
  }
  nav.container .menubar .podcast-newsletter-list .button-item a {
    display: flex;
    padding: 1.4rem 1.8rem;
    width: 100%;
    background-color: #f4f4f4;
    transition: 0.3s;
  }
  nav.container .menubar .podcast-newsletter-list .button-item a:hover {
    background-color: #e7e7e7;
  }
}
.footer-consumer {
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  padding: 30px 0 15px 0;
  font-size: 1.5rem;
}
.footer-consumer .heading {
  font-weight: bold;
  font-size: 1.6rem;
  margin-bottom: 10px;
}
@media screen and (max-width:767px) {
  .footer-consumer .heading {
    font-size: 1.8rem;
    margin-top: 15px;
  }
}
.footer-consumer hr {
  margin: 20px 0;
}
.footer-consumer a {
  color: #fff;
}
.footer-consumer ul {
  line-height: 30px;
}
.footer-consumer .phone a {
  color: #05d8c6;
}
.footer-consumer .community-list ul {
  width: 195px;
  margin: auto;
}
.footer-consumer .community-list a {
  float: left;
  display: inline-block;
  margin: 0 10px;
}
.footer-consumer .community-list .facebook {
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
  background-position: 0 -120px;
  width: 44px;
  height: 44px;
  background-position: 0 -121px;
}
.footer-consumer .community-list .twitter {
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
  background-position: -50px -120px;
  width: 44px;
  height: 44px;
  background-position: -51px -121px;
}
.footer-consumer .community-list .youtube {
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
  background-position: -100px -120px;
  width: 44px;
  height: 44px;
  background-position: -101px -121px;
}
.footer-consumer .community-list .google {
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
  background-position: -150px -120px;
  width: 44px;
  height: 44px;
}
.footer-consumer .community-list .pinterest {
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
  background-position: -200px -120px;
  width: 44px;
  height: 44px;
  background-position: -201px -121px;
}
.footer-consumer .logo {
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
}
@media screen and (max-width:575px) {
  .footer-consumer .link-column > div:nth-of-type(2) .heading {
    display: none;
  }
  .footer-consumer .link-column > div:nth-of-type(2) ul {
    display: none;
  }
  .footer-consumer .link-column > div:nth-of-type(3) .heading {
    display: none;
  }
  .footer-consumer .link-column > div:nth-of-type(3) ul {
    display: none;
  }
}
/** Icons **/
.size-14px {
  width: 14px;
  height: 14px;
}
.my-account-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-image: url('/build/images/theme/lisboa/consumer/user-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.im-pro-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-image: url('/build/images/theme/lisboa/consumer/pro-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.logout-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-image: url('/build/images/theme/lisboa/consumer/logout-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.podcast-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-image: url('/build/images/theme/lisboa/consumer/podcast-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
}
.at-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-image: url('/build/images/theme/lisboa/consumer/at-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
}
.search-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-image: url('/build/images/theme/lisboa/consumer/search-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  position: relative;
  top: 4px;
  left: 7px;
  cursor: pointer;
}
.right-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-image: url('/build/images/theme/lisboa/consumer/right-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  margin-right: 0;
}
.consumer-logout {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
}
.free-estimate-btn:focus {
  color: #fff;
}
.gray-line {
  border-bottom: 1px solid rgba(217, 217, 217, 0.37);
}
/* ==========================================================================
   HEADER
   ========================================================================== */
header .headerbar a,
nav .menubar a {
  color: black;
}
i {
  font-family: "FontAwesome";
  margin: 0 0.4rem;
  font-style: normal;
}
.separator-horizontaly {
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
}
.separator-verticaly {
  height: 2rem;
  width: 1px;
  margin: 0.6rem 0.8rem;
  background: #e3e1e1;
}
.hamburger {
  display: none;
}
.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  background-color: #747474;
}
header {
  z-index: 999;
  padding: 2rem 0;
  font-size: 14px;
  border-bottom: 0.1rem solid rgba(217, 217, 217, 0.37);
  background-color: white;
  transition: 1s;
}
header .headerbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
}
header .headerbar .headerbar-left a {
  position: relative;
  display: block;
  width: 250px;
}
header .headerbar .headerbar-left a img {
  width: 100%;
  height: auto;
}
header .headerbar .headerbar-right {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
header .headerbar .headerbar-right a {
  margin-left: 1.5rem;
}
header .headerbar .headerbar-right .free-estimate {
  position: relative;
  padding: 1.8rem 2rem;
  z-index: 99;
  border: 0.1rem solid #ff580c;
  white-space: normal;
  background-color: #ff580c;
  color: white;
  transform: skew(-20deg);
  transition: 0.3s;
}
header .headerbar .headerbar-right .free-estimate::before {
  content: '';
  position: absolute;
  left: -2rem;
  top: -0.1rem;
  width: 1rem;
  height: 104%;
  background-color: #ff580c;
}
header .headerbar .headerbar-right .free-estimate:hover,
header .headerbar .headerbar-right .free-estimate::before:hover {
  border: 0.1rem solid #e9500a;
  background-color: #e9500a;
}
header .headerbar .headerbar-right .free-estimate div {
  display: block;
  white-space: normal;
  font-weight: bold;
  transform: skew(20deg, 0deg);
}
header .headerbar .headerbar-right .log-block {
  display: flex;
}
header .headerbar .headerbar-right .log-block .headerbar-item {
  position: relative;
  white-space: nowrap;
  margin-left: 2rem;
  display: flex;
  align-items: center;
}
header .headerbar .headerbar-right .log-block .headerbar-item::before {
  content: '';
  display: inline-block;
  position: absolute;
  bottom: -0.8rem;
  left: -2px;
  right: 0;
  width: 0%;
  border-bottom: 0.2rem solid black;
  transition: 0.3s;
}
header .headerbar .headerbar-right .log-block .headerbar-item > i {
  display: block;
}
header .headerbar .headerbar-right .log-block .headerbar-item:hover::before,
header .headerbar .headerbar-right .log-block .im-pro-custom:hover::before {
  width: 104%;
}
header .headerbar .headerbar-right .log-block .im-pro-custom {
  color: #ff580c;
}
header .headerbar .headerbar-right .log-block .im-pro-custom::before {
  content: '';
  display: inline-block;
  position: absolute;
  bottom: -0.8rem;
  left: -2px;
  right: 0;
  width: 0%;
  border-bottom: 0.2rem solid #ff580c;
  transition: 0.3s;
}
/* ==========================================================================
   MENU
   ========================================================================== */
nav.container {
  position: relative;
  padding: 2rem 0 !important;
  background-color: white;
  font-size: 14px;
}
nav.container .menubar {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  z-index: 9999;
}
nav.container .menubar .current-category-list {
  position: relative;
}
nav.container .menubar .current-category-list .current-category-title {
  position: absolute;
  top: -1.6rem;
  left: 1.6rem;
  font-size: 1rem;
  color: #474747;
}
nav.container .menubar .current-category-list ul {
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  padding: 0.78rem 0;
  list-style: none;
  background-color: #f2f2f2;
  border-radius: 999rem;
}
nav.container .menubar .current-category-list ul li {
  margin-left: 0.2rem;
}
nav.container .menubar .current-category-list ul li a {
  padding: 0.7rem 1.4rem;
  white-space: nowrap;
  border-radius: 2rem;
  transition: background-color 0.3s;
  will-change: background-color;
}
nav.container .menubar .current-category-list ul li a:hover {
  background-color: #e8e8e8;
}
nav.container .menubar .all-advice-block {
  margin: 0 1.2rem 0 0;
}
nav.container .menubar .all-advice-block .all-advice-btn {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.7rem 1.4rem;
  border-radius: 2rem;
  background-color: #01ccbb;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  font-weight: bold;
  color: white;
  transition: 0.3s;
}
nav.container .menubar .all-advice-block .all-advice-btn i {
  margin: 0 0.4rem !important;
}
nav.container .menubar .all-advice-block .all-advice-expand {
  position: absolute;
  display: none;
  z-index: 99999;
}
nav.container .menubar .all-advice-block .all-advice-expand .all-category-list {
  width: 65%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem;
}
nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .title {
  position: absolute;
  top: 1.2rem;
  left: 2rem;
  font-size: 1.6rem;
  font-weight: bold;
}
nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .see-more {
  position: absolute;
  top: 1.2rem;
  right: 2rem;
  font-size: 1.6rem;
  font-weight: bold;
  color: #e9500a;
}
nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .list-category {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-left: 1rem;
  margin: 2rem 0 0 0;
  list-style: none;
}
@media only screen and (max-width: 1171px) {
  nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .list-category {
    grid-template-columns: repeat(3, 1fr);
  }
}
nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .list-category .category-item {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 1rem 0;
}
nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .list-category .category-item::before {
  content: "\2022";
  color: #ff580c;
  font-weight: bold;
  display: inline-block;
  margin-left: -1em;
}
nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .list-category .category-item a {
  padding: 0.7rem 1.4rem;
  border-radius: 999rem;
  text-decoration: none;
  background-color: transparent;
  white-space: nowrap;
  transition: background-color 0.3s;
  will-change: background-color;
}
nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .list-category .category-item a:hover {
  background-color: #e9e9e9;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 0 1rem 1rem 0;
  padding: 3rem;
  width: 35%;
  background-color: #fbfbfb;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .title {
  position: absolute;
  top: 1.2rem;
  left: 2rem;
  font-size: 1.6rem;
  font-weight: bold;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 1rem;
  color: black;
  text-decoration: none;
  cursor: pointer;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item:first-of-type {
  margin-top: 2rem;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item:hover {
  color: black;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item:hover .latest-article-content {
  background-color: #e9e9e9;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item .latest-article-img {
  z-index: 2;
  width: 10rem;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item .latest-article-img img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item .latest-article-content {
  display: flex;
  width: 100%;
  flex-flow: column;
  padding: .6rem 0.1rem .6rem 1.8rem;
  margin-left: -0.8rem;
  transition: 0.3s;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item .latest-article-content div span {
  border-bottom: 0.2rem solid #d6d6d669;
  padding-bottom: 0.1rem;
  color: #ff580c;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item .latest-article-content span {
  margin-top: 1.4rem;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item .latest-article-go {
  display: flex;
  align-items: center;
  padding: 0.4rem 0.6rem;
  border-radius: 0 1rem 1rem 0;
  background-color: #ff580c;
  opacity: 0;
  transition: 0.3s;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item .latest-article-go > i {
  color: #f9f8f6;
}
nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item:hover .latest-article-go {
  opacity: 1;
}
nav.container .menubar .all-advice-block.dropDownOpen .all-advice-expand {
  left: 0%;
  right: 0%;
  top: 6rem;
  display: flex;
  background: white;
  border: 0.1rem solid #f5f5f5;
  border-radius: 1rem;
  padding: 0;
}
nav.container .menubar .podcast-newsletter-list {
  display: flex;
}
nav.container .menubar .podcast-newsletter-list .button-item a {
  display: flex;
  align-items: center;
  padding: 0.7rem 1.4rem;
  white-space: nowrap;
  border-radius: 2rem;
  transition: background-color 0.3s;
  will-change: background-color;
}
nav.container .menubar .podcast-newsletter-list .button-item a:hover {
  background-color: #e8e8e8;
}
@media only screen and (max-width: 1200px) {
  nav .menubar .podcast-newsletter-list {
    margin-top: 1rem;
  }
}
.text-blue {
  color: #01ccbb;
}
/* ================== FORMAT MOBILE ================== */
@media only screen and (max-width: 1075px) {
  .hamburger {
    display: block;
    position: absolute;
    left: 1rem;
    top: 1.6rem;
    cursor: pointer;
  }
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  header {
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    padding: 0;
    margin: 0;
    font-size: 16px;
    z-index: 99999;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    border-bottom: none;
  }
  header .container {
    width: 100% !important;
    margin: 0;
    padding: 0;
  }
  header .headerbar {
    height: auto;
    padding: 0;
  }
  header .headerbar .headerbar-left a {
    margin: 0 1rem 0 5rem;
    min-width: 135px;
    max-width: 135px;
  }
  header .headerbar .headerbar-left a img {
    width: 100%;
  }
  header .headerbar .headerbar-right .free-estimate {
    padding: 15px 10px;
    height: 100%;
    width: 90px;
    text-align: center;
    font-weight: 300;
    position: relative;
    left: -2rem;
    margin-left: 0;
    z-index: 99;
    border: 0.1rem solid #ff580c;
    white-space: normal;
    background-color: #ff580c;
    color: white;
    transition: 0.3s;
  }
  header .headerbar .headerbar-right .free-estimate::before {
    left: -1rem;
    width: 5px;
  }
  header .headerbar .headerbar-right .free-estimate div {
    position: relative;
    display: block;
    white-space: normal;
    font-weight: bold;
    transform: skew(20deg, 0deg);
  }
  header .headerbar .headerbar-right .log-block {
    display: none;
  }
  header .headerbar .headerbar-right .log-block.active {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    background-color: #fff;
    border-top: 0.1rem solid #f1f1f1;
  }
  header .headerbar .headerbar-right .log-block .headerbar-item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 2rem;
  }
  header .headerbar .headerbar-right .log-block .headerbar-item::before {
    display: none;
  }
  header .headerbar .headerbar-right .log-block .headerbar-item i {
    display: block;
  }
  header .headerbar .headerbar-right .log-block .im-pro-custom {
    border-left: 0.1rem solid #f1f1f1;
  }
  header .headerbar .headerbar-right .log-block .im-pro-custom::before {
    display: none;
  }
  .menubar .separator-verticaly.mobile,
  .podcast-newsletter-list .separator-verticaly.mobile {
    display: none;
  }
  nav.container {
    box-shadow: none;
    padding: 0 !important;
    margin: 0;
  }
  nav.container .menubar {
    position: fixed;
    z-index: 9999;
    left: -100%;
    top: 6.4rem;
    flex-flow: column nowrap;
    justify-content: normal;
    width: 100%;
    height: 100%;
    padding: 3.2rem 0 10rem 0;
    margin-top: 0;
    box-shadow: none;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 14px;
    background-color: #fff;
    text-align: center;
    transition: 0.3s;
  }
  nav.container .menubar.active {
    left: 0;
  }
  nav.container .menubar .current-category-list {
    width: 100%;
  }
  nav.container .menubar .current-category-list .current-category-title {
    top: -1.4rem;
    left: 1.6rem;
    font-size: 1.2rem;
  }
  nav.container .menubar .current-category-list ul {
    flex-direction: column;
    margin: 1rem 0;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }
  nav.container .menubar .current-category-list ul li {
    margin: 0.2rem 0;
    text-align: left;
  }
  nav.container .menubar .current-category-list ul li a {
    display: block;
    padding: 1.6rem 4rem;
    width: 100%;
    border-radius: 0;
    background-color: #f8f8f8;
  }
  nav.container .menubar .all-advice-block {
    width: 100%;
    margin: 0.4rem 0;
    padding: 0 2rem;
  }
  nav.container .menubar .all-advice-block .all-advice-btn {
    justify-content: start;
    padding: 1.4rem 1.8rem;
    text-align: left;
  }
  nav.container .menubar .all-advice-block.dropDownOpen .all-advice-expand {
    position: initial;
    width: 100%;
    flex-direction: column-reverse;
    padding: 0;
    border-radius: 0;
    border: none;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .all-category-list {
    width: 100%;
    padding: 0;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .title {
    position: initial;
    top: 0;
    left: 0;
    margin: 2rem 0;
    font-size: 1.8rem;
    text-align: left;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .see-more {
    top: 0;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .list-category {
    padding: 0;
    margin-top: 0;
    grid-template-columns: repeat(1, 1fr);
    text-align: left;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .list-category .category-item {
    padding: 0;
    margin: 1.2rem 0;
    text-align: left;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .list-category .category-item::before {
    display: none;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .all-category-list .list-category .category-item a {
    width: 100%;
    display: inline-block;
    padding: 1rem;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles {
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .title {
    position: initial;
    top: 0;
    left: 0;
    margin: 2rem 0;
    padding: 0;
    font-size: 1.8rem;
    text-align: left;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item {
    margin-top: 0;
    margin-bottom: 3rem;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item .latest-article-img {
    display: none;
  }
  nav.container .menubar .all-advice-block .all-advice-expand .three-latest-articles .latest-article-item .latest-article-content {
    padding: 1.2rem;
    text-align: left;
  }
  nav.container .menubar .podcast-newsletter-list {
    flex-direction: column;
    margin-top: 0;
    padding: 0 2rem;
    padding-bottom: 12rem;
    width: 100%;
    text-align: left;
  }
  nav.container .menubar .podcast-newsletter-list .button-item {
    margin: 0.4rem 0;
    width: 100%;
  }
  nav.container .menubar .podcast-newsletter-list .button-item a {
    display: flex;
    padding: 1.4rem 1.8rem;
    width: 100%;
    background-color: #f4f4f4;
    transition: 0.3s;
  }
  nav.container .menubar .podcast-newsletter-list .button-item a:hover {
    background-color: #e7e7e7;
  }
}
#temp-banner {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: auto;
}
#temp-banner-mobile {
  display: none;
}
@media only screen and (max-width: 1200px) {
  #parent-temp-banner,
  #temp-banner {
    width: 100%;
  }
}
@media only screen and (max-width: 512px) {
  #parent-temp-banner,
  #temp-banner {
    display: none;
  }
  #parent-temp-banner,
  #temp-banner-mobile {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: 100%;
  }
}
footer {
  background-color: #082b37;
  padding: 40px 0 30px;
}
footer .strong_text,
footer .menu .column .heading,
footer .contact .phone,
footer .contact .text .heading,
footer .community-container .text {
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  color: #fff;
}
footer .normal_text,
footer .menu .column .item a,
footer .contact .text .heading .subheading {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  color: #fff;
}
@media (max-width:767px) {
  footer .menu .object-row-container {
    display: table;
    width: 100%;
  }
  footer .menu .object-row-separator {
    height: 20px;
  }
}
footer .menu .column .heading {
  padding: 2px 0;
}
footer .menu .column .item {
  padding: 5px 0;
  display: table-cell;
  vertical-align: middle;
}
footer .menu .column .item a {
  display: block;
}
footer .menu .column .item a:hover {
  text-decoration: underline;
}
@media (max-width:575px) {
  footer .menu .object-grid-container > div:nth-of-type(4) .heading {
    display: none;
  }
  footer .menu .object-grid-container > div:nth-of-type(4) .object-repeater-container {
    display: none;
  }
  footer .menu .object-grid-container > div:nth-of-type(7) .heading {
    display: none;
  }
  footer .menu .object-grid-container > div:nth-of-type(7) .object-repeater-container {
    display: none;
  }
  footer .menu .object-grid-container > div:nth-of-type(2),
  footer .menu .object-grid-container > div:nth-of-type(3),
  footer .menu .object-grid-container > div:nth-of-type(5),
  footer .menu .object-grid-container > div:nth-of-type(6),
  footer .menu .object-grid-container > div:nth-of-type(8),
  footer .menu .object-grid-container > div:nth-of-type(9) {
    display: none;
  }
}
@media (min-width:768px) {
  footer .contact {
    padding: 0;
  }
}
@media (max-width:767px) {
  footer .contact {
    margin-top: 20px;
    padding: 0 30px;
  }
}
footer .contact .phone .number {
  color: #05d8c6;
}
footer .contact .text .heading {
  margin: 20px 0;
}
footer .contact .text .heading .subheading {
  font-weight: normal;
}
footer .contact .text .newsletter-footer {
  margin-top: 40px;
}
footer .contact .text .newsletter-footer .newsletter-btn {
  padding: 10px 20px;
  color: #fff;
  background-color: #ff580c;
  border-radius: 5px;
  -webkit-user-select: none;
  user-select: none;
  font-weight: bold;
  transition: 0.3s;
}
footer .contact .text .newsletter-footer .newsletter-btn:hover {
  color: #fff;
  background-color: #ee520a;
}
footer .contact .text .newsletter-footer .newsletter-btn > i {
  margin-right: 5px;
  font-family: FontAwesome;
  font-style: normal;
}
footer .community-container {
  margin-top: 40px;
}
@media (max-width:767px) {
  footer .community-container {
    margin-top: 20px;
  }
}
footer .community-container .text {
  text-align: center;
  margin-bottom: 20px;
}
@media (max-width:767px) {
  footer .padd-community {
    padding-left: 0;
    padding-right: 0;
  }
}
footer .corporation .positioner {
  display: table;
  margin: 0 auto;
}
footer .corporation .positioner .line {
  color: #fff;
  float: left;
}
@media (max-width:767px) {
  footer .corporation .positioner .line {
    width: 100%;
    text-align: center;
  }
}
footer .corporation .positioner .line .line-part {
  display: inline-block;
  text-align: center;
  margin: 3px 0 0;
}
@media (min-width:768px) {
  footer .corporation .positioner .line .line-part {
    float: left;
    margin: 0 0 0 3px;
  }
}
footer .corporation .positioner .line .logo,
footer .corporation .positioner .line .habitatpresto {
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
}
footer .corporation .positioner .line .habitatpresto {
  background-position: 0 -95px;
  width: 186px;
  height: 25px;
  margin-top: -3px;
}
footer .mt-30 {
  margin-top: 30px;
}
footer .mt-20 {
  margin-top: 20px;
}
footer hr {
  border-bottom: 1px solid #fff;
}
footer .sm-hidden {
  display: none;
}
@media (max-width:767px) {
  footer .sm-hidden {
    display: block;
  }
}
footer .form-control {
  height: 40px;
}
footer .siteplan {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
footer .siteplan-item:nth-child(n+2):before {
  content: "\2022";
  display: inline-block;
  margin: 0 20px;
}
footer .siteplan-item {
  color: white;
  margin-bottom: 10px;
}
footer .siteplan-item a {
  color: white;
}
footer .siteplan-item a:hover {
  text-decoration: underline;
}
.arrow-separator-green .line {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.44);
  width: 100%;
  background-color: #05d8c6;
  height: 8px;
}
.arrow-separator-green .arrow {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -145px 0;
  width: 37px;
  height: 31px;
  margin: 0 auto;
}
.image-wrapper {
  display: table;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.image-wrapper .wrapped-content {
  display: table-cell;
  vertical-align: middle;
}
.annuaire-result .image-wrapper .wrapped-content h1 {
  color: #474747;
}
.image-wrapper {
  /*
    CONTENT HEADER FORM
  */
  /*
    CONTENT HEADER TEXT
  */
  /*
    CONTENT HEADER GUIDING
  */
}
.image-wrapper.size-1 {
  height: 500px;
}
.image-wrapper.size-2 {
  height: 150px;
}
.image-wrapper h1 {
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: white;
  text-align: center;
}
.image-wrapper.form h1,
.image-wrapper.form-annuaire h1 {
  margin-bottom: 10px;
}
.image-wrapper.form h2,
.image-wrapper.form-annuaire h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: white;
  text-align: center;
  margin-bottom: 40px;
}
.image-wrapper.form form,
.image-wrapper.form-annuaire form {
  background-color: rgba(7, 26, 33, 0.8);
  padding: 10px;
  width: 100%;
  height: 100%;
  display: table;
}
@media (min-width:768px) {
  .image-wrapper.form form > div,
  .image-wrapper.form-annuaire form > div {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    float: none;
  }
}
.image-wrapper.form form #form-select-container,
.image-wrapper.form-annuaire form #form-select-container {
  padding: 0;
}
.image-wrapper.form form #form-select-container select,
.image-wrapper.form-annuaire form #form-select-container select {
  font-size: 1.8rem;
  height: 45px;
}
.image-wrapper.form form #form-select-container optgroup,
.image-wrapper.form-annuaire form #form-select-container optgroup {
  font-weight: bold;
}
.image-wrapper.form form #form-submit-container,
.image-wrapper.form-annuaire form #form-submit-container {
  padding: 0;
  margin-top: 10px;
}
@media (min-width:768px) {
  .image-wrapper.form form #form-submit-container,
  .image-wrapper.form-annuaire form #form-submit-container {
    margin: 0;
    padding: 0 0 0 10px;
  }
}
.image-wrapper.form form #form-submit-container button,
.image-wrapper.form-annuaire form #form-submit-container button {
  font-size: 1.8rem;
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
  width: 100%;
  min-height: 45px;
}
.image-wrapper.form form #form-submit-container button:hover,
.image-wrapper.form-annuaire form #form-submit-container button:hover {
  color: white;
  border: 1px solid #f64e01;
  background-color: #f64e01;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
.image-wrapper.text h1 {
  margin-top: 20px;
  font-weight: bold;
}
.image-wrapper.text h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  color: white;
  text-align: center;
}
.homepage-banner {
  background: rgba(0, 0, 0, 0.3) url("https://vss.astrocenter.fr/habitatpresto/pictures/29934669-poele-bois.jpg");
  background-blend-mode: darken;
}
.homepage-banner h1,
.homepage-banner h2 {
  color: #fff;
  text-shadow: #000000 0px 3px 6px;
}
@media (max-width:991px) {
  .homepage-banner {
    background: rgba(0, 0, 0, 0.3) url("https://vss.astrocenter.fr/habitatpresto/pictures/29465607-adobestock-229521757.jpeg");
  }
}
/*
  HOME CATEGORY FORM
 */
#home-category-form .select2-selection__arrow {
  height: 45px;
}
#home-category-form .select2-container--default .select2-selection--single {
  height: 45px;
}
#home-category-form .select2-container--default .select2-selection--single .select2-selection__rendered {
  font-size: 1.8rem;
  height: 45px;
  line-height: 45px;
  padding: 0px 15px;
}
#select2-category_url-results {
  font-size: 1.8rem;
  max-height: 500px;
}
#select2-category_url-results .select2-results__option {
  padding: 3px 12px;
}
.select2-search__field[aria-controls="select2-category_url-results"] {
  font-size: 1.8rem;
}
.section-title {
  margin: 30px 0;
}
.section-title h2 {
  color: #474747;
  text-align: center;
}
.section-title .subtitle {
  margin-top: 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  text-align: center;
}
@media (max-width:767px) {
  .section-title .subtitle {
    display: none;
  }
}
.community.list {
  width: 100%;
  display: table;
  text-align: center;
}
.community.list .positioner {
  display: table-cell;
}
.community.list .positioner .block,
.community.list .positioner .facebook,
.community.list .positioner .twitter,
.community.list .positioner .youtube,
.community.list .positioner .google,
.community.list .positioner .pinterest {
  margin: 0 10px;
  display: inline-block;
}
.community.list .positioner .facebook {
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
  background-position: 0 -120px;
  width: 44px;
  height: 44px;
}
.community.list .positioner .twitter {
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
  background-position: -50px -120px;
  width: 44px;
  height: 44px;
}
.community.list .positioner .youtube {
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
  background-position: -100px -120px;
  width: 44px;
  height: 44px;
}
.community.list .positioner .google {
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
  background-position: -150px -120px;
  width: 44px;
  height: 44px;
}
.community.list .positioner .pinterest {
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
  background-position: -200px -120px;
  width: 44px;
  height: 44px;
}
.menu-tab {
  margin-top: 20px;
  margin-bottom: 20px;
}
.menu-tab .item {
  padding: 0 5px;
  height: 45px;
  margin-bottom: 10px;
}
@media (min-width:768px) {
  .menu-tab .item {
    height: 40px;
  }
}
.menu-tab .item .positioner {
  display: table;
  width: 100%;
  height: 100%;
}
.menu-tab .item .positioner a {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
@media (min-width:768px) {
  .menu-tab {
    display: table;
  }
  .menu-tab .item {
    display: table-cell;
    float: none;
    width: auto;
  }
}
.menu-tab {
  display: flex;
  gap: 1rem;
  padding-left: 0;
  padding-right: 0;
}
@media (max-width:767px) {
  .menu-tab {
    flex-direction: column;
  }
}
.menu-tab .item {
  flex: 1;
}
@media (max-width:767px) {
  .menu-tab .item {
    width: 100%;
  }
}
.menu-tab .item .positioner a {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  background-color: #f0f0f0;
  border-radius: 1rem;
}
@media (max-width:767px) {
  .menu-tab .item .positioner a {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
.menu-tab .item .positioner a:hover {
  background-color: #eaeaea;
}
.menu-tab .item.selected .positioner a {
  color: white;
  background-color: #05d8c6;
  border-radius: 1rem;
}
.menu-tab.press-partners .item,
.menu-tab.presse-onglet .item {
  width: 50%;
}
.wrapper-breadcrumb {
  background-color: #fff;
}
.wrapper-breadcrumb .chemin {
  text-transform: uppercase;
  line-height: 2rem;
  margin-bottom: 20px;
}
.wrapper-breadcrumb .chemin a.lien-simple,
.wrapper-breadcrumb .chemin a.lien-simple:active,
.wrapper-breadcrumb .chemin a.lien-simple:visited {
  color: #474747;
  text-decoration: none;
}
.wrapper-breadcrumb .chemin a.lien-simple:hover,
.wrapper-breadcrumb .chemin a.lien-simple:active:hover,
.wrapper-breadcrumb .chemin a.lien-simple:visited:hover {
  text-decoration: underline;
}
.wrapper-breadcrumb .chemin .arrow-grey {
  display: inline-block;
  background-position: -50px 0;
  width: 6px;
  height: 9px;
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
}
.pagination {
  float: none;
  margin: 20px 0 !important;
  justify-content: center;
}
.pagination > .active > span {
  border: 1px solid #ff580c;
  color: #fff;
  background-color: #ff580c;
  margin-top: 0;
}
.pagination > .disabled > span {
  margin-top: 0;
}
.pagination > li > a {
  color: #474747;
  margin-top: 0;
}
.pagination > li > a:hover {
  color: #ff580c;
}
/****** PAGES ******/
#kitmedia .button.white2 {
  color: #ff580c;
  border: 1px solid #ff580c;
  background-color: transparent;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
  display: inline-block;
}
#kitmedia .button.white2:hover {
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
#kitmedia .bg-noir:hover {
  background-color: rgba(0, 0, 0, 0.7);
  transition: background-color 500ms linear;
}
#kitmedia .bg-noir a.button.orange1 {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=(0));
  opacity: 0;
}
#kitmedia .bg-noir:hover a.button.orange1 {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=(100));
  opacity: 1;
  -webkit-transition: opacity 350ms linear;
  -moz-transition: opacity 350ms linear;
  -o-transition: opacity 350ms linear;
  -ms-transition: opacity 350ms linear;
}
#kitmedia .title-block-img .bg-noir:hover p {
  height: 180px;
}
#kitmedia .title-block {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
}
#kitmedia p.chapeau {
  line-height: 25px;
}
#kitmedia .img-logoHabitatPresto,
#kitmedia .img-logoHabitatPresto2,
#kitmedia .img-logoDevisPresto,
#kitmedia .img-logoDevisPresto2 {
  height: 124px;
}
#kitmedia .img-logoHabitatPresto {
  background: url('/images/habitatpresto/logoHabitatPresto.jpg') no-repeat center;
  background-size: cover;
}
#kitmedia .img-logoHabitatPresto2 {
  background: url('/images/habitatpresto/logoHabitatPresto2.jpg') no-repeat center;
  background-size: cover;
}
#kitmedia .img-logoDevisPresto {
  background: url('/images/habitatpresto/logoDevisPresto.jpg') no-repeat center;
  background-size: cover;
}
#kitmedia .img-logoDevisPresto2 {
  background: url('/images/habitatpresto/logoDevisPresto2.jpg') no-repeat center;
  background-size: cover;
}
#kitmedia .bg-noir {
  height: 124px;
  border: solid 1px #bfbbbb;
  background-color: rgba(0, 0, 0, 0);
}
#kitmedia .bg-noir:hover {
  background-color: rgba(0, 0, 0, 0.45);
}
#kitmedia .title-block-img .bg-noir:hover p {
  height: 50px;
}
#kitmedia .title-block-img p {
  height: 50px;
  line-height: 90px;
  text-align: center;
}
#kitmedia .photoBorder {
  border: solid 1px #bfbbbb;
  padding: 12px;
}
#kitmedia .form-group {
  position: relative;
}
#kitmedia .iconLetter {
  width: 50px;
  height: 40px;
  position: absolute;
  background: url("/images/habitatpresto/iconLetter.png") #e6e6e6 no-repeat scroll 10px 10px;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border: 1px solid #ccc;
  left: 0;
}
#kitmedia .bt-ok-news {
  border-radius: 3px;
  -moz-border-radius: 3px;
}
#kitmedia .form-control {
  padding-left: 55px;
  height: 40px;
}
#kitmedia .photo-ecran-1 {
  background: url('/images/habitatpresto/kitmedia/home-page.png') no-repeat center;
  background-size: cover;
  height: 245px;
}
#kitmedia .photo-ecran-2 {
  background: url('/images/habitatpresto/kitmedia/comment-ca-marche.png') no-repeat center;
  background-size: cover;
  height: 245px;
}
#kitmedia .photo-ecran-3 {
  background: url('/images/habitatpresto/kitmedia/devis.png') no-repeat center;
  background-size: cover;
  height: 245px;
}
#kitmedia .photo-ecran-4 {
  background: url('/images/habitatpresto/kitmedia/devis-categorie.png') no-repeat center;
  background-size: cover;
  height: 245px;
}
#kitmedia .photo-ecran-5 {
  background: url('/images/habitatpresto/kitmedia/qui-sommes-nous.png') no-repeat center;
  background-size: cover;
  height: 245px;
}
#kitmedia .photo-ecran-6 {
  background: url('/images/habitatpresto/kitmedia/revue-presse.png') no-repeat center;
  background-size: cover;
  height: 245px;
}
#kitmedia .photo-ecran-7 {
  background: url('/images/habitatpresto/kitmedia/guide-travaux.png') no-repeat center;
  background-size: cover;
  height: 245px;
}
#kitmedia .photo-ecran-8 {
  background: url('/images/habitatpresto/kitmedia/espace-particulier.png') no-repeat center;
  background-size: cover;
  height: 245px;
}
#kitmedia .photo-ecran .bg-noir {
  height: 245px;
}
#kitmedia .ss-title {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
#kitmedia .bt-ecran {
  top: 120px;
  position: relative;
}
#kitmedia .ss-title a {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  text-decoration: none;
}
#kitmedia .ss-title a:hover {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  text-decoration: underline;
}
#kitmedia .mt-20 {
  margin-top: 20px;
}
#kitmedia .mt-30 {
  margin-top: 30px;
}
#kitmedia .mt-10 {
  margin-top: 10px;
}
#kitmedia .mb-10 {
  margin-bottom: 10px;
}
#kitmedia .mt-40 {
  margin-top: 40px;
}
#kitmedia .mt-60 {
  margin-top: 60px;
}
#kitmedia .mb-30 {
  margin-bottom: 30px;
}
#kitmedia .mb-60 {
  margin-bottom: 60px;
}
#kitmedia #contact .newsletter-kit-media .newsletter-btn {
  padding: 10px 20px;
  color: #fff;
  background-color: #ff580c;
  border-radius: 5px;
  -webkit-user-select: none;
  user-select: none;
  font-weight: bold;
  transition: 0.3s;
}
#kitmedia #contact .newsletter-kit-media .newsletter-btn:hover {
  color: #fff;
  background-color: #ee520a;
}
#kitmedia #contact .newsletter-kit-media .newsletter-btn > i {
  margin-right: 5px;
  font-family: FontAwesome;
  font-style: normal;
}
.faq-container {
  margin: 40px 0;
}
.faq-container .mt-40 {
  margin-top: 40px;
}
.faq-container .mt-20 {
  margin-top: 20px;
}
.faq-container .faq-group {
  margin-top: 30px;
}
.faq-container .faq-group .title {
  text-align: center;
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.faq-container .faq-group .panel-group.group1 .panel {
  border-radius: 0;
  position: relative;
  cursor: pointer;
  border-color: #ececec;
}
.faq-container .faq-group .panel-group.group1 .panel[aria-expanded=true] {
  border-color: #c8c8c8;
}
.faq-container .faq-group .panel-group.group1 .panel[aria-expanded=true] .panel-caret {
  background-position: -60px 0;
  width: 14px;
  height: 8px;
  transform: rotate(0deg);
}
.faq-container .faq-group .panel-group.group1 .panel[aria-expanded=true] .panel-heading {
  background-color: #ff580c;
  border-color: #ff580c;
}
.faq-container .faq-group .panel-group.group1 .panel[aria-expanded=true] .panel-heading .panel-title {
  color: white;
}
.faq-container .faq-group .panel-group.group1 .panel .panel-body {
  line-height: 2.2rem;
}
.faq-container .faq-group .panel-group.group1 .panel-caret {
  position: absolute;
  top: 21px;
  right: 20px;
  transition: transform 0.7s ease;
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -60px -10px;
  width: 14px;
  height: 8px;
  transform: rotate(180deg);
}
.faq-container .faq-group .panel-group.group1 .panel-heading {
  border-radius: 0;
  background-clip: padding-box;
  background-color: #ececec;
}
.faq-container .faq-group .panel-group.group1 .panel-heading:hover {
  background-color: #e9e6e6;
}
.faq-container .faq-group .panel-group.group1 .panel-heading .panel-title {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  padding-right: 30px;
}
.faq-contact {
  padding-bottom: 40px;
}
.faq-contact .title-contact {
  margin-top: 20px;
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
  text-align: center;
}
.faq-contact .list-contact {
  margin: 40px auto 0;
}
.faq-contact .ss-title-contact {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
.faq-contact .tel-contact {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  margin: 20px auto 10px;
  font-weight: bold;
}
.faq-contact .block-contact {
  width: 250px;
  margin-bottom: 20px;
}
.faq-contact .txt-xxs {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #474747;
}
.faq-contact .txt-contact {
  margin: 20px auto 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  font-weight: bold;
}
.faq-contact .ss-txt-contact {
  line-height: 18px;
}
.faq-contact .icone-telephone {
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 31px;
  height: 31px;
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
}
.faq-contact .icone-chat {
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -35px 0;
  width: 38px;
  height: 33px;
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
}
.faq-contact .icone-mail {
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -75px 0;
  width: 37px;
  height: 25px;
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
}
.faq-contact .lien-contact {
  margin-top: 10px;
  color: #ff580c;
  display: inline-block;
}
.faq-contact .lien-contact:hover {
  text-decoration: underline;
}
.faq-contact .fleche-orange {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -50px -20px;
  width: 5px;
  height: 8px;
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}
.button.white2 {
  display: inline-block;
  margin-bottom: 20px;
}
.lexique-contact {
  padding: 20px 0 30px;
  text-align: center;
}
@media (max-width:767px) {
  .lexique-contact .row {
    margin: 0;
  }
}
.lexique-contact .title-lexique {
  display: inline-block;
  margin: 20px auto;
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
}
.lexique-contact .texte-lexique {
  display: block;
  margin-bottom: 30px;
  line-height: 18px;
}
@media (max-width:767px) {
  .lexique-contact .padd-community {
    padding-left: 0;
    padding-right: 0;
  }
}
#affiliation .object-row-container {
  margin-top: 30px;
}
#affiliation .object-row-container:nth-child(4n+1) > * {
  float: right;
}
#affiliation .object-row-container .image-cell {
  height: 290;
}
#affiliation .object-row-container .image-cell img {
  display: block;
  margin: 0 auto;
}
#affiliation .object-row-container .html-cell .positioner {
  height: 290;
  display: table-cell;
  vertical-align: middle;
}
#affiliation .object-row-container .html-cell .positioner .index {
  font-size: 1.8rem;
  line-height: 2.2rem;
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  background-color: #05d8c6;
  text-align: center;
  line-height: 33px;
  color: white;
  vertical-align: super;
  font-weight: bold;
  margin-right: 10px;
}
#affiliation .object-row-container .html-cell .positioner .title {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
#affiliation .object-row-container .html-cell .positioner ul {
  line-height: 30px;
}
#affiliation .object-row-container .html-cell .positioner ul li {
  background-image: url('/images/habitatpresto/fleche-noire.png');
  background-repeat: no-repeat;
  background-position: 0 10px;
  padding: 0 20px;
  margin-left: 13px;
  margin-bottom: 35px;
}
#affiliation .object-row-container .html-cell .positioner ul li ul li {
  background-image: url('/images/habitatpresto/check-vert.png');
  background-repeat: no-repeat;
  background-position: 0 10px;
  padding: 0 0 0 30px;
  margin-bottom: 35px;
}
#affiliation .entete {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  line-height: 30px;
}
#affiliation .entete strong {
  font-weight: bold;
}
#affiliation .title-page {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
}
#affiliation .mt-30 {
  margin-top: 30px;
}
#affiliation .mt-60 {
  margin-top: 60px !important;
}
#affiliation .mt-100 {
  margin-top: 100px;
}
#affiliation .mt-40 {
  margin-top: 40px;
}
#affiliation .mb-40 {
  margin-bottom: 40px;
}
#affiliation .no-padding {
  padding: 0;
}
#affiliation .puceVert {
  margin-right: 12px;
  border-radius: 50%;
  background-color: #05d8c6;
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: block;
}
#affiliation .content ul {
  margin-top: 20px;
}
#affiliation .content li {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  line-height: 30px;
}
#affiliation .content li strong {
  font-weight: bold;
}
#affiliation .content li a,
#affiliation .content li a:active,
#affiliation .content li a:visited {
  color: #474747;
}
#affiliation .content li a:hover,
#affiliation .content li a:active:hover,
#affiliation .content li a:visited:hover {
  text-decoration: underline;
  color: #474747;
}
@media (max-width:1199px) {
  #affiliation .photo-1 {
    width: 100%;
  }
}
@media (max-width:767px) and (max-width:991px) {
  #affiliation .photo-1 {
    margin-bottom: 20px !important;
    margin-left: 15px !important;
  }
}
#affiliation .photo-2 {
  margin-left: -150px !important;
}
@media (max-width:767px) and (max-width:991px) {
  #affiliation .photo-2 {
    margin-left: 0px !important;
    margin-bottom: 20px !important;
    width: 100%;
  }
}
#affiliation .photo-3 {
  margin-top: 60px !important;
}
@media (max-width:1199px) {
  #affiliation .photo-3 {
    width: 100%;
  }
}
@media (max-width:767px) and (max-width:991px) {
  #affiliation .photo-3 {
    margin-bottom: 60px !important;
    margin-left: 15px !important;
  }
}
#affiliation .button.orange1 {
  font-size: 1.8rem;
  line-height: 2.2rem;
}
#affiliation .bandeau-vert {
  width: 100%;
  background-color: #05d8c6;
}
#affiliation .puceBlanc {
  border-radius: 50%;
  background-color: #ffffff;
  height: 40px;
  width: 40px;
  display: block;
  line-height: 40px;
  margin-bottom: 15px;
  margin-top: 30px;
  color: #05d8c6;
  font-weight: bold;
  font-size: 18px;
}
#affiliation .steps2 {
  position: relative;
  line-height: 20px;
}
#affiliation .steps2-sprite {
  width: 222px;
  height: 175px;
  display: block;
  background: url("/images/habitatpresto/sprite-affiliation.jpg") no-repeat scroll;
}
#affiliation .steps2-formulaire {
  background-position: 0 -175px;
}
#affiliation .steps2-sav {
  background-position: 0 -345px;
}
#affiliation .steps2-remuneration {
  background-position: 0 -520px;
}
#affiliation .steps2-arrow,
#affiliation .steps2-arrow2,
#affiliation .steps2-arrow3 {
  position: absolute;
  display: block;
  background: url("/images/habitatpresto/steps2-arrow.jpg") no-repeat scroll;
  height: 66px;
  width: 66px;
  margin: 56px 0 0 -70px;
}
@media (max-width:767px) and (max-width:991px) {
  #affiliation .steps2-arrow,
  #affiliation .steps2-arrow2,
  #affiliation .steps2-arrow3 {
    background: none;
  }
}
#affiliation .steps2-arrow2 {
  margin-left: -50px;
}
#affiliation .steps2-arrow3 {
  margin-left: -30px;
}
#affiliation .steps2 strong {
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: white;
  font-weight: bold;
  margin-top: 15px;
}
#quisommesnous .object-row-container .object-cell {
  height: 280px;
}
@media (max-width:767px) {
  #quisommesnous .object-row-container .object-cell {
    background-image: url("/images/habitatpresto/ligne-history.jpg");
    background-repeat: repeat-y;
    background-position: left top;
  }
}
#quisommesnous .object-row-container .object-cell:last-child {
  display: table;
}
#quisommesnous .object-row-container .object-cell:last-child .date-container {
  display: table-cell;
  vertical-align: middle;
}
#quisommesnous .object-row-container .object-cell:last-child .date-container .padded-cell {
  text-align: left;
  padding-left: 85px;
}
#quisommesnous .object-row-container .object-cell:last-child .date-container .date-row {
  position: relative;
  text-align: left;
  padding-left: 85px;
}
#quisommesnous .object-row-container .object-cell:last-child .date-container .date-row .text {
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
}
#quisommesnous .object-row-container .object-cell:last-child .date-container .date-row .arrow {
  background-image: url("/images/habitatpresto/fleche-history-1.png");
  background-repeat: no-repeat;
  background-position: left center;
  width: 7px;
  height: 12px;
  position: absolute;
  left: 75px;
  top: 9px;
}
#quisommesnous .object-row-container .object-cell:last-child .date-container .date-row .marker {
  background-image: url("/images/habitatpresto/losange.png");
  background-repeat: no-repeat;
  background-position: left center;
  height: 18px;
  width: 18px;
  position: absolute;
  left: -24px;
  top: 6px;
}
@media (max-width:767px) {
  #quisommesnous .object-row-container .object-cell:last-child .date-container .date-row .marker {
    left: -21px;
  }
}
#quisommesnous .object-row-container .object-cell:last-child .date-container .description {
  text-align: left;
  padding-left: 85px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  display: block;
}
@media (min-width:768px) {
  #quisommesnous .object-row-container .object-cell-separator {
    margin: 0 -15px;
    width: 30px;
    height: 280px;
    float: left;
    background-image: url("/images/habitatpresto/ligne-history.jpg");
    background-repeat: repeat-y;
    background-position: center top;
  }
}
@media (min-width:768px) {
  #quisommesnous .object-row-container:nth-child(4n+1) .object-cell {
    float: right;
  }
  #quisommesnous .object-row-container:nth-child(4n+1) .object-cell:last-child .date-container .padded-cell {
    text-align: right;
    padding-right: 85px;
  }
  #quisommesnous .object-row-container:nth-child(4n+1) .object-cell:last-child .date-container .date-row {
    text-align: right;
    padding-right: 85px;
    padding-left: 0;
  }
  #quisommesnous .object-row-container:nth-child(4n+1) .object-cell:last-child .date-container .date-row .arrow {
    right: 75px;
    left: auto;
    background-position: right center;
    transform: rotate(180deg);
  }
  #quisommesnous .object-row-container:nth-child(4n+1) .object-cell:last-child .date-container .date-row .marker {
    right: -24px;
    left: auto;
    background-position: right center;
  }
  #quisommesnous .object-row-container:nth-child(4n+1) .object-cell:last-child .date-container .description {
    text-align: right;
    padding-right: 85px;
  }
  #quisommesnous .object-row-container:nth-child(4n+1) .object-cell-separator {
    float: right;
  }
}
@media (max-width:767px) {
  #quisommesnous .object-row-container .object-cell {
    height: 225px;
  }
  #quisommesnous .object-row-container .object-cell:last-child .date-container .padded-cell {
    padding-left: 35px;
  }
  #quisommesnous .object-row-container .object-cell:last-child .date-container .date-row {
    padding-left: 35px;
  }
  #quisommesnous .object-row-container .object-cell:last-child .date-container .date-row .arrow {
    left: 25px;
  }
  #quisommesnous .object-row-container .object-cell:last-child .date-container .description {
    padding-left: 35px;
  }
}
#quisommesnous .team {
  width: 100%;
}
#quisommesnous .team img {
  width: 100%;
  display: block;
}
#quisommesnous .entete {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  text-align: justify;
  line-height: 30px;
}
#quisommesnous .entete strong {
  font-weight: bold;
}
#quisommesnous .title-page {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
}
@media (max-width:767px) {
  #quisommesnous .row {
    margin-left: 0;
    margin-right: 0;
  }
}
#quisommesnous .mt-10 {
  margin-top: 10px;
}
#quisommesnous .mt-30 {
  margin-top: 30px;
}
#quisommesnous .mt-60 {
  margin-top: 60px !important;
}
#quisommesnous .mt-40 {
  margin-top: 40px;
}
#quisommesnous .mb-40 {
  margin-bottom: 40px;
}
#quisommesnous .mb-60 {
  margin-bottom: 60px;
}
#quisommesnous .nb-rea {
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: #474747;
  font-weight: bold;
}
#quisommesnous .txt-rea {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  word-wrap: break-word;
  -o-hyphens: auto;
  hyphens: auto;
}
@media (max-width:767px) {
  #quisommesnous .rea {
    margin-top: 20px;
  }
}
#quisommesnous a.link {
  color: #474747;
  text-decoration: none;
}
#quisommesnous a.link:hover {
  color: #474747;
  text-decoration: underline;
}
#quisommesnous .align-right {
  text-align: right;
}
#quisommesnous .button.white2 {
  display: inline-block;
}
#quisommesnous .txt-rea-row > div {
  margin-bottom: 10px;
}
#quisommesnous .icone-sofa {
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
  background-position: -297px 0;
  width: 52px;
  height: 51px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}
#quisommesnous .icone-horloge {
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
  background-position: -169px 0;
  width: 41px;
  height: 41px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}
#quisommesnous .icone-check {
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
  background-position: -252px 0;
  width: 41px;
  height: 41px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}
#quisommesnous .txt-rea-orange {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #ff580c;
  text-transform: uppercase;
  vertical-align: middle;
}
#quisommesnous .correction-align {
  margin-top: -4px;
}
#quisommesnous .title-history {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
}
#quisommesnous .debut-history {
  height: 49px;
  width: 49px;
  color: #05d8c6;
  font-size: 16px;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 15px;
  padding-left: 4px;
}
@media (min-width:768px) {
  #quisommesnous .debut-history {
    background-image: url("/images/habitatpresto/debut.png");
    color: white;
  }
}
@media (max-width:767px) {
  #quisommesnous .debut-history {
    margin-left: 40px;
  }
}
@media (max-width:767px) {
  #quisommesnous .start {
    background-image: url("/images/habitatpresto/debut.png");
    background-position: 8px center;
    background-repeat: no-repeat;
    background-size: 20px auto;
    margin-bottom: -18px;
  }
}
#quisommesnous .fin-history {
  background-image: url("/images/habitatpresto/fleche-history_1.png");
  background-repeat: no-repeat;
  height: 20px;
  width: 24px;
}
@media (max-width:767px) {
  #quisommesnous .fin-history {
    margin-left: -9px;
    margin-top: -1px;
  }
}
#quisommesnous .text-fin-history {
  color: #05d8c6;
  font-size: 16px;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 5px;
}
@media (max-width:767px) {
  #quisommesnous .text-fin-history {
    margin-top: -18px;
    text-align: left;
    margin-left: 40px;
  }
}
#quisommesnous .title-block {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
}
#quisommesnous .txt-contact {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
}
@media (max-width:767px) {
  #quisommesnous .padd {
    text-align: center;
  }
  #quisommesnous .padd:nth-child(odd) {
    margin-top: 7px;
  }
}
#quisommesnous .newsletter-about-us .newsletter-btn {
  padding: 10px 20px;
  color: #fff;
  background-color: #ff580c;
  border-radius: 5px;
  -webkit-user-select: none;
  user-select: none;
  font-weight: bold;
  transition: 0.3s;
}
#quisommesnous .newsletter-about-us .newsletter-btn:hover {
  color: #fff;
  background-color: #ee520a;
}
#quisommesnous .newsletter-about-us .newsletter-btn > i {
  margin-right: 5px;
  font-family: FontAwesome;
  font-style: normal;
}
#recrutement .team img {
  display: block;
  margin: 0 auto 2rem auto;
}
@media only screen and (max-width: 740px) {
  #recrutement .team img {
    width: 100%;
  }
}
#recrutement .title-page {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
}
#recrutement .mt-30 {
  margin-top: 30px;
}
#recrutement .mt-60 {
  margin-top: 60px !important;
}
#recrutement .mt-40 {
  margin-top: 40px;
}
#recrutement .txt-recrutement {
  text-align: justify;
  border: 1px solid #e4e4e4;
  border-radius: 1rem;
  padding: 20px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  line-height: 30px;
}
@media (max-width:767px) {
  #recrutement .txt-recrutement {
    border: none;
  }
}
#recrutement .button.orange1 {
  font-size: 18px;
  margin-top: 25px;
  border-radius: 1rem;
}
#login .object-row-container {
  margin-top: 30px;
}
#login .object-row-container:nth-child(4n+1) > * {
  float: right;
}
#login .object-row-container .image-cell {
  height: 290;
}
#login .object-row-container .image-cell img {
  display: block;
  margin: 0 auto;
}
#login .object-row-container .html-cell .positioner {
  height: 290;
  display: table-cell;
  vertical-align: middle;
}
#login .object-row-container .html-cell .positioner .index {
  font-size: 1.8rem;
  line-height: 2.2rem;
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  background-color: #05d8c6;
  text-align: center;
  line-height: 33px;
  color: white;
  vertical-align: super;
  font-weight: bold;
  margin-right: 10px;
}
#login .object-row-container .html-cell .positioner .title {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
#login .object-row-container .html-cell .positioner ul {
  line-height: 30px;
}
#login .object-row-container .html-cell .positioner ul li {
  background-image: url('/images/habitatpresto/fleche-noire.png');
  background-repeat: no-repeat;
  background-position: 0 10px;
  padding: 0 20px;
  margin-left: 13px;
  margin-bottom: 35px;
}
#login .object-row-container .html-cell .positioner ul li ul li {
  background-image: url('/images/habitatpresto/puceCheck.jpg');
  background-repeat: no-repeat;
  background-position: 0 10px;
  padding: 0 0 0 30px;
  margin-bottom: 35px;
}
#login .title-login {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  margin-right: -15px;
}
#login .background-2 {
  background-image: url("/images/habitatpresto/quisommesnous.jpg");
}
#login .background-3 {
  background-image: url("/images/habitatpresto/team.png");
}
#login .wrapper-img-1 {
  display: table;
  background-repeat: no-repeat;
  width: 100%;
  height: 475px;
}
#login .entete {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  line-height: 24px;
}
#login .title-page {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
}
#login .mt-10 {
  margin-top: 10px;
}
#login .mt-15 {
  margin-top: 15px;
}
#login .mt-20 {
  margin-top: 20px;
}
#login .mt-30 {
  margin-top: 30px;
}
#login .mt-60 {
  margin-top: 60px !important;
}
#login .mt-50 {
  margin-top: 50px;
}
#login .mt-70 {
  margin-top: 70px;
}
#login .mt-40 {
  margin-top: 40px;
}
#login .mt-100 {
  margin-top: 100px;
}
#login .ml-150 {
  margin-left: -150px !important;
}
#login .mb-40 {
  margin-bottom: 40px;
}
#login .mb-20 {
  margin-bottom: 20px;
}
#login .mb-30 {
  margin-bottom: 30px;
}
#login .no-padding {
  padding: 0;
}
#login .block-login {
  border: 1px solid #979797;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  padding: 40px 20px;
  margin-bottom: 20px;
}
#login .block-login p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  margin-top: 10px;
  margin-bottom: 20px;
}
#login .msg-error {
  background-color: #ffd4d4;
  padding: 10px;
  border: 1px solid #fdc8c8;
  color: #c33436;
  display: block;
  width: 100%;
  margin-top: 10px;
}
#login .sprite-mail {
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: 0 -100px;
  width: 28px;
  height: 19px;
  display: inline-block;
  margin-top: 12px;
  margin-left: 10px;
}
#login .sprite-password {
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -385px 0;
  width: 19px;
  height: 26px;
  display: inline-block;
  margin-top: 9px;
  margin-left: 13px;
  background-position: -60px -100px;
}
#login .iconLetter {
  width: 50px;
  height: 45px;
  position: absolute;
  background: #e6e6e6;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border: 1px solid #979797;
  left: 0;
}
#login .form-control {
  padding-left: 45px;
  height: 45px;
  border: 1px solid #979797;
}
#login .has-error .form-control {
  padding-left: 60px;
  height: 45px;
  border-color: #c33436;
}
#login .form-control-feedback {
  line-height: 26px;
}
#login .has-error .iconLetter {
  width: 50px;
  height: 45px;
  position: absolute;
  background: #ffd4d4 !important;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border: 1px solid #c33436 !important;
  left: 0;
  margin-top: 14px;
  margin-left: 0px;
}
@media (max-width:767px) {
  #login .has-error .iconLetter {
    margin-top: 13px;
  }
}
#login .button.orange1 {
  font-size: 18px;
}
#login .sprite-casque {
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
  background-position: -235px -65px;
  width: 21px;
  height: 19px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
#login a.mdp-forgot,
#login a.mdp-active:hover,
#login a.mdp-forgot:visited {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #474747;
}
#login a.mdp-forgot:hover {
  text-decoration: underline;
}
#login .modal-title {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
}
#login .txt-forgot-password {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
}
#login .modal-footer {
  text-align: justify;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #474747;
}
#login .float-r {
  float: right;
}
#login .mdp-forgot {
  float: right;
  margin-top: -10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #474747;
}
#login a.mdp-forgot,
#login a.mdp-forgot:active,
#login a.mdp-forgot:visited {
  color: #474747;
}
#login a.mdp-forgot:hover,
#login a.mdp-forgot:active:hover,
#login a.mdp-forgot:visited:hover {
  color: #474747;
  text-decoration: underline;
}
#inscription .object-row-container {
  margin-top: 30px;
}
#inscription .object-row-container:nth-child(4n+1) > * {
  float: right;
}
#inscription .object-row-container .image-cell {
  height: 290;
}
#inscription .object-row-container .image-cell img {
  display: block;
  margin: 0 auto;
}
#inscription .object-row-container .html-cell .positioner {
  height: 290;
  display: table-cell;
  vertical-align: middle;
}
#inscription .object-row-container .html-cell .positioner .index {
  font-size: 1.8rem;
  line-height: 2.2rem;
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  background-color: #05d8c6;
  text-align: center;
  line-height: 33px;
  color: white;
  vertical-align: super;
  font-weight: bold;
  margin-right: 10px;
}
#inscription .object-row-container .html-cell .positioner .title {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
#inscription .object-row-container .html-cell .positioner ul {
  line-height: 30px;
}
#inscription .object-row-container .html-cell .positioner ul li {
  background-image: url('/images/habitatpresto/fleche-noire.png');
  background-repeat: no-repeat;
  background-position: 0 10px;
  padding: 0 20px;
  margin-left: 13px;
  margin-bottom: 35px;
}
#inscription .object-row-container .html-cell .positioner ul li ul li {
  background-image: url('/images/habitatpresto/puceCheck.jpg');
  background-repeat: no-repeat;
  background-position: 0 10px;
  padding: 0 0 0 30px;
  margin-bottom: 35px;
}
#inscription .entete {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  line-height: 30px;
  text-align: center;
}
@media (max-width:767px) and (max-width:991px) {
  #inscription .entete {
    text-align: justify;
  }
}
#inscription .title-page {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
}
#inscription .mt-10 {
  margin-top: 10px;
}
#inscription .mt-15 {
  margin-top: 15px;
}
#inscription .mt-30 {
  margin-top: 30px;
}
#inscription .mt-60 {
  margin-top: 60px !important;
}
#inscription .mt-50 {
  margin-top: 50px;
}
#inscription .mt-40 {
  margin-top: 40px;
}
#inscription .mt-100 {
  margin-top: 100px;
}
#inscription .ml-150 {
  margin-left: -150px !important;
}
#inscription .mb-40 {
  margin-bottom: 40px;
}
#inscription .mb-20 {
  margin-bottom: 20px;
}
#inscription .mb-60 {
  margin-bottom: 60px;
}
#inscription .no-padding {
  padding: 0;
}
#inscription .block-login {
  border: 1px solid #cecece;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  padding: 40px 0;
}
#inscription .block-login p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  margin-top: 10px;
  margin-left: -15px;
}
#inscription .sprite-mail {
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: 0 -100px;
  width: 28px;
  height: 19px;
  display: inline-block;
  margin-top: 12px;
  margin-left: 10px;
}
#inscription .sprite-password {
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -385px 0;
  width: 19px;
  height: 26px;
  display: inline-block;
  margin-top: 9px;
  margin-left: 13px;
}
#inscription .iconLetter {
  width: 50px;
  height: 45px;
  position: absolute;
  background: #e6e6e6;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border: 1px solid #979797;
  left: 0;
  margin-top: 14px;
}
@media (max-width:767px) and (max-width:991px) {
  #inscription .iconLetter {
    margin-top: 13px;
  }
}
#inscription .form-control {
  height: 45px;
  border: 1px solid #979797;
}
#inscription .pl-60 {
  padding-left: 60px;
}
#inscription .title-step {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  margin-left: -15px;
}
#inscription .button.orange1 {
  font-size: 18px;
}
#inscription .float-r {
  float: right;
}
#inscription .nb-1 {
  background-image: url("/images/habitatpresto/inscription-nb.png");
  height: 40px;
  width: 40px;
  color: #FFF;
  font-size: 18px;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 11px;
  padding-left: 15px;
}
#inscription .nb-check {
  background-image: url("/images/habitatpresto/inscription-nb.png");
  height: 40px;
  width: 40px;
  color: #FFF;
  font-size: 18px;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 11px;
  padding-left: 10px;
}
#inscription .ligne-inscription {
  background-image: url("/images/habitatpresto/ligne-inscription.png");
  background-repeat: repeat-y;
  background-position: center top;
}
#inscription .ligne-height-300 {
  height: 300px;
}
#inscription .ligne-height-350 {
  height: 350px;
}
#inscription .ligne-height-200 {
  height: 200px;
}
#inscription .vert {
  color: #05d8c6;
}
#inscription .chp-obligatoire {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #474747;
  text-align: right;
  margin-right: -15px;
  margin-top: -5px;
}
#inscription .txt-formulaire {
  margin-left: -15px;
  margin-top: 15px;
  line-height: 18px;
}
#inscription a.case {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  border: 1px solid #979797;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  width: 100px;
  height: 100px;
  float: left;
  margin: 15px 0 25px 0;
  padding-top: 8px;
}
@media (max-width:767px) {
  #inscription a.case {
    width: 90px;
  }
}
#inscription a.case:hover {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  border: 1px solid #05d8c6;
  color: #05d8c6;
}
#inscription .sprite-homme {
  background-position: -145px -40px;
  width: 45px;
  height: 57px;
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
  display: inline-block;
  margin-top: 5px;
}
#inscription .sprite-femme {
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -50px -40px;
  width: 42px;
  height: 57px;
  display: inline-block;
  margin-top: 5px;
}
#inscription .case-active-homme {
  background-color: #05d8c6;
  border: 1px solid #979797;
  color: #FFF;
}
#inscription .case-active-homme .sprite-homme {
  background-position: -95px -40px;
  width: 45px;
  height: 57px;
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
  display: inline-block;
  margin-top: 5px;
}
#inscription .case-active-femme {
  background-color: #05d8c6;
  border: 1px solid #979797;
  color: #FFF;
}
#inscription .case-active-femme .sprite-femme {
  background-position: 0 -40px;
  width: 42px;
  height: 57px;
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
  display: inline-block;
  margin-top: 5px;
}
#inscription .marge {
  margin-right: 15px !important;
  margin-left: -15px !important;
}
@media (max-width:767px) {
  #inscription .marge {
    margin-right: 5px !important;
  }
}
#inscription .check {
  margin-top: 15px;
  margin-left: -15px;
}
#inscription .sprite-heart {
  background-position: -315px -100px;
  width: 26px;
  height: 25px;
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
#inscription .sprite-check {
  background-position: -373px -100px;
  width: 23px;
  height: 16px;
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
}
#inscription .txt-informatique-liberte {
  text-align: justify;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #474747;
  margin-top: 40px;
  margin-right: -15px;
  margin-left: 15px;
  line-height: 18px;
}
#inscription .txt-informatique-liberte strong {
  font-weight: bold;
}
#password .wrapper-img-1 {
  display: table;
  background-repeat: no-repeat;
  width: 100%;
  height: 475px;
}
#password .entete {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  line-height: 24px;
}
#password .title-page {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
}
#password .mt-40 {
  margin-top: 40px !important;
}
#password .mt-60 {
  margin-top: 60px !important;
}
#password .ml-150 {
  margin-left: -150px !important;
}
#password .block-login {
  border: 1px solid #979797;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  padding: 40px 20px;
  margin: 40px 0;
}
#password .block-login .title-login {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  margin-right: -15px;
  text-align: center;
  margin-bottom: 20px;
}
#password .block-login .title-login p {
  text-align: left;
  margin-top: 20px;
}
#password .block-login .title-login ul {
  font-weight: bold;
  margin-left: 35%;
  text-align: left;
}
#password .block-login .title-login ul li {
  font-size: 1.6rem;
  font-family: "Open Sans", Sans-Serif;
  line-height: 2.2rem;
  color: #474747;
}
#password .block-login .reset-password-text {
  font-size: 1em;
  text-align: center;
}
#password .block-login .mail-adress {
  color: #27a3b2;
  font-weight: bold;
}
#password .block-login p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  margin-top: 10px;
  margin-bottom: 20px;
}
#password .block-login button {
  margin-top: 80px;
}
@media (max-width:1199px) {
  #password .block-login button {
    margin-top: 55px;
  }
}
#password .block-login .sprite-mail {
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: 0 -100px;
  width: 28px;
  height: 19px;
  display: inline-block;
  margin-top: 12px;
  margin-left: 10px;
}
#password .block-login .sprite-password {
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -385px 0;
  width: 19px;
  height: 26px;
  display: inline-block;
  margin-top: 9px;
  margin-left: 13px;
  width: 22px;
  background-position: -58px -100px;
}
#password .block-login .sprite-password.has-error {
  background-position: -407px 0;
}
#password .block-login .iconLetter {
  width: 50px;
  height: 45px;
  position: absolute;
  background: #e6e6e6;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border: 1px solid #979797;
  left: 0;
}
#password .block-login .iconLetter.has-error {
  border: 1px solid #c03634;
  background-color: #fed3d5;
  margin-top: 0;
  margin-left: 0;
}
#password .block-login .form-control {
  padding-left: 60px;
  height: 45px;
  border: 1px solid #979797;
}
#password .block-login .form-control-feedback {
  line-height: 26px;
}
#password .block-login .button.orange1 {
  font-size: 18px;
  margin-top: 30px;
}
#password .block-login .button-width {
  width: 260px;
}
#password .float-r {
  float: right;
}
#confirmation {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.44);
}
#confirmation .wrapper-bg-gris {
  background-color: #f0f0f0;
  border: 1px solid #e8e5e5;
}
#confirmation h2 {
  margin: 30px 0;
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  text-align: center;
  font-weight: bold;
}
#confirmation p {
  font-size: 1.8rem;
}
#confirmation .quote-number span {
  font-weight: 700;
  color: #05d8c6;
  font-size: 2.4rem;
}
#confirmation .index {
  font-size: 1.8rem;
  line-height: 2.2rem;
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  background-color: #05d8c6;
  text-align: center;
  line-height: 33px;
  color: white;
  vertical-align: super;
  font-weight: bold;
  margin-right: 10px;
}
#confirmation .button {
  margin: 30px auto;
  text-align: center;
  font-size: 18px;
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
  padding: 10px 30px;
}
#confirmation .button:hover {
  color: white;
  border: 1px solid #f64e01;
  background-color: #f64e01;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
@media (max-width:767px) {
  #confirmation .button {
    margin: 30px auto 20px auto;
  }
}
#confirmation .ss-title-confirmation {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  line-height: 2.2rem;
}
#confirmation .ss-title-confirmation.orange {
  color: #ff580c;
}
#confirmation .ss-title-confirmation.bold span {
  display: block;
  font-weight: normal;
}
#confirmation .title-page {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
}
#confirmation .txt-confirm {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  line-height: 30px;
}
#confirmation .txt-confirm strong {
  font-weight: bold;
}
#confirmation .position-txt1 {
  margin-top: 50px;
}
@media (max-width:767px) {
  #confirmation .position-txt1 {
    margin-top: 0;
  }
}
@media (max-width:767px) {
  #confirmation .position-img1 {
    margin-top: 20px;
  }
}
#confirmation .position-txt2 {
  margin-top: 50px;
}
@media (max-width:767px) {
  #confirmation .position-txt2 {
    margin-top: -470px;
  }
}
@media (max-width:767px) {
  #confirmation .position-img2 {
    margin-top: 240px;
  }
}
#confirmation .position-txt3 {
  margin-top: 50px;
}
@media (max-width:767px) {
  #confirmation .position-txt3 {
    margin-top: 340px;
  }
}
@media (max-width:767px) {
  #confirmation .position-img3 {
    margin-top: 20px;
  }
}
#confirmation .position-txt4 {
  margin-top: 50px;
}
@media (max-width:767px) {
  #confirmation .position-txt4 {
    margin-top: -550px;
  }
}
@media (max-width:767px) {
  #confirmation .position-img4 {
    margin-top: 330px;
  }
}
#confirmation .mt-15 {
  margin-top: 15px;
}
#confirmation .mt-20 {
  margin-top: 20px;
}
#confirmation .mt-30 {
  margin-top: 30px;
}
#confirmation .mt-60 {
  margin-top: 60px;
}
#confirmation .mt-50 {
  margin-top: 50px;
}
#confirmation .mb-20 {
  margin-bottom: 20px;
}
#confirmation .mb-30 {
  margin-bottom: 30px;
}
#confirmation .mb-50 {
  margin-bottom: 50px;
}
#confirmation .mb-60 {
  margin-bottom: 60px;
}
#confirmation .no-padding {
  padding: 0;
}
@media (max-width:767px) {
  #confirmation .mb-30-xs {
    margin-bottom: 30px;
  }
}
#confirmation .ss-title-espace {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
}
#confirmation .block-espace-perso ul {
  margin-top: 120px;
  margin-bottom: 60px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
}
@media (max-width:767px) {
  #confirmation .block-espace-perso ul {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
#confirmation .block-espace-perso ul li {
  line-height: 36px;
}
#confirmation .block-espace-perso ul li .sprite-check {
  background-position: -120px -170px;
  width: 18px;
  height: 15px;
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 15px;
}
#confirmation .block-espace-perso ul li .services {
  margin-left: 33px;
}
#confirmation .block-espace-perso ul li strong {
  font-weight: bold;
}
#confirmation .block-espace-perso img {
  width: auto;
}
@media (max-width:767px) and (max-width:991px) and (max-width:1199px) {
  #confirmation .block-espace-perso img {
    width: 100%;
  }
}
#confirmation .block-espace-perso .img-espace-part-1 {
  margin-top: 60px;
  margin-bottom: 60px;
}
@media (max-width:991px) {
  #confirmation .block-espace-perso .img-espace-part-1 {
    margin-left: -110px;
  }
}
@media (max-width:767px) {
  #confirmation .block-espace-perso .img-espace-part-1 {
    margin-left: 0;
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
#confirmation .block-espace-perso .img-espace-part-2 {
  margin-top: 60px;
  margin-bottom: 60px;
}
@media (max-width:991px) {
  #confirmation .block-espace-perso .img-espace-part-2 {
    margin-left: -60px;
  }
}
@media (max-width:767px) {
  #confirmation .block-espace-perso .img-espace-part-2 {
    margin-left: 0;
    margin-top: 40px;
    margin-bottom: 20px;
  }
}
#confirmation a.orange,
#confirmation a.orange:hover,
#confirmation a.orange:active,
#confirmation a.orange:visited {
  color: #ff580c;
}
#confirmation .title-page-thin {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
}
#confirmation .form-control {
  padding-left: 60px;
  height: 40px;
  border: 1px solid #979797;
}
#confirmation .bg-laptop-1 {
  background: url("/images/habitatpresto/confirmation-rond-1.png");
  background-repeat: no-repeat;
  background-position: center right;
}
#confirmation .bg-laptop-2 {
  background: url("/images/habitatpresto/confirmation-rond-2.png");
  background-repeat: no-repeat;
  background-position: bottom left;
}
@media (max-width:767px) {
  #confirmation .padd-community {
    padding-left: 0;
    padding-right: 0;
  }
}
#annuaire .ss-title {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
#annuaire a .list-link,
#annuaire a:active .list-link,
#annuaire a:visited .list-link {
  line-height: 24px;
  margin-top: 10px;
  font-size: 1.4rem;
  display: inline;
  color: #474747;
  text-decoration: none;
}
#annuaire a .list-link:hover,
#annuaire a:active .list-link:hover,
#annuaire a:visited .list-link:hover {
  color: #474747;
  text-decoration: underline;
}
#annuaire .title-article {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  margin-top: 20px;
}
#annuaire .fa-2x {
  font-size: 1.2em;
  margin: 10px 3px 0;
}
#annuaire .select2-results .select2-no-results {
  font-size: 12px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background: #d9edf7 !important;
  color: #3a87ad !important;
}
.financementtravaux-1.offer {
  background-color: #f0f0f0;
}
.financementtravaux-1.offer .offer-img {
  width: 153px;
  height: 153px;
  display: block;
  margin: 0 auto;
}
.financementtravaux-1.offer ul {
  width: 340px;
  text-align: left;
}
@media (max-width:767px) {
  .financementtravaux-1.offer ul {
    width: 280px;
  }
}
.financementtravaux-1.offer ul li {
  background-image: url('/images/habitatpresto/orange-check.png');
  background-repeat: no-repeat;
  background-position: 0;
  padding-left: 30px;
  margin-top: 10px;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  text-transform: uppercase;
}
.financementtravaux-1.offer .conditions {
  border: 1px solid #ff580c;
  padding: 15px;
  line-height: 20px;
  text-align: justify;
}
.financementtravaux-1.offer .conditions .ft-bold {
  font-size: 16px;
  font-weight: bold;
}
.financementtravaux-2 {
  line-height: 20px;
}
.financementtravaux-2 h1,
.financementtravaux-2 h2 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  text-align: center;
  margin: 30px auto;
}
.financementtravaux-2 .button-orange {
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
  font-size: 18px;
  margin: 30px auto;
}
.financementtravaux-2 .button-orange:hover {
  color: white;
  border: 1px solid #f64e01;
  background-color: #f64e01;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
.financementtravaux-2 .button-orange a {
  text-decoration: none;
  color: white;
}
.financementtravaux-2 ul.check {
  text-align: left;
}
.financementtravaux-2 ul.check li {
  background-image: url('/images/habitatpresto/orange-check.png');
  background-repeat: no-repeat;
  background-position: 0;
  padding: 0 30px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
}
.financementtravaux-2 .object-grid-container .object-row-container:nth-child(4n+1) > * {
  float: right;
}
.financementtravaux-2 .object-grid-container .object-row-container .object-cell {
  display: table;
}
.financementtravaux-2 .object-grid-container .object-row-container .object-cell .image-cell {
  height: 290;
}
.financementtravaux-2 .object-grid-container .object-row-container .object-cell .image-cell img {
  display: block;
  margin: 0 auto;
}
.financementtravaux-2 .object-grid-container .object-row-container .object-cell .indexed-cell {
  height: 290;
  display: table-cell;
  vertical-align: middle;
}
@media (max-width:767px) and (max-width:991px) {
  .financementtravaux-2 .object-grid-container .object-row-container .object-cell .indexed-cell {
    height: 100%;
    padding: 20px;
  }
}
.financementtravaux-2 .object-grid-container .object-row-container .object-cell .indexed-cell .positioner {
  position: relative;
}
.financementtravaux-2 .object-grid-container .object-row-container .object-cell .indexed-cell .positioner .index {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #05d8c6;
  text-align: center;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: white;
  line-height: 35px;
  position: absolute;
  font-weight: bold;
}
.financementtravaux-2 .object-grid-container .object-row-container .object-cell .indexed-cell .positioner .text {
  margin-left: 40px;
}
.financementtravaux-2 .object-grid-container .object-row-container .object-cell .indexed-cell .positioner .text ul {
  line-height: 30px;
}
.financementtravaux-2 .object-grid-container .object-row-container .object-cell .indexed-cell .positioner .text ul li {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  margin-left: 13px;
  line-height: 30px;
  margin-top: 15px;
}
.financementtravaux-2 .object-grid-container .object-row-container .object-cell .indexed-cell .positioner .text ul li strong {
  font-weight: bold;
}
.financementtravaux-3.benefits {
  background-color: #f0f0f0;
  line-height: 20px;
}
.financementtravaux-3.benefits h1 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  text-align: center;
  margin: 15px auto;
  text-transform: uppercase;
}
.financementtravaux-3.benefits a {
  color: #474747;
}
.financementtravaux-3.benefits a .icone {
  width: 54px;
  height: 54px;
  margin: 0 auto 10px auto;
  display: block;
}
.financementtravaux-3.benefits .legal {
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  line-height: 1.5rem;
  text-align: justify;
}
#add-suspect .form-add-suspect {
  border: 1px solid #cecece;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  padding: 20px 40px;
  width: 50%;
  margin: auto;
}
#add-suspect .form-add-suspect .title {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  margin-bottom: 10px;
}
#add-suspect .form-add-suspect input,
#add-suspect .form-add-suspect select {
  margin-bottom: 10px;
}
#newsletter-consumer .sub-title {
  font-size: 2rem;
}
#newsletter-consumer .box-item-shadow {
  border-radius: 1rem;
  box-shadow: 1px 1px 5px #d9d9d9;
  cursor: pointer;
}
#newsletter-consumer .box-item-shadow img {
  border-radius: 1rem 0 0 1rem;
}
#newsletter-consumer .box-item-shadow > .col-sm-4 {
  padding-left: 0;
}
#newsletter-consumer .box-item-shadow > .col-sm-8 {
  padding: 1rem;
}
#newsletter-consumer .box-item-shadow .travaux-title .checkbox > label {
  padding-left: 4rem;
  font-weight: bold;
  font-size: 2rem;
  color: #d35603;
}
#newsletter-consumer .box-item-shadow .travaux-title .checkbox > label > input {
  position: absolute;
  left: 2.6rem;
  transform: scale(1.6);
}
#newsletter-consumer .box-item-shadow .decoration-title .checkbox > label {
  padding-left: 4rem;
  font-weight: bold;
  font-size: 2rem;
  color: #2880b9;
}
#newsletter-consumer .box-item-shadow .decoration-title .checkbox > label > input {
  position: absolute;
  left: 2.6rem;
  transform: scale(1.6);
}
#newsletter-consumer .box-item-shadow .jardin-title .checkbox > label {
  padding-left: 4rem;
  font-weight: bold;
  font-size: 2rem;
  color: #27ae60;
}
#newsletter-consumer .box-item-shadow .jardin-title .checkbox > label > input {
  position: absolute;
  left: 2.6rem;
  transform: scale(1.6);
}
#newsletter-consumer .box-item-shadow p {
  font-weight: initial;
}
#newsletter-consumer .asterix {
  font-size: 1rem;
}
#newsletter-consumer .newsletter-div {
  margin-top: 5rem;
}
#newsletter-consumer .newsletter-div .email-submit {
  display: flex;
  justify-content: center;
}
#newsletter-consumer .newsletter-div .email-submit .email {
  position: relative;
}
#newsletter-consumer .newsletter-div .email-submit .email .fleche-courbe {
  width: 100px;
  height: 100px;
  transform: rotate(90deg);
  position: absolute;
  left: -100px;
  top: -42px;
}
#newsletter-consumer .newsletter-div .email-submit .email .email-input {
  width: 400px;
  margin: 1rem;
  padding: 2.2rem;
  font-size: 1.8rem;
  border-color: #ffd4c1;
  border-radius: 1rem;
}
#newsletter-consumer .newsletter-div .email-submit .email .email-input:focus {
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.08), 0 0 8px rgba(249, 200, 176, 0.6);
}
#newsletter-consumer .newsletter-div .email-submit .email .email-input::placeholder {
  color: #ffa57b;
}
#newsletter-consumer .newsletter-div .email-submit .submit .submit-btn {
  margin: 1rem;
  padding: 1.4rem 2.8rem;
  border: none;
  border-radius: 1rem;
  background-color: #fc580c;
  color: white;
  font-weight: bold;
  font-size: 1.8rem;
}
#newsletter-consumer #accordion-div #accordion .panel-default {
  border-radius: 1rem;
}
#newsletter-consumer #accordion-div #accordion .panel-default .panel-heading {
  padding: 0;
  background-color: transparent;
}
#newsletter-consumer #accordion-div #accordion .panel-default .panel-heading .panel-title > a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.8rem 2.6rem;
}
#newsletter-consumer #accordion-div #accordion .panel-default .panel-body {
  line-height: 2rem;
}
#newsletter-consumer #accordion-div #accordion .panel-default .panel-body > a {
  color: #e44800;
}
@media only screen and (max-width: 768px) {
  #newsletter-consumer .box-item-shadow {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  #newsletter-consumer .box-item-shadow img {
    border-radius: 1rem 1rem 0 0;
    max-height: 200px;
    width: 100%;
  }
  #newsletter-consumer .box-item-shadow > .col-sm-4 {
    padding: 0;
  }
  #newsletter-consumer .box-item-shadow > .col-sm-8 {
    padding: 2rem;
  }
  #newsletter-consumer .newsletter-div {
    margin-top: 5rem;
  }
  #newsletter-consumer .newsletter-div .email-submit {
    flex-direction: column;
    margin: 0 auto;
  }
  #newsletter-consumer .newsletter-div .email-submit .email {
    display: flex;
    justify-content: center;
  }
  #newsletter-consumer .newsletter-div .email-submit .email .fleche-courbe {
    display: none;
  }
}
#annuaire #form-annuaire h1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
#annuaire #form-annuaire h1 img {
  height: 40px;
  margin-right: 15px;
}
#annuaire .row-flex {
  display: flex;
  flex-wrap: wrap;
}
#annuaire .directory-pro-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#annuaire .card-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#annuaire .tag-list {
  margin-top: 20px;
}
#annuaire .tag {
  display: inline-block;
  background-color: #f5f5f5;
  padding: 10px 15px;
  margin: 5px;
  border-radius: 25px;
  font-size: 1.7rem;
  color: #333;
  text-align: center;
  min-width: 120px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}
#annuaire .tag:hover {
  background-color: #00d1b2;
  color: #fff;
}
#annuaire .tag:hover img {
  filter: invert(1) sepia(0) saturate(0);
}
#annuaire .tag img {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(330deg);
  width: 20px;
  height: 20px;
  margin-right: 10px;
  vertical-align: middle;
}
#annuaire .circle {
  background-color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}
#annuaire .testimonies-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
#annuaire #faq {
  border-radius: 10px;
}
#annuaire #faq a[aria-expanded=true] .fa-chevron-right {
  display: none;
}
#annuaire #faq a[aria-expanded=false] .fa-chevron-down {
  display: none;
}
#annuaire #faq ul {
  list-style-type: disc !important;
  padding-left: 1em;
}
#annuaire #faq .panel-title {
  font-size: 1.7rem;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}
#annuaire #faq .panel-body {
  font-size: 1.6rem;
  line-height: normal;
}
#annuaire #faq .panel-body ul {
  margin-top: 10px;
  margin-bottom: 5px;
}
#annuaire #faq .panel-body li {
  margin-bottom: 5px;
}
#annuaire #faq .panel-body p {
  margin-bottom: 10px;
}
#annuaire #faq .panel-default {
  background-color: inherit;
  border: none;
}
#annuaire #faq .panel-heading {
  background-color: inherit;
}
#annuaire .bt-white {
  color: black;
  border: 1px solid white;
  background-color: white;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
  font-size: 18px;
}
#annuaire .bt-white:hover {
  color: #f64e01;
  background-color: #f0f0f0;
}
#annuaire .directory-no-results-img {
  max-width: 300px;
}
#annuaire .card-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
#annuaire .directory-pro-card {
  border-radius: 10px;
  padding: 15px;
  background-color: #f0f0f0;
  position: relative;
}
#annuaire .directory-pro-card .company-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}
#annuaire .directory-pro-card .title {
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
}
#annuaire .directory-pro-card .location {
  color: #757575;
  font-size: 14px;
}
#annuaire .directory-pro-card .pro-directory-tag {
  background-color: white;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 5px 10px;
  display: table;
  margin-top: 10px;
  font-size: 14px;
  color: grey;
}
#annuaire .directory-pro-card .badge-container {
  text-align: end;
  display: flex;
  flex-direction: column;
}
#annuaire .directory-pro-card .badge-container .badge-pro {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}
#annuaire .directory-pro-card .badge-container .badge-rge {
  width: 60px;
  height: 41px;
}
#annuaire .directory-pro-card .view-profile {
  background-color: #ff5722;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  display: block;
  margin-top: 20px;
}
#annuaire .directory-pro-card .profile-card {
  display: flex;
  align-items: center;
}
#annuaire .directory-pro-card .profile-card .image-container {
  flex-shrink: 0;
  margin-right: 15px;
}
#annuaire .directory-pro-card .profile-card img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
#annuaire .directory-pro-card .profile-card .text-container {
  display: flex;
  flex-direction: column;
}
#annuaire .directory-pro-card .profile-card .text-container .title {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}
#annuaire .directory-pro-card .profile-card .text-container .rating {
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-size: 14px;
}
#annuaire .directory-pro-card .profile-card .text-container .rating small {
  color: #727272;
  margin-left: 5px;
}
#annuaire .directory-pro-card .profile-card .text-container .location {
  color: #757575;
  font-size: 14px;
  margin-top: 5px;
}
#annuaire .directory-pro-card .profile-card .star-icon {
  color: #04d8c6;
  font-size: 14px;
  margin-right: 3px;
}
#annuaire #seo-text {
  border-radius: 10px;
  padding-left: 30px;
  padding-right: 30px;
}
#annuaire #seo-text h2 {
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 3.6rem;
  line-height: 3.6rem;
  text-transform: uppercase;
  color: #474747;
  text-align: center;
}
#annuaire #seo-text h3 {
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 15px;
}
#annuaire #seo-text h4 {
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
}
#annuaire #seo-text p {
  font-size: 1.6rem;
  line-height: normal;
  margin-bottom: 10px;
}
#annuaire #seo-text li {
  font-size: 1.6rem;
  line-height: normal;
  margin-bottom: 5px;
}
#annuaire .directory-breadcrumb,
#annuaire-fiche-pro .directory-breadcrumb {
  background-color: #fff;
  text-transform: uppercase;
  line-height: 2rem;
  margin-bottom: 20px;
}
#annuaire .directory-breadcrumb a,
#annuaire-fiche-pro .directory-breadcrumb a,
#annuaire .directory-breadcrumb a:active,
#annuaire-fiche-pro .directory-breadcrumb a:active,
#annuaire .directory-breadcrumb a:visited,
#annuaire-fiche-pro .directory-breadcrumb a:visited {
  color: #474747;
  text-decoration: none;
}
#annuaire .directory-breadcrumb a:hover,
#annuaire-fiche-pro .directory-breadcrumb a:hover,
#annuaire .directory-breadcrumb a:active:hover,
#annuaire-fiche-pro .directory-breadcrumb a:active:hover,
#annuaire .directory-breadcrumb a:visited:hover,
#annuaire-fiche-pro .directory-breadcrumb a:visited:hover {
  text-decoration: underline;
}
#annuaire .directory-breadcrumb .arrow-grey,
#annuaire-fiche-pro .directory-breadcrumb .arrow-grey {
  display: inline-block;
  background-position: -50px 0;
  width: 6px;
  height: 9px;
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
}
.home-banner {
  background: linear-gradient(180deg, #ff9b6d 50%, #ff793d 100%);
  padding: 40px 0;
}
.home-banner .banner-col img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-banner .banner-col h1 {
  font-size: 60px;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
}
@media (max-width:767px) {
  .home-banner .banner-col h1 {
    font-size: 40px;
  }
}
.home-banner .banner-col img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.home-banner .banner-col .emphasis {
  color: #27a3b2;
}
.search-bar {
  padding: 40px 0;
  margin: 0 auto;
  max-width: 90%;
}
.search-bar .search-container {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}
.search-bar input {
  width: 100%;
  height: 60px;
  padding: 0 30px;
  font-size: 18px;
  border: 5px solid #ff580c;
  border-radius: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background: url('/build/images/theme/lisboa/consumer/search-icon.png') no-repeat right 15px center / 20px 20px;
  background-color: white;
}
.search-bar input:focus {
  outline: none;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
}
.search-bar input::placeholder {
  color: #999;
}
.search-bar .search-results {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-height: 400px;
  overflow-y: auto;
  z-index: 100;
}
.search-bar .search-results .search-result {
  padding: 20px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}
.search-bar .search-results .search-result:hover {
  background: #f0f0f0;
}
.search-bar .search-results .search-result:last-child {
  border-bottom: none;
}
.search-bar .search-results .search-result u {
  color: #ff793d;
  -webkit-text-decoration-color: #ff793d;
  text-decoration-color: #ff793d;
}
.search-bar .search-results .search-result .search-result-name {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}
.search-bar .search-results .search-result .search-result-label {
  color: #666;
  font-size: 14px;
  line-height: 1.6;
}
.search-bar .search-results .search-result-empty {
  padding: 20px;
  text-align: center;
  color: #666;
  font-size: 18px;
  line-height: 1.6;
}
.search-bar .search-results .search-result-empty strong {
  font-weight: bold;
  color: #ff793d;
}
.search-bar .search-results .search-result-empty p {
  margin: 0;
}
.image-wrapper.ddd-form-header h1,
.image-wrapper.ddd-form-header h2 {
  text-shadow: black 1px 0 10px;
}
.image-wrapper {
  display: table;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.image-wrapper.size-2 {
  height: 150px;
}
.image-wrapper .wrapped-content {
  display: table-cell;
  vertical-align: middle;
}
.image-wrapper .wrapped-content h1 {
  font-size: 4.8rem;
  line-height: 4.8rem;
  text-align: center;
}
@media (max-width:767px) {
  .image-wrapper .wrapped-content h1 {
    margin-top: 6rem;
  }
}
.pagination {
  margin-top: 20px;
}
.pagination li.active span,
.pagination li.active a {
  color: #ff580c !important;
  border: none;
  background-color: #fff !important;
}
.pagination li.active:hover span,
.pagination li.active:hover a {
  color: #ff580c;
  border: none;
  background-color: #fff;
}
.pagination li span,
.pagination li a {
  color: #000;
  border: none;
  background-color: #fff;
}
.pagination li span:hover,
.pagination li a:hover,
.pagination li span:focus,
.pagination li a:focus {
  color: #ff580c;
  border: none;
  background-color: #fff;
}
.wrapper-404 {
  background: url(/images/habitatpresto/404-1.jpg) no-repeat center;
  /* pour anciens Chrome et Safari */
  background-size: cover;
  /* version standardisée */
}
.wrapper-404 .text-404 {
  margin-top: 150px;
  margin-bottom: 50px;
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: white;
}
.wrapper-404 .mb-150 {
  margin-bottom: 150px;
}
.wrapper-404 a.bt-orange {
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
  font-size: 18px;
}
.wrapper-404 a.bt-orange:hover {
  background-color: #f64e01;
}
.form-category-header {
  padding: 20px 0;
}
.form-category-header .category-text {
  text-align: justify;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
}
.form-category-header .info-row {
  display: table;
  margin-top: 15px;
}
.form-category-header .info-row .info-text {
  display: table-cell;
  vertical-align: middle;
  padding-left: 10px;
}
.form-category-header .info-row .info-text .normal {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  font-size: 3.6rem;
}
.form-category-header .info-row .info-text .normal strong {
  font-weight: bold;
}
.form-category-header .info-row .info-text .ss-txt {
  margin-top: 5px;
}
.form-stats-header .text h2 {
  text-align: center;
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: #474747;
  font-weight: bold;
  margin-top: 10px;
  text-transform: none;
}
.form-ddd {
  background: linear-gradient(180deg, #ff966a 50%, #FCA37A 100%);
}
.form-ddd .container {
  padding-bottom: 20px;
  display: flex;
}
@media (max-width:991px) {
  .form-ddd .container {
    flex-direction: column;
  }
}
.form-ddd .form-heading {
  color: white;
  width: 50%;
  margin-top: 5rem;
}
@media (max-width:991px) {
  .form-ddd .form-heading {
    width: 100%;
  }
}
.form-ddd .form-heading .form-heading-pretitle {
  font-size: 24px;
  font-weight: bold;
}
.form-ddd .form-heading .form-heading-title {
  font-size: 50px;
  font-weight: bold;
  margin: 20px 0;
}
@media (max-width:991px) {
  .form-ddd .form-heading .form-heading-title {
    font-size: 3.6rem;
  }
}
.form-ddd .form-heading .form-heading-subtitle {
  font-weight: bold;
  font-size: 34px;
  line-height: 37px;
  margin: 20px 0;
}
@media (max-width:767px) {
  .form-ddd .form-heading .form-heading-subtitle {
    margin: 0;
  }
}
.form-ddd .form-heading .form-check-list {
  list-style: disc;
  font-weight: 400;
  background-color: white;
  color: black;
  border-radius: 10px;
  padding: 10px 30px;
  margin: 30px 0;
  display: inline-block;
  line-height: 36px;
  font-size: 20px;
}
.form-ddd .form-heading .form-heading-image {
  width: 100%;
  max-width: 540px;
  border-radius: 10px;
}
.form-ddd .form {
  width: 50%;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
}
@media (max-width:991px) {
  .form-ddd .form {
    width: 100%;
  }
}
.form-ddd .form .form-group.has-error .form-control-feedback {
  top: 22px;
}
.form-ddd .form .control-label {
  font-weight: normal;
  padding-bottom: 8px;
}
.form-ddd .form .control-label .form-control {
  height: 44px;
}
.form-ddd .form textarea.form-control {
  height: 100px !important;
}
.form-ddd .form .footer,
.form-ddd .form .info {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.4rem;
  color: #474747;
}
.form-ddd .form .footer {
  margin: 10px 0 20px;
  line-height: 14px;
}
.form-ddd .form .footer .fa {
  font-size: 16px;
  position: relative;
  top: 10px;
}
.form-ddd .form .footer .text {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-left: 20px;
}
.form-ddd .form .footer .cgu-txt input {
  margin: 5px;
}
.form-ddd .form .footer .cgu-txt.text {
  margin-left: 0;
}
.form-ddd .form .info {
  margin-bottom: 20px;
  line-height: 12px;
}
.form-ddd .form .directive {
  margin: 10px 0;
}
@media (max-width:767px) {
  .form-ddd .form .directive {
    text-align: center;
  }
}
.form-ddd .form .directive .number {
  background-color: #ff804b;
  color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 100px;
  line-height: 2;
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  display: inline-block;
  margin-left: 10px;
}
.form-ddd .form .directive .text-nb {
  font-weight: bold;
  font-size: 25px;
  margin-left: 10px;
  color: #ff804b;
  vertical-align: middle;
}
.form-ddd .form .bt-demande-form {
  background-color: #ff804b;
  border: none;
  border-radius: 25px;
  padding: 15px 15px 15px 45px;
  font-weight: bold;
  color: #fff;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position-x: 15px;
  background-position-y: center;
  display: block;
  margin: 0 auto;
}
.form-ddd .form .fa.blue {
  color: #19556a;
}
.form-ddd .form .fa.green {
  color: #05d8c6;
}
.form-ddd .form .fa.green.coordinates {
  font-size: 18px;
  display: inline-block;
  position: relative;
  left: 15px;
  top: 35px;
}
@media (max-width:991px) {
  .form-ddd .form .fa.green.coordinates {
    font-size: 16px;
    left: 10px;
  }
}
.form-ddd .form .popover {
  width: 500px;
  height: 90px;
  font-size: 12px;
  background-color: #d9edf7;
  color: #3a87ad;
  border: 1px solid #bce8f1;
  font-family: 'Open Sans', sans-serif;
}
.form-ddd .form .popover.top > .arrow {
  border-top-color: #bce8f1;
}
.form-ddd .form .popover.top > .arrow:after {
  border-top-color: #d9edf7;
}
#image-wrapper-ddd {
  display: table;
  background: url('/images/habitatpresto/header-hp.jpg') no-repeat center center;
  background-size: cover;
  width: 100%;
}
#image-wrapper-ddd .size-3 {
  height: 800px;
}
#image-wrapper-ddd .wrapped-content {
  display: table-cell;
  vertical-align: middle;
}
#image-wrapper-ddd h1 {
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: white;
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
}
#image-wrapper-ddd h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  color: white;
  text-align: center;
}
#image-wrapper-ddd .picto {
  margin: -73px auto 40px auto;
}
#image-wrapper-ddd .picto .artisan {
  background-image: url('/images/habitatpresto/picto-artisan.png');
  width: 119px;
  height: 103px;
  display: block;
  margin: auto;
}
#image-wrapper-ddd .border-form-ddd {
  margin-top: 100px;
  border: 15px solid rgba(8, 43, 55, 0.8);
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
#image-wrapper-ddd .form-ddd {
  background-color: #fff;
  padding: 20px;
}
#image-wrapper-ddd .form-ddd .medium {
  width: 220px;
  margin-bottom: 20px;
}
#image-wrapper-ddd .form-ddd form .has-error textarea,
#image-wrapper-ddd .form-ddd form .has-error select,
#image-wrapper-ddd .form-ddd form .has-error input {
  background-color: #ffcecd;
  border-bottom-color: #c33436;
}
#image-wrapper-ddd .form-ddd form .has-error .form-control-feedback {
  display: none;
}
#image-wrapper-ddd .form-ddd form .has-error .tooltip-inner {
  background-color: #ffcecd;
  color: #c33436;
}
#image-wrapper-ddd .form-ddd form .has-error .tooltip-arrow {
  border-bottom-color: #ffcecd;
}
#image-wrapper-ddd .form-ddd p {
  margin-top: 20px;
  line-height: 2.2rem;
}
#image-wrapper-ddd .form-ddd .p-inline {
  display: inline;
  line-height: 3.2rem;
}
#image-wrapper-ddd .inline-input {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
#image-wrapper-ddd select.text,
#image-wrapper-ddd textarea.text,
#image-wrapper-ddd input.text {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #474747;
  padding-left: 0;
  text-align: left;
}
#image-wrapper-ddd textarea.text {
  box-shadow: none;
  -webkit-box-shadow: none;
  min-width: 240px;
  min-height: 35px;
  clear: left;
}
#image-wrapper-ddd .zipcode {
  width: 50px;
}
#image-wrapper-ddd .phone {
  width: 85px;
}
#image-wrapper-ddd .name {
  width: 150px;
}
#image-wrapper-ddd .medium {
  width: 280px;
}
#image-wrapper-ddd .large {
  width: 100%;
}
#image-wrapper-ddd .text.invalid {
  background: #ffd4d4;
  color: #c33436;
}
#image-wrapper-ddd .section-button {
  margin-top: 50px;
  text-align: center;
  padding-bottom: 30px;
}
#image-wrapper-ddd .section-button .button.orange1 {
  font-size: 18px;
}
.ddd4steps {
  background-color: #fafafa;
}
.ddd4steps #breadcrumb .row {
  padding: 0 20px;
}
.ddd4steps #breadcrumb .col-xs-3 {
  padding: 0;
}
.ddd4steps #breadcrumb .active {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #ff580c;
  margin: 0;
}
.ddd4steps #breadcrumb .inactive {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #bfbfbf;
  margin: 0;
}
.ddd4steps #breadcrumb .step-0-active {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: 0 -55px;
  width: 16px;
  height: 16px;
  margin-left: -1px;
  margin-top: -7px;
  float: left;
}
.ddd4steps #breadcrumb .step-1-active {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -21px -55px;
  width: 13px;
  height: 13px;
  margin-top: -13px;
  float: right;
}
.ddd4steps #breadcrumb .step-2-active {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -40px -55px;
  width: 28px;
  height: 17px;
  margin-top: -17px;
  float: right;
}
.ddd4steps #breadcrumb .step-2-inactive {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -72px -55px;
  width: 28px;
  height: 17px;
  margin-top: -17px;
  float: right;
}
.ddd4steps #breadcrumb .step-3-active {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -105px -55px;
  width: 21px;
  height: 29px;
  margin-top: -29px;
  float: right;
}
.ddd4steps #breadcrumb .step-3-inactive {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -130px -55px;
  width: 21px;
  height: 29px;
  margin-top: -29px;
  float: right;
}
.ddd4steps #breadcrumb .step-4-active {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -155px -55px;
  width: 26px;
  height: 23px;
  margin-top: -23px;
  float: right;
}
.ddd4steps #breadcrumb .step-4-inactive {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -183px -55px;
  width: 26px;
  height: 23px;
  margin-top: -23px;
  float: right;
}
.ddd4steps .step-1 {
  text-align: center;
  margin-left: 13px;
}
@media (max-width:767px) {
  .ddd4steps .step-1 {
    margin-left: 0;
  }
}
.ddd4steps .step-2 {
  text-align: center;
  margin-left: -22px;
}
@media (max-width:767px) {
  .ddd4steps .step-2 {
    margin-left: 0;
  }
}
.ddd4steps .step-3 {
  text-align: center;
  margin-left: -5px;
}
@media (max-width:767px) {
  .ddd4steps .step-3 {
    margin-left: 0;
  }
}
.ddd4steps .step-4 {
  text-align: center;
  margin-left: -15px;
}
@media (max-width:767px) {
  .ddd4steps .step-4 {
    margin-left: 0;
  }
}
.ddd4steps .nb-step {
  width: 35px;
  height: 35px;
  background: #19556a;
  border-radius: 100px;
  line-height: 35px;
  text-align: center;
  color: #fff;
  font-size: 1.8rem;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
}
.ddd4steps .title-step {
  font-size: 1.8rem;
  display: inline;
  margin-left: 15px;
}
.ddd4steps .category-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.ddd4steps .block-picto {
  background-color: #fff;
  border: 1px solid #cecece;
  border-radius: 3px 3px 0 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
@media (min-width:992px) {
  .ddd4steps .block-picto {
    min-width: 140px;
    min-height: 160px;
  }
}
@media (min-width:768px) {
  .ddd4steps .block-picto {
    min-height: 160px;
  }
}
@media (max-width:767px) {
  .ddd4steps .block-picto {
    min-height: 145px;
  }
}
.ddd4steps .block-picto:hover,
.ddd4steps .block-picto.picto-active {
  border: 1px solid #05d8c6;
  background-color: #05d8c6;
}
.ddd4steps .block-picto:hover .label,
.ddd4steps .block-picto.picto-active .label {
  color: #fff;
}
.ddd4steps .block-picto .picto-label {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  line-height: 1.4rem;
  font-size: 1.4rem;
}
@media (max-width:991px) {
  .ddd4steps .block-picto .picto-label {
    height: auto;
  }
}
.ddd4steps .block-picto .picto {
  display: block;
  margin: 10px auto 0 auto;
  width: 75px;
  height: 75px;
}
.ddd4steps .block-description {
  background-color: #fff;
  border: 1px solid #cecece;
  border-top: 0 none;
  border-radius: 0 0 3px 3px;
  padding: 10px;
  color: #ff580c;
  text-align: center;
  height: 80px;
  display: table-cell;
  vertical-align: middle;
}
.ddd4steps .block-error {
  background: #ffd4d4 !important;
  border: 1px solid #c33436;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  color: #c33436;
  padding: 10px;
  margin-top: 20px;
}
.ddd4steps a.link,
.ddd4steps a.link:active,
.ddd4steps a.link:visited {
  color: #ff580c;
  text-decoration: none;
}
.ddd4steps a.link:hover,
.ddd4steps a.link:active:hover,
.ddd4steps a.link:visited:hover {
  color: #ff580c;
  text-decoration: underline;
}
.ddd4steps .has-feedback label ~ .form-control-feedback {
  top: 20px;
}
.ddd4steps .section-button {
  margin-top: 42px;
  text-align: center;
  padding-bottom: 40px;
}
.ddd4steps .section-button .button {
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
  font-size: 18px;
}
.ddd4steps .section-button .button:hover {
  color: white;
  border: 1px solid #f64e01;
  background-color: #f64e01;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
.ddd4steps .iconLetter {
  width: 50px;
  height: 45px;
  position: absolute;
  background: #e6e6e6;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  -webkit-border-radius: 3px 0 0 3px;
  border: 1px solid #979797;
  margin-top: 14px;
}
@media (max-width:767px) and (max-width:991px) {
  .ddd4steps .iconLetter {
    margin-top: 12px;
  }
}
.ddd4steps .iconLetter .has-error {
  background: #ffd4d4 !important;
  border: 1px solid #c33436;
}
.ddd4steps .sprite-id {
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -192px -68px;
  width: 27px;
  height: 28px;
  display: inline-block;
  margin-top: 6px;
  margin-left: 12px;
}
.ddd4steps .sprite-id .sprite-error {
  background-position: -221px -68px;
  width: 27px;
  height: 28px;
}
.ddd4steps .sprite-pin {
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -205px -100px;
  width: 21px;
  height: 28px;
  display: inline-block;
  margin-top: 8px;
  margin-left: 13px;
}
.ddd4steps .sprite-pin .sprite-error {
  background-position: -228px -100px;
  width: 21px;
  height: 28px;
}
.ddd4steps .sprite-mail {
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: 0 -100px;
  width: 28px;
  height: 19px;
  display: inline-block;
  margin-top: 12px;
  margin-left: 10px;
}
.ddd4steps .sprite-mail .sprite-error {
  background-position: -30px -100px;
  width: 28px;
  height: 19px;
}
.ddd4steps .sprite-phone {
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -145px -100px;
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-top: 8px;
  margin-left: 10px;
}
.ddd4steps .sprite-phone .sprite-error {
  background-position: -175px -100px;
  width: 28px;
  height: 28px;
}
.ddd4steps form .cgu-txt input {
  margin-right: 5px;
}
.ddd4steps .footer {
  margin-top: 20px;
  text-align: justify;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.4rem;
  color: #474747;
}
.ddd4steps .orange {
  color: #ff580c;
}
.ddd4steps .grey {
  color: #bfbfbf;
}
.ddd4steps .form-height {
  height: 45px;
  padding-left: 63px;
}
.ddd4steps .mt-10 {
  margin-top: 10px;
}
.ddd4steps .mt-40 {
  margin-top: 40px;
}
.ddd4steps .mb-40 {
  margin-bottom: 40px;
}
.ddd4steps .iti {
  display: block;
}
.form-category-footer {
  padding-bottom: 20px;
}
.form-category-footer .title-block {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  text-transform: uppercase;
  margin-top: 40px;
}
.form-category-footer .ss-title-block {
  line-height: 18px;
  padding-left: 30px;
  padding-right: 30px;
}
.form-category-footer .mt-20 {
  margin-top: 20px;
}
.form-category-footer .mt-40 {
  margin-top: 40px;
}
.form-category-footer .mb-40 {
  margin-bottom: 40px;
}
.form-category-footer .category-text {
  text-align: justify;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
}
@media (max-width:767px) {
  .form-category-footer .ss-title-prix {
    margin-left: 20px;
  }
}
.form-category-footer ul li {
  padding: 10px;
}
.form-category-footer ul .fleche-noir {
  background-position: -50px -29px;
  width: 5px;
  height: 10px;
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 10px;
}
@media (max-width:767px) {
  .form-category-footer ul {
    margin-left: 60px;
  }
}
.form-category-footer .info-row {
  display: table;
  margin-top: 15px;
}
.form-category-footer .info-row .info-text {
  display: table-cell;
  vertical-align: middle;
  padding-left: 10px;
}
.form-category-footer .info-row .info-text .normal {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
.form-category-footer .info-row .info-text .normal strong {
  font-weight: bold;
}
.form-category-footer .info-row .info-text .ss-txt {
  margin-top: 5px;
}
.form-category-footer a.link {
  color: #474747;
  text-decoration: none;
}
.form-category-footer a.link:hover {
  color: #474747;
  text-decoration: underline;
}
.form-category-footer .modal-title {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
}
.form-category-footer .txt-forgot-password {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
}
.form-category-footer .modal-footer {
  text-align: justify;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #474747;
}
.form-category-footer .sprite-mail {
  background-image: url('/images/habitatpresto/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: 0 -100px;
  width: 28px;
  height: 19px;
  display: inline-block;
  margin-top: 12px;
  margin-left: 10px;
}
.form-category-footer .iconLetter {
  width: 50px;
  height: 45px;
  position: absolute;
  background: #e6e6e6;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border: 1px solid #979797;
  left: 0;
  margin-top: 14px;
  margin-left: 15px;
}
@media (max-width:767px) {
  .form-category-footer .iconLetter {
    margin-top: 13px;
  }
}
.form-category-footer .form-control {
  padding-left: 60px;
  height: 45px;
  border: 1px solid #979797;
}
.form-category-footer .checkbox {
  line-height: 18px;
}
.form-category-footer .mt-30 {
  margin-top: 30px;
}
.form-category-footer .prix {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
}
.form-category-footer .form-stats-header .text {
  text-align: center;
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: #474747;
  font-weight: bold;
  margin-top: 10px;
  text-transform: none;
}
.form-category-footer .catalogue-prix {
  line-height: 18px;
  text-align: justify;
}
#form-annuaire form {
  margin-top: 20px;
}
@media (max-width:767px) {
  #form-annuaire form {
    padding: 0 10px 10px 10px;
  }
}
#form-annuaire label {
  background-color: #FFF;
  height: 50px;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-color: #fff;
  font-size: 1.8rem;
  padding-top: 7px;
  font-weight: normal;
}
#form-annuaire label img {
  margin: 2px 4px;
  padding: 0;
  width: 27px;
}
@media (max-width:991px) {
  #form-annuaire label img {
    margin: 4px 3px;
    width: 26px;
  }
}
@media (max-width:575px) {
  #form-annuaire label img {
    margin: 6px 3px;
    width: 21px;
  }
}
@media (max-width:767px) {
  #form-annuaire .no-pl-xs {
    padding-left: 0;
  }
}
#form-annuaire input {
  height: 44px;
  font-size: 1.8rem;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border: 1px solid #FFF;
  box-shadow: inset 0 0 0 #ffffff;
}
#form-annuaire .no-pl {
  padding-left: 0;
}
#form-annuaire .no-pr {
  padding-right: 0;
}
#form-annuaire .form-group {
  margin-bottom: 0;
}
#form-annuaire .select2-container {
  width: 100%!important;
}
#form-annuaire .select2-selection {
  font-size: 18px;
  padding-left: 10px;
  height: 50px;
  border: none;
}
#form-annuaire .select2-selection__rendered {
  line-height: 50px;
}
#form-annuaire .select2-selection__arrow {
  display: none;
}
#form-annuaire .directory_form_city + .select2 .select2-selection--single {
  border-radius: 0px 4px 4px 0px;
}
@media (max-width:767px) {
  #form-annuaire #form-select-container {
    padding: 20px 0 10px;
  }
}
#form-annuaire #form-select-container select {
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (max-width:767px) {
  #form-annuaire #form-select-container input {
    padding: 10px 10px;
  }
}
#form-annuaire #form-submit-container {
  margin: 0;
  padding: 0 0 0 10px;
}
@media (max-width:767px) {
  #form-annuaire #form-submit-container {
    padding: 10px 0;
  }
}
#form-annuaire #form-submit-container .bt-form {
  font-size: 1.8rem;
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 0;
  width: 100%;
  min-height: 50px;
}
#form-annuaire #form-submit-container .bt-form:hover {
  border-color: #ff580c;
}
#form-annuaire .search-input {
  padding: 2px;
  border-radius: 4px;
  background-color: #fff;
  min-height: 50px;
}
#form-annuaire .search-input input {
  border: 0;
  box-shadow: none;
}
#form-annuaire .search-input button {
  margin: 2px 0 0 0;
  background: none;
  box-shadow: none;
  border: 0;
  color: #666666;
  padding: 0 8px 0 10px;
}
#form-annuaire .search-input button:hover {
  border: 0;
  box-shadow: none;
}
#form-annuaire .search-input .glyphicon-search,
#form-annuaire .search-input .glyphicon-remove {
  font-size: 2.5rem;
  top: 3px;
  right: 5px;
}
#form-annuaire .search-input .glyphicon-remove {
  display: none;
}
.ddd-horizontal {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.44);
  line-height: 18px;
}
.ddd-horizontal.green-wrapper {
  background-color: #27a3b2;
  padding-bottom: 40px;
}
.ddd-horizontal.blue-wrapper {
  background-color: #11404d;
}
.ddd-horizontal.orange-wrapper {
  background-color: #ff580c;
}
.ddd-horizontal p {
  color: #fff;
}
.ddd-horizontal p.title {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  color: #fff !important;
}
.ddd-horizontal p.subtitle {
  line-height: 2rem;
  text-align: center;
}
.ddd-horizontal p.subtitle span {
  display: block;
}
@media screen and (max-width:767px) {
  .ddd-horizontal p.subtitle span {
    display: inline;
  }
}
.ddd-horizontal form {
  margin-top: 20px;
}
.ddd-horizontal form select {
  font-size: 1.8rem;
  padding: 10px;
  height: 44px;
}
.ddd-horizontal form select option:first-child {
  font-size: 1.6rem;
}
.ddd-horizontal form input {
  font-size: 1.8rem;
  padding: 10px;
  height: 44px;
}
@media (max-width:991px) {
  .ddd-horizontal form input {
    margin-top: 10px;
  }
}
.ddd-horizontal form .bt-green {
  color: white;
  border: 1px solid #05d8c6;
  background-color: #05d8c6;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  border: 1px solid #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.8rem;
  padding: 10px 20px;
  height: 44px;
  width: 100%;
}
/****** INCLUDES ******/
.card-project-infos {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #8d8d8d;
  background-color: #fff;
  border-radius: 2px;
}
.card-project-infos.grey {
  background-color: #efefef;
}
.card-project-infos.grey .edit-project {
  display: none;
}
.card-project-infos h3 {
  margin-bottom: 10px;
  font-weight: bold;
}
.card-project-infos .project-img {
  width: 100%;
}
@media (max-width:767px) {
  .card-project-infos .project-infos {
    padding: 0 15px;
  }
}
.card-project-infos .project-infos .edit-project {
  display: inline-block;
  padding: 10px;
  margin: 0 10px;
  color: #05d8c6;
  border: 1px solid #05d8c6;
  border-radius: 3px;
}
.card-project-infos .project-infos .edit-project i {
  font-size: 1.7rem;
}
.card-project-infos .project-infos .edit-project:hover {
  color: #fff;
  background: #05d8c6;
}
.card-project-infos .heading {
  font-weight: bold;
  font-size: 1.5rem;
}
.card-project-infos h2 {
  font-weight: bold;
  margin: 15px 0;
}
.card-project-infos span {
  font-weight: bold;
}
.card-project-infos p {
  line-height: 2.2rem;
}
.card-project-infos hr {
  width: 98%;
  margin-left: 0;
}
.card-project-infos .project-description {
  padding: 15px;
}
.card-project-infos .project-description .heading {
  margin-bottom: 15px;
}
.card-project-infos .project-details {
  padding: 0 15px 15px;
}
.card-project-infos .project-details .heading {
  margin-top: 10px;
}
.card-project-infos .project-details .col-6:first-child {
  border-right: 2px dotted #cecece;
}
.card-project-infos .breadcrumb-progress .row {
  padding: 0 80px 0 55px;
}
.card-project-infos .breadcrumb-progress .col-4 {
  padding: 0;
}
.card-project-infos .breadcrumb-progress .active {
  display: block;
  height: 2px;
  background-color: #ff580c;
  margin: 0;
}
.card-project-infos .breadcrumb-progress .inactive {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #bfbfbf;
  margin: 0;
}
.card-project-infos .breadcrumb-progress .step-0-active {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-nav-progress.png');
  background-repeat: no-repeat;
  background-position: 0;
  width: 22px;
  height: 27px;
  margin-left: -2px;
  margin-top: -22px;
  float: left;
}
.card-project-infos .breadcrumb-progress .step-0-inactive {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-nav-progress.png');
  background-repeat: no-repeat;
  background-position: -119px;
  width: 22px;
  height: 27px;
  margin-left: -2px;
  margin-top: -22px;
  float: left;
}
.card-project-infos .breadcrumb-progress .step-1-active {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-nav-progress.png');
  background-repeat: no-repeat;
  background-position: -23px 0;
  width: 26px;
  height: 23px;
  margin-top: -23px;
  float: right;
}
.card-project-infos .breadcrumb-progress .step-1-inactive {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-nav-progress.png');
  background-repeat: no-repeat;
  background-position: -170px 0;
  width: 26px;
  height: 23px;
  margin-top: -23px;
  float: right;
}
.card-project-infos .breadcrumb-progress .step-2-active {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-nav-progress.png');
  background-repeat: no-repeat;
  background-position: -50px 0;
  width: 26px;
  height: 23px;
  margin-top: -23px;
  float: right;
}
.card-project-infos .breadcrumb-progress .step-2-inactive {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-nav-progress.png');
  background-repeat: no-repeat;
  background-position: -143px 0;
  width: 26px;
  height: 23px;
  margin-top: -23px;
  float: right;
}
.card-project-infos .breadcrumb-progress .step-3-active {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-nav-progress.png');
  background-repeat: no-repeat;
  background-position: -78px 0;
  width: 37px;
  height: 27px;
  margin-top: -29px;
  float: right;
}
.card-project-infos .breadcrumb-progress .step-3-inactive {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-nav-progress.png');
  background-repeat: no-repeat;
  background-position: -198px 0;
  width: 37px;
  height: 27px;
  margin-top: -29px;
  float: right;
}
.card-project-infos .project-progress span.step {
  font-weight: normal;
  color: #ff580c;
  border: 1px solid #ff580c;
  border-radius: 5px;
  padding: 5px 10px;
}
.card-project-infos .project-progress span.first-step {
  margin-left: -17px;
}
.card-project-infos .cancel-project {
  float: right;
  color: #05d8c6;
  border: 1px solid #05d8c6;
  margin: 10px;
  padding: 10px;
  border-radius: 3px;
}
.card-project-infos .cancel-project:hover {
  color: #fff;
  background: #05d8c6;
}
.card-projects-list .project-details {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #8d8d8d;
  border-radius: 2px;
}
.card-projects-list .project-details .project-img {
  width: 100%;
}
@media (max-width:767px) {
  .card-projects-list .project-details .project-infos {
    padding: 15px;
  }
}
.card-projects-list .project-details .project-infos .heading {
  font-weight: bold;
  font-size: 1.5rem;
}
.card-projects-list .project-details .project-infos h2 {
  font-weight: bold;
  margin: 15px 0;
}
.card-projects-list .project-details .project-infos span {
  font-weight: bold;
}
.card-projects-list .project-details .project-infos p {
  line-height: 2.2rem;
}
.card-projects-list .project-details .project-infos .link {
  display: block;
  margin-top: 30px;
  margin-right: 10px;
  color: #ff580c;
  text-align: right;
}
@media (max-width:1199px) {
  .card-projects-list .project-details .project-infos .link {
    margin-top: 5px;
  }
}
.card-projects-list .project-details .project-infos .link .arrow-orange {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -50px -20px;
  width: 5px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -1px;
}
.consumer-tips {
  padding: 2rem;
  border-radius: 1rem;
  background-color: #daebff;
}
.consumer-tips p {
  text-align: left;
  line-height: 2.2rem;
}
.consumer-tips p .link {
  display: block;
  text-decoration: underline;
  cursor: pointer;
  color: #ff580c;
}
.card-pro-positioned {
  margin-top: 20px;
  margin-bottom: 40px;
  border: 1px solid #8d8d8d;
  border-radius: 2px;
}
.card-pro-positioned .card-pro-positioned-badge {
  width: 70px;
  height: 70px;
  margin-top: 10px;
}
.card-pro-positioned .heading {
  font-weight: bold;
}
.card-pro-positioned p {
  line-height: 2.2rem;
}
.card-pro-positioned hr {
  width: 97%;
}
.card-pro-positioned .mail {
  word-break: break-all;
}
.card-pro-positioned .link {
  margin-top: 20px;
  color: #ff580c;
  display: block;
}
.card-pro-positioned .link .arrow-orange {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -50px -20px;
  width: 5px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
}
.card-pro-positioned .arrow-blue {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -50px -10px;
  width: 5px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
}
.card-pro-positioned .rating-stars .rating-symbol {
  display: inline;
  position: relative;
}
.card-pro-positioned .rating-stars .rating-symbol .fa-2x {
  font-size: 1.2em;
  margin: 10px 0 0;
}
.card-pro-positioned .rating-stars .rating-symbol .custom-star {
  color: #ffe066;
  font-size: 1.2em;
}
.card-pro-positioned .rating-stars .rating-symbol .rating-symbol-background {
  visibility: hidden;
}
.card-pro-positioned .rating-stars .rating-symbol .rating-symbol-foreground {
  display: inline-block;
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  width: auto;
}
.card-pro-positioned .informations {
  padding: 15px;
}
.card-pro-positioned .informations .company h3 {
  text-transform: uppercase;
  margin-top: 7px;
  margin-bottom: 10px;
}
.card-pro-positioned .informations .company span {
  font-weight: bold;
}
.card-pro-positioned .informations .contact-info a {
  color: #000;
  text-decoration: underline;
}
@media (max-width:767px) {
  .card-pro-positioned .informations .contact-info {
    margin-top: 15px;
  }
}
@media (max-width:991px) {
  .card-pro-positioned .informations .contact-info p {
    word-break: break-all;
  }
}
.card-pro-positioned .informations .contact-info .labels-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.card-pro-positioned .informations .contact-info .labels-block .rge-logo img {
  width: 70px;
  height: auto;
}
.card-pro-positioned .informations .advice {
  text-align: right;
}
.card-pro-positioned .informations .advice a {
  color: #05d8c6;
}
.card-pro-positioned .informations .advice .bt-orange {
  font-size: 1.5rem;
  color: #fff;
  margin-top: 15px;
  cursor: pointer;
  display: inline-block;
}
.card-pro-positioned .details {
  padding: 0 15px 20px;
}
.card-pro-positioned .details .info {
  color: #3a87ad;
  font-size: 1rem;
  font-weight: bold;
}
@media (max-width:767px) {
  .card-pro-positioned .details .info {
    margin-bottom: 5px;
    margin-top: -10px;
  }
}
.card-pro-positioned .details span {
  font-weight: bold;
  margin-left: 5px;
}
.card-pro-positioned .details .devis-check img {
  margin-left: 10px;
}
.card-pro-positioned .details .devis-check span:last-child {
  font-weight: normal;
  color: #ff580c;
}
.card-pro-positioned .details .devis-upload {
  margin-top: 10px;
}
.card-pro-positioned .details .devis-upload a span {
  font-weight: normal;
  color: #05d8c6;
}
.card-pro-positioned .details .devis-upload img {
  margin-left: 10px;
  margin-top: -10px;
}
.card-pro-positioned .details .heading {
  margin-bottom: 10px;
}
@media (max-width:767px) {
  .card-pro-positioned .details input {
    margin-bottom: 10px;
  }
}
.card-pro-positioned .reviews.row {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
.card-pro-positioned .reviews {
  padding: 5px 15px 15px;
  border-top: 1px solid #8d8d8d;
}
.card-pro-positioned .reviews .review-col {
  text-align: center;
}
.card-pro-positioned .reviews .review-col .review-comment {
  margin-top: 10px;
  margin-bottom: 10px;
}
.card-pro-positioned .reviews .date {
  margin-left: 5px;
  font-weight: bold;
  color: #05d8c6;
}
.card-pro-positioned .reviews .heading {
  margin: 5px 0;
}
.card-pro-positioned .reviews .edit-advice {
  color: #05d8c6;
  display: block;
  margin-top: 10px;
  float: right;
}
.card-pro-positioned .answer {
  padding: 15px;
  background-color: #efefef;
}
.card-pro-positioned .pro-contact-yes-no {
  padding: 0px 20px 10px 20px;
}
.card-pro-positioned .pro-contact-yes-no p {
  padding-left: 15px;
  padding-right: 15px;
  line-height: 30px;
}
.card-pro-empty {
  margin-top: 20px;
  margin-bottom: 40px;
  border: 1px solid #8d8d8d;
  border-radius: 2px;
  padding: 15px;
  text-align: center;
}
.card-pro-empty h3 {
  font-weight: bold;
  margin-bottom: 15px;
}
.card-pro-empty .bt-orange {
  font-size: 1.5rem;
  margin-top: 15px;
  cursor: pointer;
  display: inline-block;
}
.card-pro-empty .bt-orange:hover {
  color: #fff;
}
.star-green {
  color: #009600;
}
.card-pro-details {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #8d8d8d;
  background-color: #fff;
  border-radius: 2px;
  line-height: 1.8rem;
}
.card-pro-details .title-rubrique {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  text-transform: uppercase;
  margin-top: 10px;
}
.card-pro-details .block-info-artisan {
  padding: 10px;
}
.card-pro-details .block-info-artisan a.orange {
  color: #ff580c;
}
.card-pro-details .block-info-artisan .pro-badge {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
}
@media (max-width:767px) {
  .card-pro-details .block-info-artisan .pro-badge {
    margin-top: 10px;
  }
}
.card-pro-details .block-info-artisan img {
  width: 100%;
}
.card-pro-details .block-info-artisan .labels-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}
.card-pro-details .block-info-artisan .labels-block .rge-logo img {
  width: 100px;
  height: auto;
  margin-bottom: 1rem;
}
.card-pro-details .block-info-artisan .col-md-4 {
  text-align: center;
}
.card-pro-details .block-info-artisan .bt-orange {
  font-size: 1.4rem;
  margin-top: 10px;
  cursor: pointer;
}
@media (max-width:767px) {
  .card-pro-details .rating-stars {
    display: block;
  }
}
.card-pro-details .rating-stars .rating-symbol {
  display: inline;
  position: relative;
}
.card-pro-details .rating-stars .rating-symbol .fa-2x {
  font-size: 1.2em;
}
.card-pro-details .rating-stars .rating-symbol .custom-star {
  color: #ffe066;
  font-size: 1.2em;
}
.card-pro-details .rating-stars .rating-symbol .rating-symbol-background {
  visibility: hidden;
}
.card-pro-details .rating-stars .rating-symbol .rating-symbol-foreground {
  display: inline-block;
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  width: auto;
}
.card-pro-details .divider {
  border-left: 1px solid #ddd;
  height: auto;
}
@media screen and (max-width:991px) {
  .card-pro-details .divider {
    width: 80%;
    border-bottom: solid 1px #ddd;
    height: 1px;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
.card-pro-details .review-badge {
  width: 80px;
  height: 80px;
}
.card-pro-details .pro-contact {
  padding: 10px;
}
.card-pro-details .pro-contact .heading {
  font-weight: bold;
}
.card-pro-details .pro-contact a {
  color: #000;
  text-decoration: underline;
}
.card-pro-details .info-company {
  background: #f0f0f0;
  padding: 0 10px 15px;
  margin: 20px 5px 5px;
}
.card-pro-details .info-company a {
  color: #000;
  text-decoration: underline;
}
.card-pro-details .info-company li {
  list-style-type: disc;
  margin-left: 20px;
}
.card-pro-details .info-company .sprite-info {
  background-position: -240px -336px;
  width: 24px;
  height: 23px;
  background-image: url('/images/habitatpresto/sprites.png');
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 10px;
}
.card-pro-details .info-company .sprite-doc {
  background-position: -240px -367px;
  width: 23px;
  height: 23px;
  background-image: url('/images/habitatpresto/sprites.png');
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 10px;
}
.card-pro-details .text-black {
  color: black;
}
.card-pro-details .star-green {
  color: #009600;
}
.card-pro-reviews {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #8d8d8d;
  background-color: #fff;
  border-radius: 2px;
  line-height: 2rem;
}
.card-pro-reviews .title-rubrique {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  text-transform: uppercase;
  margin-top: 10px;
}
.card-pro-reviews .title-rubrique .sprite-avis {
  background-position: -277px -352px;
  width: 22px;
  height: 19px;
  background-image: url('/images/habitatpresto/sprites.png');
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 10px;
}
.card-pro-reviews .rating-stars .rating-symbol {
  display: inline;
  position: relative;
}
.card-pro-reviews .rating-stars .rating-symbol .custom-star {
  color: #ffe066;
  font-size: 1.2em;
}
.card-pro-reviews .rating-stars .rating-symbol .fa-2x {
  font-size: 1.5em;
  margin: 10px 0 0;
}
.card-pro-reviews .rating-stars .rating-symbol .rating-symbol-background {
  visibility: hidden;
}
.card-pro-reviews .rating-stars .rating-symbol .rating-symbol-foreground {
  display: inline-block;
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  width: auto;
}
.card-pro-reviews .response-pro {
  background-color: #f8f8f8;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #727272;
}
.card-pro-reviews .response-pro ul {
  margin: 10px 15px 15px;
  line-height: 24px;
}
.card-pro-reviews .response-pro .tip {
  background-image: url('/images/habitatpresto/pointe-avis.jpg');
  background-repeat: no-repeat;
  background-position: 80px 0;
  height: 19px;
  display: block;
  margin-top: -1px;
}
.card-pro-reviews .nocontact {
  text-transform: uppercase;
  background-color: #dddddd;
  border: 1px solid #c2c2c2;
  color: #828282;
  padding: 8px;
  margin-top: -10px;
}
.card-pro-reviews .visitscheduled {
  text-transform: uppercase;
  background-color: #ffedbc;
  border: 1px solid #fed973;
  color: #c19211;
  padding: 8px;
  margin-top: -10px;
}
.card-pro-reviews .estimatereceived {
  text-transform: uppercase;
  background-color: #ffd6c4;
  border: 1px solid #ffbea0;
  color: #ff580c;
  padding: 8px;
  margin-top: -10px;
}
.card-pro-reviews .workinprogress {
  text-transform: uppercase;
  background-color: #d9edf7;
  border: 1px solid #c8eaf3;
  color: #3a87ad;
  padding: 8px;
  margin-top: -10px;
}
.card-pro-reviews .workcompleted {
  text-transform: uppercase;
  background-color: #dff0d8;
  border: 1px solid #daeccd;
  color: #3c763d;
  padding: 8px;
  margin-top: -10px;
}
.card-pro-photos {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #8d8d8d;
  background-color: #fff;
  border-radius: 2px;
  line-height: 2rem;
}
.card-pro-photos .title-rubrique {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  text-transform: uppercase;
  margin-top: 10px;
}
.card-pro-photos .title-rubrique .sprite-photo {
  background-position: -276px -281px;
  width: 23px;
  height: 18px;
  background-image: url('/images/habitatpresto/sprites.png');
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 10px;
}
.card-pro-photos .pro-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 220px;
  margin-top: 10px;
}
.card-pro-photos span {
  display: block;
  text-align: center;
  margin-top: 10px;
}
.card-pro-labels {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #8d8d8d;
  background-color: #fff;
  border-radius: 2px;
  line-height: 2rem;
}
.card-pro-labels .title-rubrique {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  text-transform: uppercase;
  margin-top: 10px;
}
.card-pro-labels .title-rubrique .sprite-label {
  background-position: -276px -313px;
  width: 24px;
  height: 28px;
  background-image: url('/images/habitatpresto/sprites.png');
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}
.card-pro-labels .pro-label {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 220px;
  margin-top: 10px;
}
.card-pro-comment {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #8d8d8d;
  background-color: #fff;
  border-radius: 2px;
  line-height: 2rem;
}
.card-pro-comment .rating {
  margin-bottom: 10px;
}
.card-pro-comment .rating .rating-stars .rating-symbol {
  display: inline;
  position: relative;
}
.card-pro-comment .rating .rating-stars .rating-symbol .custom-star {
  color: #b3b2b2;
  font-size: 1.8em;
}
.card-pro-comment .rating .rating-stars .rating-symbol .star-color-0 {
  color: #b3b2b2;
}
.card-pro-comment .rating .rating-stars .rating-symbol .star-color-1 {
  color: #ff0000;
}
.card-pro-comment .rating .rating-stars .rating-symbol .star-color-2 {
  color: #ff8f00;
}
.card-pro-comment .rating .rating-stars .rating-symbol .star-color-3 {
  color: #ffed00;
}
.card-pro-comment .rating .rating-stars .rating-symbol .star-color-4 {
  color: #7ed957;
}
.card-pro-comment .rating .rating-stars .rating-symbol .star-color-5 {
  color: #04bf00;
}
.card-pro-comment .rating .rating-stars .rating-symbol .rating-symbol-background {
  visibility: hidden;
}
.card-pro-comment label {
  font-weight: bold;
  margin-bottom: 10px;
}
.card-pro-comment .form-control {
  height: 45px !important;
  border: 1px solid #979797;
  margin-bottom: 15px;
  font-size: 14px;
}
.card-pro-comment textarea {
  min-height: 100px;
}
.card-pro-comment .radio {
  display: inline-block;
  float: left;
  margin-right: 10px;
}
.card-pro-comment .rating-statics:after {
  display: block;
  clear: both;
  content: "";
}
.card-pro-comment .bt-orange {
  font-size: 1.5rem;
  cursor: pointer;
}
.card-pro-comment .review-global-score {
  border: 2px solid #b3b2b2;
  padding: 3px 5px 3px 5px;
  font-weight: bolder;
}
.card-pro-comment .global-score-0 {
  color: #b3b2b2;
  border-color: #b3b2b2 !important;
}
.card-pro-comment .global-score-1 {
  color: #ff0000;
  border-color: #ff0000 !important;
}
.card-pro-comment .global-score-2 {
  color: #ff8f00;
  border-color: #ff8f00 !important;
}
.card-pro-comment .global-score-3 {
  color: #ffed00;
  border-color: #ffed00 !important;
}
.card-pro-comment .global-score-4 {
  color: #7ed957;
  border-color: #7ed957 !important;
}
.card-pro-comment .global-score-5 {
  color: #04bf00;
  border-color: #04bf00 !important;
}
.card-pro-comment .rating-ok-icon {
  font-size: 4em;
}
.card-pro-comment .rating-smiley-icon {
  font-size: 1.5em;
  font-weight: bold;
  margin-left: 5px;
}
.card-pro-comment .thank-for-review {
  padding-right: 30px;
  padding-left: 30px;
}
.card-pro-comment .rating-a {
  text-decoration: underline;
}
.card-pro-comment .review-block {
  padding-top: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.card-pro-comment .review-block:first-of-type {
  padding-top: 0px;
}
.card-pro-comment .review-block:nth-child(odd) {
  background-color: #efefef;
}
.card-pro-comment .star-green {
  color: #009600;
}
.card-edit-project {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #8d8d8d;
  background-color: #fff;
  border-radius: 2px;
  line-height: 2rem;
}
.card-edit-project h2 {
  display: inline-block;
}
.card-edit-project .iconLetter {
  width: 50px;
  height: 45px;
  position: absolute;
  background: #e6e6e6;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border: 1px solid #979797;
  left: 0;
  margin-left: 15px;
}
.card-edit-project .nb {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 40px;
  height: 40px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  padding-top: 9px;
  font-size: 2rem;
  display: inline-block;
  margin-right: 15px;
}
.card-edit-project .sprite-consumer-calendar {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
  background-position: -148px 0;
  width: 29px;
  height: 29px;
  display: inline-block;
  margin-top: 7px;
  margin-left: 10px;
}
.card-edit-project .sprite-consumer-helmet {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
  background-position: -181px 0;
  width: 33px;
  height: 33px;
  display: inline-block;
  margin-top: 7px;
  margin-left: 8px;
}
.card-edit-project .sprite-consumer-mail {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
  background-position: -422px 0;
  width: 28px;
  height: 40px;
  display: inline-block;
  margin-top: 13px;
  margin-left: 10px;
}
.card-edit-project .sprite-consumer-head {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
  background-position: -318px 0;
  width: 32px;
  height: 32px;
  display: inline-block;
  margin-top: 4px;
  margin-left: 8px;
}
.card-edit-project .sprite-consumer-clock {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
  background-position: -355px 0;
  width: 29px;
  height: 29px;
  display: inline-block;
  margin-top: 7px;
  margin-left: 10px;
}
.card-edit-project .sprite-consumer-hammer {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
  background-position: -219px 0;
  width: 34px;
  height: 34px;
  display: inline-block;
  margin-top: 6px;
  margin-left: 8px;
}
.card-edit-project .sprite-consumer-pin {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
  background-position: -258px 0;
  width: 21px;
  height: 29px;
  display: inline-block;
  margin-top: 8px;
  margin-left: 13px;
}
.card-edit-project .sprite-consumer-beds {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
  background-position: -284px 0;
  width: 29px;
  height: 29px;
  display: inline-block;
  margin-top: 8px;
  margin-left: 10px;
}
.card-edit-project .form-control {
  padding-left: 60px;
  border: 1px solid #979797;
  margin-bottom: 15px;
  font-size: 14px;
}
.card-edit-project .form-control:not(.textarea-project-description) {
  height: 45px !important;
}
.card-edit-project textarea {
  padding: 7px !important;
  min-height: 100px !important;
}
.card-edit-project label {
  font-weight: bold;
  margin-bottom: 1em;
}
.card-edit-project .bt-orange {
  font-size: 1.5rem;
  cursor: pointer;
}
.card-edit-project .checkbox input {
  margin-right: 5px;
}
.card-edit-project .legal-notice {
  font-style: italic;
  font-size: 12px;
  text-align: justify;
  line-height: 1.2em;
  margin-top: 2em;
}
.card-edit-project .iti {
  display: block;
  margin-bottom: 15px;
}
.no-border {
  border: none;
}
.card-edit-profile {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #8d8d8d;
  background-color: #fff;
  border-radius: 2px;
  line-height: 2rem;
}
.card-edit-profile .iconLetter {
  width: 50px;
  height: 45px;
  position: absolute;
  background: #e6e6e6;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border: 1px solid #979797;
  left: 0;
  margin-left: 15px;
}
.card-edit-profile .sprite-consumer-mail {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
  background-position: -422px 0;
  width: 28px;
  height: 40px;
  display: inline-block;
  margin-top: 13px;
  margin-left: 10px;
}
.card-edit-profile .sprite-consumer-head {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
  background-position: -318px 0;
  width: 32px;
  height: 32px;
  display: inline-block;
  margin-top: 4px;
  margin-left: 8px;
}
.card-edit-profile .sprite-consumer-clock {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
  background-position: -355px 0;
  width: 29px;
  height: 29px;
  display: inline-block;
  margin-top: 7px;
  margin-left: 10px;
}
.card-edit-profile .sprite-consumer-phone {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
  background-position: -389px 0;
  width: 29px;
  height: 29px;
  display: inline-block;
  margin-top: 7px;
  margin-left: 11px;
}
.card-edit-profile .form-control {
  padding-left: 60px;
  height: 45px !important;
  border: 1px solid #979797;
  margin-bottom: 15px;
  font-size: 14px;
}
.card-edit-profile label {
  font-weight: bold;
}
.card-edit-profile .bt-orange {
  font-size: 1.5rem;
  cursor: pointer;
}
.card-edit-profile .iti {
  display: block;
  margin-bottom: 15px;
}
.card-edit-password {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #8d8d8d;
  background-color: #fff;
  border-radius: 2px;
  line-height: 2rem;
}
.card-edit-password ul {
  list-style-type: disc;
  margin-left: 40px;
  margin-bottom: 20px;
}
.card-edit-password .iconLetter {
  width: 50px;
  height: 45px;
  position: absolute;
  background: #e6e6e6;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border: 1px solid #979797;
  left: 0;
}
.card-edit-password .sprite-consumer-lock {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
  background-position: -455px 0;
  width: 19px;
  height: 26px;
  display: inline-block;
  margin-top: 9px;
  margin-left: 14px;
}
.card-edit-password .form-control {
  padding-left: 45px;
  height: 45px !important;
  border: 1px solid #979797;
  margin-bottom: 15px;
  font-size: 14px;
}
.card-edit-password label {
  font-weight: bold;
}
.card-edit-password .bt-orange {
  font-size: 1.5rem;
  cursor: pointer;
}
.card-edit-password .alert-danger .list-unstyled {
  list-style-type: none;
  margin: 0;
}
.new-project-card {
  margin: 20px 0;
}
.new-project-card .title {
  background-color: #05d8c6;
  color: #fff;
  text-transform: uppercase;
  padding: 15px;
  font-size: 1.8rem;
}
.new-project-card ul li {
  background-color: #efefef;
  border-bottom: 1px solid #474747;
  padding: 15px;
  color: #474747;
}
.new-project-card ul li:hover {
  background-color: #05d8c6;
  color: #fff;
}
.new-project-card ul li:hover .arrow-blue {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
  background-position: -479px 0;
  width: 6px;
  height: 10px;
}
.new-project-card ul li .arrow-blue {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
  background-position: -74px 0;
  width: 6px;
  height: 10px;
  float: right;
  margin-top: 3px;
}
@media (min-width:992px) and (max-width:1199px) {
  .new-project-card ul li {
    font-size: 1.2rem;
  }
}
.new-project-card .link {
  background-color: #ff580c;
  color: #fff;
  padding: 15px;
  display: block;
}
.new-project-card .link .arrow-white {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
  background-position: -479px 0;
  width: 6px;
  height: 10px;
  float: right;
  margin-top: 2px;
}
@media (min-width:992px) and (max-width:1199px) {
  .new-project-card .link {
    font-size: 1.2rem;
  }
}
@media (max-width:991px) {
  .new-project-card {
    display: none;
  }
}
.card-profile-infos {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #8d8d8d;
  background-color: #fff;
  border-radius: 2px;
  line-height: 2rem;
}
.card-profile-infos h2 {
  text-transform: uppercase;
  margin-bottom: 10px;
}
.card-profile-infos .edit-infos {
  float: right;
  padding: 10px 10px 7px;
  color: #05d8c6;
  border: 1px solid #05d8c6;
  border-radius: 3px;
}
.card-profile-infos .edit-infos i {
  font-size: 1.7rem;
}
.card-profile-infos .edit-infos:hover {
  color: #fff;
  background: #05d8c6;
}
.card-profile-infos .heading {
  font-weight: bold;
}
.card-profile-infos .mail {
  word-break: break-all;
}
.card-profile-infos a {
  color: #000;
  text-decoration: underline;
}
.card-profile-infos .link {
  color: #ff580c;
  text-decoration: none;
  margin-top: 10px;
  display: block;
}
.card-profile-infos .link .arrow-orange {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -50px -20px;
  width: 5px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -1px;
}
.card-profile-reviews {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #8d8d8d;
  background-color: #fff;
  border-radius: 2px;
  line-height: 2rem;
}
.card-profile-reviews h2 {
  text-transform: uppercase;
  margin-bottom: 10px;
}
.card-profile-reviews .rating-stars .rating-symbol {
  display: inline-block;
  position: relative;
}
.card-profile-reviews .rating-stars .rating-symbol .custom-star {
  color: #ffe066;
  font-size: 1.2em;
}
.card-profile-reviews .rating-stars .rating-symbol .fa-2x {
  font-size: 1.5em;
  margin: 10px 0 0;
}
.card-profile-reviews .rating-stars .rating-symbol .rating-symbol-background {
  visibility: hidden;
}
.card-profile-reviews .rating-stars .rating-symbol .rating-symbol-foreground {
  display: inline-block;
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  width: auto;
}
.card-profile-reviews .description {
  line-height: 18px;
  margin-top: 5px;
}
.card-profile-reviews .date {
  margin-left: 5px;
  font-weight: bold;
  color: #05d8c6;
}
.card-profile-reviews a {
  color: #ff580c;
  font-weight: bold;
}
.card-profile-reviews .response p {
  margin-top: 10px;
}
.card-profile-reviews .response .advice-answer {
  margin-left: 20px;
}
.card-confirm-cellphone {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #8d8d8d;
  background-color: #fff;
  border-radius: 2px;
  line-height: 2rem;
}
.card-confirm-cellphone h2 {
  text-transform: uppercase;
  margin-bottom: 10px;
}
.card-confirm-cellphone .bt-orange {
  font-size: 1.5rem;
  cursor: pointer;
}
.card-confirm-cellphone .ss-title-confirmation {
  margin-top: 10px;
}
.card-confirm-cellphone .ss-title-confirmation.bold span {
  display: block;
  font-weight: normal;
}
.card-cancel-project {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #8d8d8d;
  background-color: #fff;
  border-radius: 2px;
  line-height: 2rem;
}
.card-cancel-project .bt-orange {
  font-size: 1.5rem;
  cursor: pointer;
}
.card-cancel-project .form-control {
  height: 45px !important;
  border: 1px solid #979797;
  margin-bottom: 15px;
  font-size: 14px;
}
.card-cancel-project label {
  font-weight: bold;
}
.accountant-card-consumer img {
  width: 100%;
  margin-bottom: 20px;
}
@media (max-width:767px) {
  .accountant-card-consumer {
    display: none;
  }
}
.newsletter-main-title {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  font-size: 1.6rem;
}
.newsletter-main-title:first-of-type {
  font-size: 2rem;
}
.newsletter-main-title > span {
  margin-bottom: 1rem;
}
.newsletter-form {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin-bottom: 2rem;
}
.newsletter-form .horizontal-line {
  width: 95%;
  border-bottom: 0.1rem solid #d1d1d1;
}
.newsletter-form .newsletter-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 3rem 0;
}
.newsletter-form .newsletter-item .newsletter-content {
  margin: 0 1.6rem;
}
.newsletter-form .newsletter-item .newsletter-content .newsletter-title {
  display: inherit;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
}
.newsletter-form .newsletter-item .newsletter-content .newsletter-desc {
  display: inherit;
  font-size: 1.6rem;
  margin-top: 0.8rem;
}
.newsletter-form .newsletter-item .newsletter-checkbox {
  position: relative;
  min-width: 60px;
  height: 30px;
  margin: 0 1.8rem;
  border-radius: 3rem;
  background: #cccccc;
  outline: 0;
  appearance: none;
  cursor: pointer;
  transition: all 0.4s;
}
.newsletter-form .newsletter-item .newsletter-checkbox:checked {
  background: #fc5908;
}
.newsletter-form .newsletter-item .newsletter-checkbox::after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  display: block;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 2rem;
  transition: 0.4s;
}
.newsletter-form .newsletter-item .newsletter-checkbox:checked::after {
  left: calc(55%);
}
@media only screen and (max-width: 478px) {
  .newsletter-form {
    padding: 0;
  }
}
#numbers {
  margin-top: 5px;
  margin-bottom: 40px;
}
#numbers .icon {
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
  margin: 0 auto;
}
#numbers .icon.professionals {
  background-position: 0 0;
  width: 55px;
  height: 55px;
}
#numbers .icon.evaluations {
  background-position: -55px 0;
  width: 55px;
  height: 55px;
}
#numbers .icon.expert {
  background-position: -110px 0;
  width: 55px;
  height: 55px;
}
#numbers .heading {
  margin: 10px 0;
  text-align: center;
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  text-transform: uppercase;
  word-wrap: break-word;
  -o-hyphens: auto;
  hyphens: auto;
}
#numbers .heading .strong {
  font-weight: bold;
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
}
#numbers .text {
  text-align: center;
  display: none;
  line-height: 18px;
}
@media (min-width:768px) {
  #numbers .text {
    display: block;
  }
}
#numbers .mb-20 {
  margin-bottom: 20px;
}
#categories .grid-top {
  margin-top: -30px;
}
#categories .grid-top .object-repeater-container {
  float: left;
  width: 100%;
}
#categories .grid-top .object-repeater-container .cat-cell {
  padding: 30px 10px 0 10px;
}
#categories .grid-top .object-repeater-container .cat-cell .img-cat {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: table;
  height: 265px;
  width: 100%;
  cursor: pointer;
}
#categories .grid-top .object-repeater-container .cat-cell .img-cat .bg_opacity {
  background-color: rgba(0, 0, 0, 0.45);
  display: table-cell;
  height: 100%;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}
#categories .grid-top .object-repeater-container .cat-cell .img-cat .bg_opacity .txt {
  color: white;
  font-size: 1.7rem;
}
#categories .grid-top .object-repeater-container .cat-cell .img-cat .bg_opacity:hover {
  background-color: rgba(0, 0, 0, 0.7);
  transition: background-color 500ms linear;
}
#categories .grid-top .object-repeater-container .cat-cell .img-cat .bg_opacity:hover .positioner .button-container {
  bottom: -60px;
  /*.transition(bottom 250ms linear 0s);*/
}
#categories .grid-top .object-repeater-container .cat-cell .img-cat .bg_opacity:hover .positioner .button-container .button {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=(100));
  opacity: 1;
  transition: opacity 350ms linear;
}
#categories .grid-top .object-repeater-container .cat-cell .img-cat .bg_opacity .positioner {
  margin: 0 auto;
  position: relative;
  width: 100%;
}
#categories .grid-top .object-repeater-container .cat-cell .img-cat .bg_opacity .positioner .button-container {
  position: absolute;
  left: 0;
  bottom: -100px;
  width: 100%;
}
#categories .grid-top .object-repeater-container .cat-cell .img-cat .bg_opacity .positioner .button-container .button {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=(0));
  opacity: 0;
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
  margin: 0 auto;
  display: inline-block;
  padding: 10px 15px;
}
#categories .grid-top .object-repeater-container .cat-cell .img-cat .bg_opacity .positioner .button-container .button:hover {
  color: white;
  border: 1px solid #f64e01;
  background-color: #f64e01;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
#categories .section-button {
  margin-top: 42px;
  text-align: center;
  padding-bottom: 40px;
}
#categories .section-button .button {
  color: #ff580c;
  border: 1px solid #ff580c;
  background-color: transparent;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 20px;
}
#categories .section-button .button:hover {
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
.how-it-works {
  background-color: #27a3b2;
  padding-bottom: 40px;
  color: #fff;
}
.how-it-works h2 {
  margin: 30px 0;
  text-align: center;
  font-weight: bold;
  color: #fff !important;
}
.how-it-works .object-repeater-container {
  margin: 40px 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
@media (max-width:991px) {
  .how-it-works .object-repeater-container {
    flex-direction: column;
    gap: 20px;
  }
}
.how-it-works .object-repeater-container .object-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  gap: 12px;
}
@media (max-width:991px) {
  .how-it-works .object-repeater-container .object-cell {
    width: 100%;
    gap: 12px;
  }
}
.how-it-works .object-repeater-container .object-cell img {
  height: 70px;
}
.how-it-works .object-repeater-container .object-cell .heading {
  text-align: center;
  min-height: 60px;
  align-content: center;
}
.how-it-works .object-repeater-container .object-cell .text {
  text-align: center;
  font-size: 16px;
}
.how-it-works .object-repeater-container .object-cell-separator {
  display: none;
}
.how-it-works .section-button {
  margin-top: 42px;
  text-align: center;
  padding-bottom: 40px;
}
.how-it-works .section-button .button {
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
  font-size: 18px;
}
.how-it-works .section-button .button:hover {
  color: white;
  border: 1px solid #f64e01;
  background-color: #f64e01;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
#references .grid-top .object-repeater-container {
  float: left;
  width: 100%;
}
@media (max-width:991px) {
  #references .grid-top .object-repeater-container .object-cell:nth-child(n+4) .cell {
    display: none;
  }
}
#references .grid-top .object-repeater-container .cell .reference-quotation {
  background-color: #f0f0f0;
  border-radius: 3px 3px 0 0;
  background-clip: padding-box;
  border-color: #cecece;
  border-style: solid;
  border-width: 1px 1px 0;
  padding: 20px;
  width: 100%;
  height: 270px;
  text-align: justify;
}
@media (max-width:1199px) {
  #references .grid-top .object-repeater-container .cell .reference-quotation {
    height: 310px;
  }
}
@media (max-width:991px) {
  #references .grid-top .object-repeater-container .cell .reference-quotation {
    height: 310px;
  }
}
#references .grid-top .object-repeater-container .cell .reference-quotation .qmark {
  margin: 10px auto 0 auto;
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
  background-position: 0 -170px;
  width: 47px;
  height: 46px;
}
#references .grid-top .object-repeater-container .cell .reference-quotation .text {
  margin-top: 15px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #474747;
  font-style: italic;
}
@media (max-width:767px) {
  #references .grid-top .object-repeater-container .cell .reference-quotation .text {
    font-size: 1.5rem;
  }
}
#references .grid-top .object-repeater-container .arrow-separator-green .arrow {
  background-position: -110px 0;
  width: 33px;
  height: 22px;
}
#references .grid-top .object-repeater-container .reference-source {
  text-align: center;
  margin-top: 5px;
  line-height: 18px;
}
@media (max-width:767px) {
  #references .grid-top .object-repeater-container .reference-source {
    margin-bottom: 15px;
  }
}
#references .grid-top .object-repeater-container .reference-source strong {
  font-weight: bold;
}
#references .section-button {
  margin-top: 60px;
  text-align: center;
  padding-bottom: 40px;
}
#references .section-button .button {
  color: #ff580c;
  border: 1px solid #ff580c;
  background-color: transparent;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 20px;
}
#references .section-button .button:hover {
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
#references .section-title {
  margin: 60px 0;
}
#press .grid-top .object-repeater-container .logo-container {
  display: table;
}
@media (max-width:767px) {
  #press .grid-top .object-repeater-container .logo-container {
    height: 70px;
  }
}
#press .grid-top .object-repeater-container .logo-container .positioner {
  display: table-cell;
  vertical-align: middle;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo {
  background-image: url('/images/habitatpresto/sprites.png');
  background-repeat: no-repeat;
  margin: 30px auto 0 auto;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo.directmatin {
  background-position: 0 -64px;
  width: 109px;
  height: 26px;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo.marieclaire {
  background-position: -115px -62px;
  width: 114px;
  height: 31px;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo.rmc {
  background-position: -238px -63px;
  width: 81px;
  height: 34px;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo.maisonmagazine {
  background-position: -327px -62px;
  width: 71px;
  height: 39px;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo.telepoche {
  background-position: -404px -57px;
  width: 50px;
  height: 39px;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo.rustica {
  background-position: -458px -62px;
  width: 104px;
  height: 33px;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo.lofficiel {
  background-position: -564px -61px;
  width: 82px;
  height: 20px;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo.rtl {
  background-position: 0 -98px;
  width: 109px;
  height: 29px;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo.ecomaison {
  background-position: -116px -100px;
  width: 114px;
  height: 48px;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo.maisonapart {
  background-position: -651px -58px;
  width: 59px;
  height: 37px;
}
#press .section-button {
  margin-top: 60px;
  text-align: center;
  padding-bottom: 60px;
}
#press .section-button .button {
  color: #ff580c;
  border: 1px solid #ff580c;
  background-color: transparent;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 20px;
}
#press .section-button .button:hover {
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
#universes .grid-top {
  margin-top: -30px;
}
@media (max-width:767px) {
  #universes .grid-top {
    margin: 0 20px;
  }
}
@media (max-width:767px) {
  #universes .grid-top .object-cell:nth-child(n+6) .universe {
    display: none;
  }
}
@media (max-width:991px) {
  #universes .grid-top .object-cell:nth-child(n+8) .universe {
    display: none;
  }
}
#universes .grid-top .universe {
  position: relative;
  margin-top: 30px;
  float: left;
  height: 480px;
}
@media (max-width:767px) {
  #universes .grid-top .universe {
    height: auto;
    padding-bottom: 30px;
    border-top: 0;
    border-bottom: 1px solid #cecece;
    width: 100%;
  }
}
@media (max-width:767px) {
  #universes .grid-top .universe .content {
    height: 220px;
  }
}
#universes .grid-top .universe .content .img-container {
  float: left;
}
@media (max-width:767px) {
  #universes .grid-top .universe .content .img-container {
    position: absolute;
    top: 50px;
    left: 0;
    padding: 0;
  }
}
#universes .grid-top .universe .content .img-container .img-univers {
  height: 170px;
  width: 100%;
}
#universes .grid-top .universe .content .heading-container {
  float: left;
}
@media (max-width:767px) {
  #universes .grid-top .universe .content .heading-container {
    position: absolute;
    top: 0;
    left: 0;
  }
}
#universes .grid-top .universe .content .heading-container .heading {
  float: left;
  padding-bottom: 10px;
}
@media (max-width:767px) {
  #universes .grid-top .universe .content .heading-container .heading {
    width: 100%;
  }
}
@media (min-width:768px) {
  #universes .grid-top .universe .content .heading-container .heading {
    margin-top: 10px;
    border-top: 0;
    border-bottom: 1px solid #cecece;
    width: 100%;
  }
}
#universes .grid-top .universe .content .heading-container .heading .title {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  float: left;
}
@media (max-width:767px) {
  #universes .grid-top .universe .content .heading-container .heading .title {
    width: 100%;
    text-align: center;
  }
}
#universes .grid-top .universe .content .articles {
  float: left;
  height: 200px;
}
@media (min-width:768px) {
  #universes .grid-top .universe .content .articles {
    margin-top: 20px;
  }
}
@media (max-width:767px) {
  #universes .grid-top .universe .content .articles {
    float: right;
    top: 50px;
    right: 0;
    height: auto;
  }
}
#universes .grid-top .universe .content .articles li {
  margin-bottom: 10px;
}
@media (max-width:767px) {
  #universes .grid-top .universe .content .articles .item:nth-child(n+3) {
    display: none;
  }
}
#universes .grid-top .universe .content .articles .item .title {
  color: #727272;
  text-transform: uppercase;
}
#universes .grid-top .universe .content .articles .item .title a {
  color: #727272;
}
#universes .grid-top .universe .content .articles .item .text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  margin: 5px 0 10px;
  line-height: 18px;
  display: inline-block;
}
#universes .grid-top .universe .content .articles .item .text:hover {
  text-decoration: underline;
}
#universes .grid-top .universe .link {
  margin-top: 20px;
  color: #ff580c;
  float: right;
}
@media (max-width:767px) {
  #universes .grid-top .universe .link {
    margin-top: 40px;
  }
}
#universes .grid-top .universe .link:hover {
  text-decoration: underline;
}
#universes .grid-top .universe .link .arrow {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -50px -20px;
  width: 5px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
}
#universes .section-button {
  margin-top: 60px;
  text-align: center;
  padding-bottom: 60px;
}
#universes .section-button .button {
  color: #ff580c;
  border: 1px solid #ff580c;
  background-color: transparent;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 20px;
}
#universes .section-button .button:hover {
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
#cookies .heading {
  margin: 60px 0 30px 0;
  text-align: left;
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  text-transform: uppercase;
}
#cookies .text {
  text-align: justify;
  display: block;
  line-height: 24px;
}
#cookies .text a,
#cookies .text a:visited,
#cookies .text a:active {
  color: #ff580c;
}
#cookies .text a:hover {
  color: #ff580c;
  text-decoration: underline;
}
#cookies .text strong {
  font-weight: bold;
}
@media (min-width:768px) {
  #cookies .text {
    display: block;
  }
}
#legal .heading {
  margin: 60px 0 30px 0;
  text-align: left;
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  text-transform: uppercase;
}
#legal .text {
  text-align: justify;
  display: block;
  line-height: 24px;
}
#legal .text a,
#legal .text a:visited,
#legal .text a:active {
  color: #ff580c;
}
#legal .text a:hover {
  color: #ff580c;
  text-decoration: underline;
}
#legal .text strong {
  font-weight: bold;
}
@media (min-width:768px) {
  #legal .text {
    display: block;
  }
}
#legal .text.mb-60 {
  margin-bottom: 60px;
}
#benefits h1 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  text-align: center;
  margin-top: 30px;
  text-transform: uppercase;
}
#benefits .grid-top {
  margin-top: -40px;
}
#benefits .object-grid-container {
  padding-bottom: 40px;
}
#benefits .object-grid-container .object-row-container {
  margin-top: 40px;
}
#benefits .object-grid-container .object-row-container:nth-child(4n+1) > * {
  float: right;
}
#benefits .object-grid-container .object-row-container .object-cell {
  display: table;
  margin-top: 80px;
}
#benefits .object-grid-container .object-row-container .object-cell .image-cell {
  height: 290;
}
#benefits .object-grid-container .object-row-container .object-cell .image-cell img {
  display: block;
  margin: 0 auto;
}
#benefits .object-grid-container .object-row-container .object-cell .indexed-cell {
  height: 290;
  display: table-cell;
  vertical-align: middle;
}
#benefits .object-grid-container .object-row-container .object-cell .indexed-cell .positioner {
  position: relative;
}
#benefits .object-grid-container .object-row-container .object-cell .indexed-cell .positioner .index {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #05d8c6;
  text-align: center;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: white;
  line-height: 35px;
  position: absolute;
  font-weight: bold;
}
#benefits .object-grid-container .object-row-container .object-cell .indexed-cell .positioner .text {
  margin-left: 40px;
}
#benefits .object-grid-container .object-row-container .object-cell .indexed-cell .positioner .text .title h3 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  margin-left: 15px;
}
#benefits .object-grid-container .object-row-container .object-cell .indexed-cell .positioner .text ul {
  line-height: 30px;
}
#benefits .object-grid-container .object-row-container .object-cell .indexed-cell .positioner .text ul li {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  background-image: url('/images/habitatpresto/fleche-noire.png');
  background-repeat: no-repeat;
  background-position: 0 10px;
  padding: 0 20px;
  margin-left: 13px;
  line-height: 30px;
  margin-top: 15px;
}
#benefits .object-grid-container .object-row-container .object-cell .indexed-cell .positioner .text ul li strong {
  font-weight: bold;
}
#benefits .section-title h2 {
  font-weight: bold !important;
}
.reassurances {
  margin: 10px 0;
}
.reassurances .object-repeater-container {
  margin-top: 30px;
  width: 100%;
  float: left;
}
.reassurances .object-repeater-container .positioner {
  text-align: center;
}
@media (max-width:767px) {
  .reassurances .object-repeater-container .positioner {
    margin-bottom: 15px;
  }
}
.reassurances .object-repeater-container .positioner .icon {
  display: inline-block;
  background-image: url('/images/habitatpresto/sprite-general.png');
  background-repeat: no-repeat;
  margin-right: 20px;
  vertical-align: top;
}
.reassurances .object-repeater-container .positioner .icon.time {
  background-position: -169px 0;
  width: 41px;
  height: 41px;
}
.reassurances .object-repeater-container .positioner .icon.free {
  background-position: -211px 0;
  width: 41px;
  height: 41px;
}
.reassurances .object-repeater-container .positioner .icon.satisfaction {
  background-position: -252px 0;
  width: 41px;
  height: 41px;
}
.reassurances .object-repeater-container .positioner .text {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  line-height: 40px;
  text-transform: uppercase;
  color: #ff580c;
}
@media (min-width:768px) {
  .reassurances .object-repeater-container .positioner .text {
    display: inline-block;
  }
}
.reassurances .section-button {
  margin-top: 60px;
  text-align: center;
  padding-bottom: 60px;
}
.reassurances .section-button .button {
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
  font-size: 18px;
}
.reassurances .section-button .button:hover {
  color: white;
  border: 1px solid #f64e01;
  background-color: #f64e01;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
.image-cell {
  display: table;
  height: 100%;
  table-layout: fixed;
}
.image-cell:not(.free-width) {
  width: 100%;
}
.image-cell .image-positioner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 10px;
}
.image-cell .image-positioner img {
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  height: auto;
}
.image-cell .image-positioner .rounded-full {
  border-radius: 999rem;
}
.object-grid-container.image-content .object-row-container {
  padding: 0;
}
.object-grid-container.image-content .object-row-container .object-cell {
  padding: 0;
  height: 290px;
}
@media (min-width:992px) {
  .object-grid-container.image-content .object-row-container .object-cell {
    height: 243px;
  }
}
@media (min-width:1200px) {
  .object-grid-container.image-content .object-row-container .object-cell {
    height: 290px;
  }
}
@media (min-width:768px) and (max-width:991px) {
  .object-grid-container.image-content .object-row-container:nth-child(4n+3) .object-cell {
    float: right;
  }
}
@media (min-width:992px) {
  .object-grid-container.image-content .object-row-container:nth-child(8n+5) .object-cell,
  .object-grid-container.image-content .object-row-container:nth-child(8n+7) .object-cell {
    float: right;
  }
}
.partners .contact {
  text-align: center;
  margin: 30px 0;
}
.partners .object-grid-container .object-row-container .object-cell {
  padding: 2px;
}
.partners .object-grid-container .object-row-container .object-cell .content {
  position: relative;
  height: 288px;
  padding-top: 25px;
}
.partners .object-grid-container .object-row-container .object-cell .content .title {
  text-align: center;
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
.partners .object-grid-container .object-row-container .object-cell .content .text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  position: absolute;
  top: 85px;
  left: 0;
  padding: 0 25px;
}
.partners .object-grid-container .object-row-container .object-cell .content .lien {
  float: right;
  position: absolute;
  bottom: 25px;
  right: 25px;
}
.partners .object-grid-container .object-row-container .object-cell .content .lien a {
  color: #ff580c;
}
.partners .object-grid-container .object-row-container .object-cell .content .lien .arrow {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -50px -20px;
  width: 5px;
  height: 8px;
  display: inline-block;
  margin-left: 10px;
}
@media (min-width:992px) and (max-width:1199px) {
  .partners .object-grid-container .object-row-container .object-cell .content {
    height: 241px;
    padding-top: 15px;
  }
  .partners .object-grid-container .object-row-container .object-cell .content .text {
    padding: 0 15px;
    top: 70px;
  }
  .partners .object-grid-container .object-row-container .object-cell .content .lien {
    bottom: 15px;
    right: 15px;
  }
}
.partners .object-grid-container .object-row-container .object-cell:first-child {
  background-color: #f0f0f0;
}
@media (min-width:992px) {
  .partners .object-grid-container .object-row-container:hover {
    border: 2px solid #05d8c6;
  }
  .partners .object-grid-container .object-row-container:hover .object-cell {
    height: 239px;
    padding: 0;
  }
  .partners .object-grid-container .object-row-container:hover .object-cell:last-child {
    padding-left: 2px;
  }
  .partners .object-grid-container .object-row-container:hover .object-cell:first-child {
    background-color: #05d8c6;
    padding-right: 2px;
  }
}
@media (min-width:1200px) {
  .partners .object-grid-container .object-row-container:hover .object-cell {
    height: 286px;
  }
}
@media (min-width:992px) {
  .partners .object-grid-container .object-row-container:hover:nth-child(8n+5) .object-cell:last-child,
  .partners .object-grid-container .object-row-container:hover:nth-child(8n+7) .object-cell:last-child {
    padding-right: 2px;
    padding-left: 0;
  }
  .partners .object-grid-container .object-row-container:hover:nth-child(8n+5) .object-cell:first-child,
  .partners .object-grid-container .object-row-container:hover:nth-child(8n+7) .object-cell:first-child {
    padding-left: 2px;
    padding-right: 0;
  }
}
.partners .mt-40 {
  margin-top: 40px;
}
.partners .ss-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  text-align: justify;
}
@media (max-width:767px) {
  .partners .ss-title {
    padding: 0 20px;
  }
}
.partners .section-button {
  margin-top: 60px;
  text-align: center;
  padding-bottom: 60px;
}
.partners .section-button .button {
  color: #ff580c;
  border: 1px solid #ff580c;
  background-color: transparent;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 20px;
}
.partners .section-button .button:hover {
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
.presse .contact {
  line-height: 2.2rem;
  margin: 30px 0;
}
.presse .object-grid-container .object-row-container .object-cell {
  padding: 2px;
}
.presse .object-grid-container .object-row-container .object-cell .content {
  position: relative;
  height: 288px;
  padding-top: 20px;
}
.presse .object-grid-container .object-row-container .object-cell .content .title {
  text-align: center;
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
.presse .object-grid-container .object-row-container .object-cell .content .content-text {
  top: 65px;
  height: 100px;
  position: absolute;
  left: 0;
  padding: 0 20px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
}
.presse .object-grid-container .object-row-container .object-cell .content .content-text .text {
  padding-top: 10px;
}
.presse .object-grid-container .object-row-container .object-cell .content .lien {
  float: right;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.presse .object-grid-container .object-row-container .object-cell .content .lien a {
  color: #ff580c;
}
.presse .object-grid-container .object-row-container .object-cell .content .lien .arrow {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -50px -20px;
  width: 5px;
  height: 8px;
  display: inline-block;
  margin-left: 10px;
}
@media (min-width:992px) and (max-width:1199px) {
  .presse .object-grid-container .object-row-container .object-cell .content {
    height: 241px;
    padding-top: 15px;
  }
  .presse .object-grid-container .object-row-container .object-cell .content .content-text {
    padding: 0 10px;
    top: 60px;
  }
  .presse .object-grid-container .object-row-container .object-cell .content .lien {
    bottom: 10px;
    right: 10px;
  }
}
.presse .object-grid-container .object-row-container .object-cell:first-child {
  background-color: #f0f0f0;
}
@media (min-width:992px) {
  .presse .object-grid-container .object-row-container:hover {
    border: 2px solid #05d8c6;
  }
  .presse .object-grid-container .object-row-container:hover .object-cell {
    height: 239px;
    padding: 0;
  }
  .presse .object-grid-container .object-row-container:hover .object-cell:last-child {
    padding-left: 2px;
  }
  .presse .object-grid-container .object-row-container:hover .object-cell:first-child {
    background-color: #05d8c6;
    padding-right: 2px;
  }
}
@media (min-width:1200px) {
  .presse .object-grid-container .object-row-container:hover .object-cell {
    height: 286px;
  }
}
@media (min-width:992px) {
  .presse .object-grid-container .object-row-container:hover:nth-child(8n+5) .object-cell:last-child,
  .presse .object-grid-container .object-row-container:hover:nth-child(8n+7) .object-cell:last-child {
    padding-right: 2px;
    padding-left: 0;
  }
  .presse .object-grid-container .object-row-container:hover:nth-child(8n+5) .object-cell:first-child,
  .presse .object-grid-container .object-row-container:hover:nth-child(8n+7) .object-cell:first-child {
    padding-left: 2px;
    padding-right: 0;
  }
}
.presse .mt-40 {
  margin-top: 40px;
}
.presse .ss-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  text-align: justify;
}
@media (max-width:767px) {
  .presse .ss-title {
    padding: 0 20px;
  }
}
.presse .section-button {
  margin-top: 60px;
  text-align: center;
  padding-bottom: 60px;
}
.presse .section-button .button {
  color: #ff580c;
  border: 1px solid #ff580c;
  background-color: transparent;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 20px;
}
.presse .section-button .button:hover {
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
.testimonies-list {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  margin: 3rem 0;
}
@media (max-width:575px) {
  .testimonies-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
.testimonies-list .testimony {
  background-color: #f0f0f0;
  border-radius: 1rem;
  padding: 1.5rem;
}
.testimonies-list .testimony .testimony-header {
  display: flex;
  justify-content: space-between;
}
.testimonies-list .testimony .testimony-header .star-gold {
  color: #ff8910;
}
.testimonies-list .testimony .testimony-content {
  margin: 1.5rem 0;
  text-align: justify;
}
.testimonies-list .testimony .testimony-content .category {
  font-weight: bold;
  color: #ff580c;
  margin: 1.5rem 0;
}
.testimonies-page-description-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 5rem 0;
}
.testimonies-page-description-container .image {
  display: flex;
  justify-content: center;
  width: 50%;
  margin-bottom: 5rem;
}
@media (max-width:767px) {
  .testimonies-page-description-container .image {
    width: 100%;
  }
}
.testimonies-page-description-container .image img {
  height: auto;
  max-width: 100%;
  margin: auto;
}
.testimonies-page-description-container .content {
  max-width: 50%;
}
@media (max-width:767px) {
  .testimonies-page-description-container .content {
    max-width: 100%;
  }
}
.testimonies-page-description-container .content h2 {
  display: inline-block;
  border-bottom: 2px solid #05d8c6;
}
.testimonies-page-description-container .content p {
  text-align: justify;
  margin: 2rem 0;
  line-height: 3rem;
  font-size: 1.8rem;
}
.directory-category-description {
  background-color: #fff;
}
.directory-category-description .sprite-casque {
  background-position: -115px -277px;
  width: 117px;
  height: 118px;
  background-image: url('/images/habitatpresto/sprites.png');
  background-repeat: no-repeat;
}
.directory-category-description .title-presentation {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  text-transform: uppercase;
  font-weight: bold;
}
.directory-category-description p {
  line-height: 2rem;
  text-align: justify;
  padding: 0 20px 30px 20px;
}
.quotation-form-description .category-description {
  font-size: 16px;
  line-height: 3rem;
}
.quotation-form-description .content-wrapper {
  padding: 30px;
  margin: 20px auto;
}
.quotation-form-description .content-wrapper h2 {
  font-size: 24px;
  color: #333;
  font-weight: 800;
  margin: 20px 0;
}
.quotation-form-description .content-wrapper h3 {
  font-size: 20px;
  color: #333;
  font-weight: 700;
  margin: 20px 0;
}
.quotation-form-description .content-wrapper p {
  font-size: 16px;
  color: #444;
  line-height: 26px;
  margin-bottom: 20px;
}
.quotation-form-description .content-wrapper blockquote {
  font-size: 1em;
  color: #555;
  padding: 10px 20px;
  border-left: 5px solid #007bff;
  background: #f9f9f9;
  margin-bottom: 20px;
}
.quotation-form-description .content-wrapper .example-devis {
  font-size: 1.1em;
  color: #007bff;
  font-weight: bold;
  background: #eef5ff;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
}
.quotation-form-description .maillage-interne {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
}
.quotation-form-description .maillage-interne h3 {
  font-size: 2em;
  color: #333;
  font-weight: 700;
  margin-bottom: 20px;
}
.quotation-form-description .maillage-interne ul {
  list-style-type: none;
  padding: 0;
}
.quotation-form-description .maillage-interne ul li {
  margin-bottom: 10px;
}
.quotation-form-description .maillage-interne ul li a {
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}
.quotation-form-description .maillage-interne ul li a:hover {
  color: #0056b3;
  text-decoration: underline;
}
.micro-map {
  margin-top: 40px;
}
@media (max-width:575px) {
  .micro-map .section-title {
    margin: 70px 0 30px !important;
  }
}
.micro-map .section-title h2 {
  text-transform: uppercase;
  text-align: center;
  font-size: 3.6rem;
  line-height: 3.6rem;
}
.micro-map .section-title h2.text-left {
  text-align: left;
}
.micro-map .list-dep {
  line-height: 24px;
  margin-top: 10px;
}
.micro-map .list-dep li {
  list-style-type: disc;
  margin-left: 50px;
}
.micro-map .list-dep li .orange {
  color: #ff580c;
}
.micro-map .list-dep li .orange:hover,
.micro-map .list-dep li .orange:focus,
.micro-map .list-dep li .orange:active {
  text-decoration: underline;
}
#annuaire-category .title {
  font-size: 3.6rem;
  line-height: 3.6rem;
  text-transform: uppercase;
  color: #474747;
  text-align: center;
}
#annuaire-category .ss-title-region {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}
#annuaire-category .sprite-time {
  background-position: 0 -274px;
  width: 117px;
  height: 117px;
  background-image: url('/images/habitatpresto/sprites.png');
  background-repeat: no-repeat;
}
@media (max-width:575px) {
  #annuaire-category .section-title {
    margin: 70px 0 30px !important;
  }
}
#annuaire-category .section-title h2 {
  text-transform: uppercase;
  text-align: center;
  font-size: 3.6rem;
  line-height: 3.6rem;
}
#annuaire-category .section-title h2.text-left {
  text-align: left;
}
@media (min-width:768px) and (max-width:991px) {
  #annuaire-category #stat-metier .section-title h2 {
    padding-top: 3rem;
  }
}
#annuaire-category .fa-search {
  box-shadow: rgba(0, 0, 0, 0.4) 0 2px 5px;
  width: 50px;
  height: 50px;
  display: block;
  padding: 16px 0;
  position: absolute;
  top: 0;
  right: 25px;
  line-height: 1;
  text-align: center;
  background-color: #ff580c;
  color: #fff;
  background-clip: padding-box;
  border-radius: 50%;
  font-size: 2rem;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  -transition-duration: 0.3s;
  transition-duration: 0.3s;
}
#annuaire-category .fa-search:hover {
  color: #fff;
  box-shadow: 0 25px 55px 0 rgba(0, 0, 0, 0.21), 0 16px 28px 0 rgba(0, 0, 0, 0.22);
  transition: all 0.2s ease-out;
}
@media (max-width:767px) {
  #annuaire-category .fa-search {
    right: 15px;
    width: 40px;
    height: 40px;
    line-height: 1.2rem;
  }
}
@media (max-width:575px) {
  #annuaire-category .fa-search {
    top: -50px;
  }
}
#annuaire-category .blue {
  color: #27a3b2;
}
#annuaire-category .bt-orange {
  color: #fff;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
  font-size: 18px;
}
#annuaire-category .bt-orange:hover {
  background-color: #f64e01;
}
#annuaire-category #form-annuaire {
  display: none;
}
#annuaire-category #form-annuaire .container > .row h1 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: #474747;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;
  padding-top: 8rem;
}
@media (max-width:767px) {
  #annuaire-category #form-annuaire .container > .row h1 {
    padding-top: 7rem;
    font-size: 3.8rem;
  }
}
#annuaire-category #form-annuaire .container > .row h1 .fa-times {
  box-shadow: rgba(0, 0, 0, 0.4) 0 2px 5px;
  width: 50px;
  height: 50px;
  display: block;
  padding: 15px 0;
  position: absolute;
  right: 80px;
  top: 130px;
  line-height: 1;
  text-align: center;
  background-color: #ff580c;
  color: #fff;
  background-clip: padding-box;
  border-radius: 50%;
  font-size: 2rem;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  -transition-duration: 0.3s;
  transition-duration: 0.3s;
}
#annuaire-category #form-annuaire .container > .row h1 .fa-times:hover {
  color: #fff;
  box-shadow: 0 25px 55px 0 rgba(0, 0, 0, 0.21), 0 16px 28px 0 rgba(0, 0, 0, 0.22);
  transition: all 0.2s ease-out;
}
@media (max-width:767px) {
  #annuaire-category #form-annuaire .container > .row h1 .fa-times {
    right: 15px;
    width: 32px;
    height: 32px;
    line-height: 1rem;
    top: 80px;
    padding: 12px 0;
  }
}
#annuaire-category #form-annuaire .container > .row form {
  margin: 30px 0;
  background-color: rgba(7, 26, 33, 0.8);
  padding: 10px 10px 10px 0;
  width: 100%;
  height: 100%;
  display: table;
}
@media (max-width:767px) {
  #annuaire-category #form-annuaire .container > .row form {
    padding: 0 10px 10px 10px;
  }
}
#annuaire-category .fa-2x {
  font-size: 1.2em;
  margin: 10px 3px 0;
}
.annuaire-link-category {
  margin-bottom: 60px;
}
.annuaire-link-category .orange {
  color: #ff580c;
}
.annuaire-link-category a.orange,
.annuaire-link-category a.orange:active,
.annuaire-link-category a.orange:visited {
  color: #ff580c;
  text-decoration: none;
}
.annuaire-link-category a.orange:hover,
.annuaire-link-category a.orange:active:hover,
.annuaire-link-category a.orange:visited:hover {
  color: #ff580c;
  text-decoration: underline;
}
.annuaire-link-category ul {
  line-height: 24px;
}
.annuaire-link-category .ml-15 {
  margin-left: 15px;
}
.annuaire-link-metier {
  background-color: #fff;
}
.annuaire-link-metier.grey-wapper {
  background-color: #f0f0f0;
}
.annuaire-link-metier .section-title {
  margin: 30px 0;
}
.annuaire-link-metier .section-title .title {
  font-size: 3.6rem;
  line-height: 3.6rem;
  text-transform: uppercase;
  color: #474747;
  text-align: center;
}
.annuaire-link-metier a {
  color: #474747;
  text-decoration: none;
}
.annuaire-link-metier a:hover,
.annuaire-link-metier a:active,
.annuaire-link-metier a:focus {
  color: #474747;
  text-decoration: underline;
}
.annuaire-link-metier p {
  line-height: 2.2rem;
}
.annuaire-link-metier p.text {
  font-size: 1.6rem;
  color: #474747;
  padding: 10px;
}
.annuaire-link-metier ul {
  line-height: 2.2rem;
}
.annuaire-link-metier ul li.ml-15 {
  margin-left: 15px;
}
.annuaire-link-metier .category-list {
  border-right: 1px solid #bfbfbf;
}
@media screen and (max-width:767px) {
  .annuaire-link-metier .category-list {
    border-right: none;
  }
}
.annuaire-link-metier .category-list:last-of-type {
  border-right: none;
}
.annuaire-link-metier .category-list ul {
  line-height: 2.2rem;
}
@media screen and (max-width:767px) {
  .annuaire-link-metier .category-list ul li {
    text-align: center;
  }
}
@media screen and (max-width:767px) {
  .annuaire-link-metier .category-list ul {
    margin-bottom: 20px;
  }
}
.annuaire-link-metier .category-list a {
  color: #474747;
  text-decoration: none;
}
.annuaire-link-metier .category-list a:hover,
.annuaire-link-metier .category-list a:active,
.annuaire-link-metier .category-list a:focus {
  color: #474747;
  text-decoration: underline;
}
.annuaire-link-metier .category-list a.read-more {
  color: #ff580c;
  text-decoration: underline;
}
.annuaire-link-metier .category-list a.read-more .arrow-link {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -50px -20px;
  width: 5px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
}
.annuaire-link-metier .category-list a.read-more .arrow-link:hover,
.annuaire-link-metier .category-list a.read-more .arrow-link:active,
.annuaire-link-metier .category-list a.read-more .arrow-link:focus {
  color: #ff580c;
  text-decoration: none;
}
#annuaire-fiche-pro {
  line-height: 18px;
  /**********modal****************/
  /*********accordeon************/
}
#annuaire-fiche-pro .wrapper-breadcrumb {
  background-color: #ffffff;
}
#annuaire-fiche-pro .wrapper-grey {
  background-color: #f0f0f0;
}
#annuaire-fiche-pro .mt-60 {
  margin-top: 60px;
}
#annuaire-fiche-pro .mt-10 {
  margin-top: 10px;
}
#annuaire-fiche-pro .mt-20 {
  margin-top: 20px;
}
#annuaire-fiche-pro .mt-30 {
  margin-top: 30px;
}
#annuaire-fiche-pro .mt-5 {
  margin-top: 5px;
}
#annuaire-fiche-pro .mb-30 {
  margin-bottom: 30px;
}
#annuaire-fiche-pro .no-padding {
  padding: 0;
}
#annuaire-fiche-pro .ss-title {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
#annuaire-fiche-pro .title {
  font-size: 3.6rem;
  line-height: 3.6rem;
  text-transform: uppercase;
  color: #474747;
  text-align: center;
}
#annuaire-fiche-pro .list-dep {
  line-height: 24px;
}
#annuaire-fiche-pro .orange {
  color: #ff580c;
}
#annuaire-fiche-pro a.orange,
#annuaire-fiche-pro a.orange:active,
#annuaire-fiche-pro a.orange:visited {
  color: #ff580c;
  text-decoration: none;
}
#annuaire-fiche-pro a.orange:hover,
#annuaire-fiche-pro a.orange:active:hover,
#annuaire-fiche-pro a.orange:visited:hover {
  color: #ff580c;
  text-decoration: underline;
}
#annuaire-fiche-pro .green {
  color: #02c4b3;
}
#annuaire-fiche-pro a.green,
#annuaire-fiche-pro a.green:active,
#annuaire-fiche-pro a.green:visited {
  color: #02c4b3;
  text-decoration: none;
}
#annuaire-fiche-pro a.green:hover,
#annuaire-fiche-pro a.green:active:hover,
#annuaire-fiche-pro a.green:visited:hover {
  color: #02c4b3;
  text-decoration: underline;
}
#annuaire-fiche-pro .fleche-grise {
  background-position: -50px -29px;
  width: 5px;
  height: 10px;
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 10px;
}
#annuaire-fiche-pro .title-presentation {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  text-transform: uppercase;
  font-weight: bold;
}
#annuaire-fiche-pro .title-rubrique {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  text-transform: uppercase;
  margin-top: 10px;
}
#annuaire-fiche-pro .sprite-info {
  background-position: -240px -336px;
  width: 24px;
  height: 23px;
  background-image: url('/images/habitatpresto/sprites.png');
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 10px;
}
#annuaire-fiche-pro .sprite-doc {
  background-position: -240px -367px;
  width: 23px;
  height: 23px;
  background-image: url('/images/habitatpresto/sprites.png');
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 10px;
}
#annuaire-fiche-pro .sprite-avis {
  background-position: -277px -352px;
  width: 22px;
  height: 19px;
  background-image: url('/images/habitatpresto/sprites.png');
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 10px;
}
#annuaire-fiche-pro .sprite-photo {
  background-position: -276px -281px;
  width: 23px;
  height: 18px;
  background-image: url('/images/habitatpresto/sprites.png');
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 10px;
}
#annuaire-fiche-pro .sprite-label {
  background-position: -276px -313px;
  width: 24px;
  height: 28px;
  background-image: url('/images/habitatpresto/sprites.png');
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}
#annuaire-fiche-pro .sprite-fleche-blanche {
  background-position: -359px -200px;
  width: 8px;
  height: 13px;
  background-image: url('/images/habitatpresto/sprites.png');
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  vertical-align: middle;
}
#annuaire-fiche-pro .no-margin {
  margin-right: 0;
  margin-left: 0;
}
#annuaire-fiche-pro .block-avis-client .avis-client {
  padding-bottom: 20px;
  border-bottom: 1px solid #727272;
  line-height: 18px;
}
#annuaire-fiche-pro .block-avis-client p {
  line-height: 18px;
}
#annuaire-fiche-pro .workcompleted {
  text-transform: uppercase;
  background-color: #dff0d8;
  border: 1px solid #daeccd;
  color: #3c763d;
  padding: 8px;
  margin-top: -10px;
}
#annuaire-fiche-pro .workinprogress {
  text-transform: uppercase;
  background-color: #d9edf7;
  border: 1px solid #c8eaf3;
  color: #3a87ad;
  padding: 8px;
  margin-top: -10px;
}
#annuaire-fiche-pro .nocontact {
  text-transform: uppercase;
  background-color: #dddddd;
  border: 1px solid #c2c2c2;
  color: #828282;
  padding: 8px;
  margin-top: -10px;
}
#annuaire-fiche-pro .visitscheduled {
  text-transform: uppercase;
  background-color: #ffedbc;
  border: 1px solid #fed973;
  color: #c19211;
  padding: 8px;
  margin-top: -10px;
}
#annuaire-fiche-pro .estimatereceived {
  text-transform: uppercase;
  background-color: #ffd6c4;
  border: 1px solid #ffbea0;
  color: #ff580c;
  padding: 8px;
  margin-top: -10px;
}
#annuaire-fiche-pro .reponse-artisan {
  background-color: #f8f8f8;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #727272;
}
#annuaire-fiche-pro .reponse-artisan ul {
  margin: 15px 30px 30px 30px;
  line-height: 24px;
}
#annuaire-fiche-pro .pointe-bulle {
  background-image: url('/images/habitatpresto/pointe-avis.jpg');
  background-repeat: no-repeat;
  background-position: 80px 0;
  height: 19px;
  display: block;
  margin-top: -1px;
}
#annuaire-fiche-pro .info-entreprise {
  padding: 10px 10px 30px 10px;
}
#annuaire-fiche-pro .info-entreprise p {
  line-height: 24px;
  text-align: justify;
}
#annuaire-fiche-pro .info-entreprise .reviews-section .review-title {
  color: #05d8c6;
  font-weight: bold;
}
#annuaire-fiche-pro .info-entreprise .reviews-section p {
  text-align: center;
}
#annuaire-fiche-pro .info-entreprise .review-badge {
  width: 80px;
  height: 80px;
}
#annuaire-fiche-pro .info-entreprise li {
  float: left;
  margin-right: 5px;
}
#annuaire-fiche-pro .block-photo {
  padding: 15px;
  overflow: auto;
}
#annuaire-fiche-pro .block-photo-chantier {
  overflow: hidden;
  height: 200px;
}
#annuaire-fiche-pro .block-photo-chantier p {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  height: 200px;
  padding: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  transition: margin-top 0.2s ease-out;
}
#annuaire-fiche-pro .block-photo-chantier:hover p {
  margin-top: -200px;
}
#annuaire-fiche-pro .photo-chantier {
  /* pour anciens Chrome et Safari */
  background-size: cover;
  /* version standardisée */
  width: 100%;
  height: 200px;
  border: 1px solid #c5c5c5;
}
#annuaire-fiche-pro .img-label {
  border: 1px solid #c5c5c5;
  width: 100%;
  height: 200px;
}
#annuaire-fiche-pro .block-photo-label {
  overflow: hidden;
  height: 200px;
}
#annuaire-fiche-pro .block-photo-label .def-label {
  height: 200px;
  padding: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  transition: margin-top 0.2s ease-out;
  text-align: justify;
  line-height: 18px;
}
#annuaire-fiche-pro .block-photo-label .def-label h4 {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  color: #FFF;
}
#annuaire-fiche-pro .bt-orange {
  color: white;
  border: 1px solid #ff580c;
  background-color: #ff580c;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  padding: 10px 20px;
  font-size: 18px;
}
#annuaire-fiche-pro .bt-orange:hover {
  background-color: #f64e01;
}
#annuaire-fiche-pro .link {
  margin-top: 15px;
  text-align: right;
  margin-right: 20px;
}
#annuaire-fiche-pro .link a {
  color: #ff580c;
  text-decoration: none;
}
#annuaire-fiche-pro .link a:hover {
  color: #ff580c;
  text-decoration: underline;
}
#annuaire-fiche-pro .link a:active,
#annuaire-fiche-pro .link a:visited {
  color: #ff580c;
}
#annuaire-fiche-pro .arrow-link {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -50px -20px;
  width: 5px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
}
#annuaire-fiche-pro .block-photo-label:hover .def-label {
  margin-top: -200px;
}
#annuaire-fiche-pro .block-info-artisan .photo-artisan {
  width: 115px;
  height: 115px;
  border-radius: 50%;
  border: 2px solid #FFF;
  background-image: url('/images/habitatpresto/photo-artisan.jpg');
  /* pour anciens Chrome et Safari */
  background-size: cover;
  /* version standardisée */
  -o-box-shadow: 0 1px 5px 0 #656565;
  box-shadow: 0 1px 5px 0 #656565;
}
#annuaire-fiche-pro .block-info-artisan .directory-badge {
  width: 50px;
  height: 50px;
  margin-left: 10px;
  display: inline-block;
}
#annuaire-fiche-pro .block-info-artisan .job {
  font-size: 1.8rem;
  margin-top: 10px;
  margin-bottom: 10px;
}
#annuaire-fiche-pro .block-info-artisan .mt-40 {
  margin-top: 40px;
}
#annuaire-fiche-pro .competence li {
  line-height: 24px;
  list-style-type: disc;
  margin-left: 20px;
}
#annuaire-fiche-pro .competence h2 {
  font-size: 1.4rem;
  line-height: 18px;
  font-weight: bold;
}
#annuaire-fiche-pro .competence .list-competence {
  font-size: 1.4rem !important;
  line-height: 18px;
}
#annuaire-fiche-pro .block-favoris {
  color: #02c4b3;
}
#annuaire-fiche-pro .block-favoris .sprite-favoris {
  background-position: -242px -277px;
  width: 23px;
  height: 20px;
  background-image: url('/images/habitatpresto/sprites.png');
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
}
#annuaire-fiche-pro .block-message-pro {
  background-color: #28a3b2;
  padding: 15px;
  color: #FFF;
  line-height: 18px;
}
#annuaire-fiche-pro .block-message-pro a,
#annuaire-fiche-pro .block-message-pro a:hover,
#annuaire-fiche-pro .block-message-pro a:active,
#annuaire-fiche-pro .block-message-pro a:visited {
  color: #FFF;
  text-decoration: underline;
}
#annuaire-fiche-pro .sprite-fleche-bas {
  background-image: url('/images/habitatpresto/sprites.png');
  background-repeat: no-repeat;
  background-position: -335px -26px;
  width: 11px;
  height: 7px;
  margin-left: 5px;
  display: inline-block;
}
#annuaire-fiche-pro .chemin {
  text-transform: uppercase;
  line-height: 24px;
}
#annuaire-fiche-pro .chemin a,
#annuaire-fiche-pro .chemin a:active,
#annuaire-fiche-pro .chemin a:visited {
  color: #474747;
  text-decoration: none;
}
#annuaire-fiche-pro .chemin a:hover,
#annuaire-fiche-pro .chemin a:active:hover,
#annuaire-fiche-pro .chemin a:visited:hover {
  color: #474747;
  text-decoration: underline;
}
#annuaire-fiche-pro .arrow-grey {
  display: inline-block;
  background-position: -50px 0;
  width: 6px;
  height: 9px;
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
}
#annuaire-fiche-pro a.lien-simple,
#annuaire-fiche-pro a.lien-simple:active,
#annuaire-fiche-pro a.lien-simple:visited {
  color: #474747;
  text-decoration: none;
}
#annuaire-fiche-pro a.lien-simple:hover,
#annuaire-fiche-pro a.lien-simple:active:hover,
#annuaire-fiche-pro a.lien-simple:visited:hover {
  text-decoration: underline;
}
#annuaire-fiche-pro .ddd-3-steps {
  background-color: #05d8c6;
  padding: 20px;
}
#annuaire-fiche-pro .ddd-3-steps h2 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  color: #FFF;
  text-transform: uppercase;
  font-weight: bold;
}
#annuaire-fiche-pro .ddd-3-steps h3 {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  color: #FFF;
  font-weight: bold;
}
#annuaire-fiche-pro .ddd-3-steps select,
#annuaire-fiche-pro .ddd-3-steps input {
  height: 44px;
  font-size: 18px;
}
#annuaire-fiche-pro .ddd-3-steps .bt-green {
  background-color: #02c4b3;
  border: 1px solid #FFF;
  border-radius: 3px;
  color: #FFF;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.8rem;
  padding: 10px 0;
  height: 44px;
  width: 100%;
}
#annuaire-fiche-pro .ddd-3-steps .bt-green:hover {
  background-color: #02ae9f;
}
#annuaire-fiche-pro .ddd-3-steps p {
  color: #FFF;
  line-height: 18px;
  text-align: justify;
}
#annuaire-fiche-pro .empty {
  color: #727272;
}
#annuaire-fiche-pro .modal-title {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
#annuaire-fiche-pro .panel {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
}
#annuaire-fiche-pro .panel-default {
  border-color: #fff;
}
#annuaire-fiche-pro .panel-heading {
  padding: 10px 0;
}
#annuaire-fiche-pro .panel-default > .panel-heading {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}
#annuaire-fiche-pro .panel-title {
  font-size: 1.4rem;
  color: #f64e01;
}
#annuaire-fiche-pro .panel-body {
  padding: 0;
}
#annuaire-fiche-pro .panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #FFF;
}
#annuaire-fiche-pro .rating-symbol {
  display: inline;
  position: relative;
}
#annuaire-fiche-pro .rating-symbol .fa-2x {
  font-size: 1.8em;
  margin-right: 2px;
}
#annuaire-fiche-pro .rating-symbol .fa-1x {
  font-size: 1.5em;
}
#annuaire-fiche-pro .rating-symbol .custom-star {
  color: #ffe066;
}
#annuaire-fiche-pro .rating-symbol .rating-symbol-background {
  visibility: hidden;
}
#annuaire-fiche-pro .rating-symbol .rating-symbol-foreground {
  display: inline-block;
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  width: auto;
}
#annuaire-fiche-pro .rating-symbol .rating-symbol-foreground .custom-star-grey {
  color: #d7d7d7;
}
#annuaire-fiche-pro .star-green {
  color: #009600;
}
.legal {
  line-height: 20px;
  margin-bottom: 20px;
}
.legal .contact {
  color: #ff580c;
  font-weight: bold;
}
#universes .grid-top .article {
  position: relative;
  margin-top: 30px;
  float: left;
  height: 40px;
}
#universes .grid-top .article .title {
  color: #727272;
  text-transform: uppercase;
}
#universes .grid-top .article .title a {
  color: #ff580c;
}
#universes .grid-top .article .text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  margin: 5px 0 10px;
  line-height: 18px;
  display: inline-block;
}
#universes .grid-top .article .text:hover {
  text-decoration: underline;
}
.add-project-pictures {
  padding: 0 15px 15px;
}
.add-project-pictures .flex {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
}
.add-project-pictures .img-layout {
  position: relative;
  width: 200px;
  height: 200px;
  object-fit: cover;
  border: 2px solid #b6b5b6;
  border-radius: 0.4rem;
  padding: 0.25rem;
}
.add-project-pictures .img-layout img {
  width: 100%;
  height: 100%;
  border-radius: 0.4rem;
  object-fit: cover;
}
.add-project-pictures .img-layout .add,
.add-project-pictures .img-layout .remove {
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
  width: 24px;
  height: 24px;
  border-radius: 99rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2.4rem;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.add-project-pictures .img-layout .remove {
  background-color: #ff0000;
  rotate: 45deg;
}
.add-project-pictures .img-layout .add {
  background-color: #ff580c;
}
.new-project-category {
  margin-bottom: 30px;
}
.new-project-category h2 {
  font-size: 3.6rem;
  line-height: 3.6rem;
  margin-top: 30px;
  text-transform: uppercase;
  color: #474747;
  text-align: center;
}
.new-project-category p {
  margin: 20px 0;
}
.new-project-category .img-cat {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  display: table;
  height: 265px;
  width: 100%;
  cursor: pointer;
  margin-bottom: 2em;
}
.new-project-category .img-cat .bg_opacity {
  background-color: rgba(0, 0, 0, 0.45);
  display: table-cell;
  height: 100%;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}
.new-project-category .img-cat .bg_opacity .txt {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  color: white;
}
.new-project-category .img-cat .bg_opacity:hover {
  background-color: rgba(0, 0, 0, 0.7);
  transition: background-color 500ms linear;
}
@media (max-width:767px) {
  .new-project-category .img-cat {
    margin-bottom: 15px;
  }
}
.new-project-category .btn-start {
  margin: 20px 0 40px 0;
}
.projects-menu {
  margin: 20px 0 0 0;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.projects-menu ul li .tabs-project {
  background-color: #efefef;
  color: #474747;
  border-bottom: 1px solid #474747;
}
.projects-menu ul li .tabs-project .project-type {
  padding: 2px 20px 15px 15px;
}
.projects-menu ul li .tabs-project .project-type .arrow-blue {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
  background-position: -74px 0;
  width: 6px;
  height: 10px;
  float: right;
  margin-top: 3px;
}
@media (min-width:992px) and (max-width:1199px) {
  .projects-menu ul li .tabs-project .project-type {
    font-size: 1.2rem;
  }
}
.projects-menu ul li .tabs-project:hover {
  background-color: #05d8c6;
  color: #fff;
}
.projects-menu ul li .tabs-project:hover .project-type .arrow-blue {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
  background-position: -479px 0;
  width: 6px;
  height: 10px;
}
.projects-menu ul li .tabs-project .date {
  font-size: 1rem;
  padding: 10px 0 5px 15px;
}
.projects-menu ul li:last-child .tabs-project {
  border-bottom: 0;
}
.projects-menu .link {
  background-color: #ff580c;
  color: #fff;
  padding: 15px 20px 15px 15px;
  display: block;
}
.projects-menu .link .arrow-white {
  background-image: url('/build/images/theme/lisboa/consumer/sprite-consumer.png');
  background-repeat: no-repeat;
  background-position: -479px 0;
  width: 6px;
  height: 10px;
  float: right;
  margin-top: 2px;
}
.works-guide {
  margin-bottom: 30px;
}
.works-guide h2 {
  font-size: 3.6rem;
  line-height: 3.6rem;
  margin: 30px 0;
  text-transform: uppercase;
  color: #474747;
  text-align: center;
}
.works-guide .content .img-article {
  height: 170px;
  width: 100%;
}
.works-guide .content .heading h3 {
  margin-top: 10px;
  border-top: 0;
  width: 100%;
  color: #474747;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 2.5rem;
}
.works-guide .content p {
  margin-top: 15px;
  line-height: 2.2rem;
}
.works-guide .link {
  margin-top: 20px;
  color: #ff580c;
  float: right;
}
@media (max-width:767px) {
  .works-guide .link {
    margin-bottom: 15px;
  }
}
.works-guide .link:hover {
  text-decoration: underline;
}
.works-guide .link .arrow {
  background-image: url('/images/habitatpresto/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -50px -20px;
  width: 5px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
}
#cgu .heading {
  margin: 60px 0 30px 0;
  text-align: left;
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  text-transform: uppercase;
}
#cgu .text {
  text-align: justify;
  display: block;
  line-height: 24px;
}
#cgu .text a,
#cgu .text a:visited,
#cgu .text a:active {
  color: #ff580c;
}
#cgu .text a:hover {
  color: #ff580c;
  text-decoration: underline;
}
#cgu .text strong {
  font-weight: bold;
}
@media (min-width:768px) {
  #cgu .text {
    display: block;
  }
}
#cgu .text ul {
  margin-top: 10px;
  margin-left: 20px;
  margin-bottom: 10px;
}
#cgu .text ul li {
  list-style: disc inside;
  margin-top: 10px;
}
#cgu .text.mb-60 {
  margin-bottom: 60px;
}
#cpdp .heading {
  margin: 60px 0 30px 0;
  text-align: left;
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  text-transform: uppercase;
}
#cpdp .text {
  text-align: justify;
  display: block;
  line-height: 24px;
}
#cpdp .text a,
#cpdp .text a:visited,
#cpdp .text a:active {
  color: #ff580c;
}
#cpdp .text a:hover {
  color: #ff580c;
  text-decoration: underline;
}
#cpdp .text strong {
  font-weight: bold;
}
@media (min-width:768px) {
  #cpdp .text {
    display: block;
  }
}
#cpdp .text ul {
  margin-top: 10px;
  margin-left: 20px;
  margin-bottom: 10px;
}
#cpdp .text ul li {
  list-style: disc inside;
  margin-top: 10px;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}

/*# sourceMappingURL=habitatpresto_less.css.map */
